// @ts-ignore
import { template } from "@ember/template-compiler";
import { ensureSafeComponent } from '@embroider/util';
import Component from '@glimmer/component';
import type { ComponentLike } from '@glint/template';
import { hash } from '@ember/helper';
import FromElsewhere from 'ember-elsewhere/components/from-elsewhere';
import type { DeprecatedUsage } from 'ember-elsewhere/components/from-elsewhere';
import ModalContent from './content.gts';
interface ModalTargetSignature {
    Args: {
    };
    Element: HTMLDivElement;
}
export default class ModalTargetComponent extends Component<ModalTargetSignature> {
    isDeprecatedUsage(ComponentOrOptions1: ComponentLike | DeprecatedUsage): ComponentOrOptions is DeprecatedUsage {
        return (ComponentOrOptions1 && typeof ComponentOrOptions1 === 'object' && 'modal' in ComponentOrOptions1);
    }
    static{
        template(`
    <FromElsewhere @name="modal-container" as |ComponentOrOptions outsideParams|>
      {{#if (this.isDeprecatedUsage ComponentOrOptions)}}
        <div class="rendered-modal" data-test-modal={{ComponentOrOptions.modal.testSelector}} ...attributes>
          {{#let
            (component (ensureSafeComponent ComponentOrOptions.modal.component this))
            as |ModalComponent|
          }}
            {{! @glint-ignore }}
            <ModalComponent
              @modal={{ComponentOrOptions.modal}}
              @ui={{hash
                modal=(component ModalContent close=ComponentOrOptions.close)
              }}
            />
          {{/let}}
        </div>
      {{else}}
        <div class="rendered-modal" data-test-modal={{outsideParams.testSelector}} ...attributes>
          <ComponentOrOptions />
        </div>
      {{/if}}
    </FromElsewhere>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
