import Component from '@glimmer/component';

interface FormBaseWrapperLinkableLabelLinkSignature {
  Args: {};
  Element: HTMLElement;
}

export default class FormBaseWrapperLinkableLabelLinkComponent extends Component<FormBaseWrapperLinkableLabelLinkSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Form::Base::Wrapper::LinkableLabel::Link': typeof FormBaseWrapperLinkableLabelLinkComponent;
    'form/base/wrapper/linkable-label/link': typeof FormBaseWrapperLinkableLabelLinkComponent;
  }
}
