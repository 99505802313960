import { template } from "@ember/template-compiler";
import type { WithBoundArgs } from '@glint/template';
import { hash } from '@ember/helper';
import ButtonText from '../../button/text.gts';
import Icon from '../../icon.gts';
interface Signature {
    Args: {
    };
    Blocks: {
        default: [{
                ui: {
                    item: WithBoundArgs<typeof ButtonText, 'classes'>;
                    icon: WithBoundArgs<typeof Icon, 'classes'>;
                };
            }];
    };
    Element: HTMLDivElement;
}
const TableActionCellContent: TemplateOnlyComponent<Signature> = template(`
  <div class="flex flex-col" ...attributes>
    {{yield (hash
      ui=(hash
        item=(component ButtonText classes="p-4 text-teal-600 hover:text-navy-600")
        icon=(component Icon w="w-6" classes="mr-2")
      )
    )}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default TableActionCellContent;
