import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { Machine } from 'xstate';
import { hash } from '@ember/helper';
import { action } from '@ember/object';
import didInsert from '@ember/render-modifiers/modifiers/did-insert';
import didUpdate from '@ember/render-modifiers/modifiers/did-update';
import { task } from 'ember-concurrency';
import { useMachine } from 'ember-statecharts';
import { matchesState } from '../utils/statecharts.ts';
interface StateSchema {
    states: {
        idle: {
        };
        waiting: {
        };
        busy: {
        };
        error: {
        };
        final: {
        };
    };
}
type StatechartEvent = {
    type: 'OPEN';
} | {
    type: 'CONFIRM';
} | {
    type: 'CANCEL';
} | {
    type: 'RESOLVE';
} | {
    type: 'REJECT';
};
interface ConfirmationSignature {
    Args: {
        isOpen: boolean;
        onConfirm?: Function;
        onCancel?: Function;
    };
    Blocks: {
        default: [{
                actions: {
                    confirm: Confirmation['confirm'];
                    cancel: Confirmation['cancel'];
                };
                state: {
                    confirmationDidError: Confirmation['confirmationDidError'];
                    isBusy: Confirmation['isBusy'];
                };
            }];
    };
}
export type ConfirmationConfirm = ConfirmationSignature['Blocks']['default'][0]['actions']['confirm'];
export type ConfirmationCancel = ConfirmationSignature['Blocks']['default'][0]['actions']['cancel'];
export default class Confirmation extends Component<ConfirmationSignature> {
    statechart = useMachine(this, ()=>({
            machine: Machine<Confirmation, StateSchema, StatechartEvent>({
                initial: 'idle',
                states: {
                    idle: {
                        on: {
                            OPEN: 'waiting'
                        }
                    },
                    waiting: {
                        on: {
                            CONFIRM: 'busy',
                            CANCEL: {
                                target: 'idle',
                                actions: [
                                    'dismissConfirmation'
                                ]
                            }
                        }
                    },
                    busy: {
                        entry: [
                            'executeAction'
                        ],
                        on: {
                            RESOLVE: {
                                target: 'final',
                                actions: [
                                    'dismissConfirmation'
                                ]
                            },
                            REJECT: 'error'
                        }
                    },
                    error: {
                        on: {
                            CONFIRM: 'busy',
                            CANCEL: {
                                target: 'idle',
                                actions: [
                                    'dismissConfirmation'
                                ]
                            }
                        }
                    },
                    final: {
                        on: {
                            OPEN: 'waiting'
                        }
                    }
                }
            }).withConfig({
                actions: {
                    dismissConfirmation: ()=>this.args.onCancel?.(),
                    executeAction: ()=>this.confirmationTask.perform()
                }
            })
        }));
    @matchesState('error')
    confirmationDidError: boolean;
    @matchesState('busy')
    isBusy: boolean;
    confirmationTask = task({
        drop: true
    }, async ()=>{
        try {
            await this.args.onConfirm?.();
            this.statechart.send('RESOLVE');
        } catch (error1) {
            this.statechart.send('REJECT');
        }
    });
    @action
    confirm() {
        this.statechart.send('CONFIRM');
    }
    @action
    cancel() {
        this.statechart.send('CANCEL');
    }
    @action
    shouldOpenConfirmation() {
        if (this.args.isOpen) {
            this.statechart.send('OPEN');
        }
    }
    static{
        template(`
    <div class="hidden"
      {{didInsert this.shouldOpenConfirmation}}
      {{didUpdate this.shouldOpenConfirmation @isOpen}}
    ></div>

    {{#if @isOpen}}
      {{yield
        (hash
          actions=(hash
            confirm=this.confirm
            cancel=this.cancel
          )
          state=(hash
            confirmationDidError=this.confirmationDidError
            isBusy=this.isBusy
          )
        )
      }}
    {{/if}}

  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
