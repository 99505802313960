import { template } from "@ember/template-compiler";
import type { ButtonDefaultBlock } from '../../button.gts';
import Button from '../../button.gts';
interface ButtonTopBaseSignature {
    Args: {
        disabled?: boolean;
        isBusy?: string | boolean;
        type?: string;
        onClick?: Function;
        onSuccess?: Function;
        onError?: Function;
        testSelector?: string;
        narrowStyle?: boolean;
        contentClasses?: string;
        classes?: string;
        text?: string;
        small?: boolean;
        borderRadius?: 'normal' | 'small';
    };
    Blocks: {
        default: [ButtonDefaultBlock];
    };
    Element: HTMLButtonElement;
}
export function getCTAButtonSize(small1?: boolean, narrowStyle1?: boolean) {
    if (small1) {
        return 'cta-button--size-small';
    }
    if (narrowStyle1) {
        return 'cta-button--size-narrow';
    }
    return 'cta-button--size-normal';
}
export function getCTAButtonBorderRadius(borderRadius1?: 'normal' | 'small') {
    if (borderRadius1 === 'small') {
        return 'cta-button--border-radius-small';
    }
    return 'cta-button--border-radius-normal';
}
/**
 * Note: <Button::Top::Base> is customized a lot in **Ticketbooth**!
 */ const ButtonTopBaseComponent: TemplateOnlyComponent<ButtonTopBaseSignature> = template(`
  <Button
    @disabled={{@disabled}}
    @isBusy={{@isBusy}}
    @type={{@type}}
    @onClick={{@onClick}}
    @onSuccess={{@onSuccess}}
    @onError={{@onError}}
    @testSelector={{@testSelector}}
    class="cta-button
      {{getCTAButtonSize @small @narrowStyle}}
      {{getCTAButtonBorderRadius @borderRadius}}
      {{if @onClick "disabled:cursor-not-allowed" "disabled:cursor-default"}}
      {{@classes}}"
    ...attributes
    as |c|
    >
    <c.ui.content class="{{@contentClasses}}">
      {{#if (has-block)}}
        {{yield c}}
      {{else}}
        {{@text}}
      {{/if}}
    </c.ui.content>
  </Button>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ButtonTopBaseComponent;
