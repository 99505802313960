import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
interface Signature {
    Args: {
        isLarge: boolean;
        isArrowBefore: boolean;
        axis: string;
        axisPosition: string;
        colorClasses: string;
        testSelector?: string;
        offset?: string;
    };
    Blocks: {
    };
}
export default class AttachmentTooltipArrow extends Component<Signature> {
    get classes() {
        const { isLarge: isLarge1, axis: axis1, axisPosition: axisPosition1, isArrowBefore: isArrowBefore1 } = this.args;
        const classes1 = [];
        if (isLarge1) {
            classes1.push('tooltip-arrow-container-large');
        } else {
            classes1.push('tooltip-arrow-container');
        }
        if (axis1 === 'x') {
            classes1.push(`tooltip-arrow-v justify-${axisPosition1}`);
        }
        if (axis1 === 'y') {
            classes1.push(`tooltip-arrow-h items-${axisPosition1}`);
        }
        classes1.push(`tooltip-arrow-${isArrowBefore1 ? 'before' : 'after'}`);
        return classes1.join(' ');
    }
    static{
        template(`
    <div data-test-tooltip-arrow={{@testSelector}} class={{this.classes}}>
      <div class="tooltip-arrow">
        <div class="tooltip-arrow-content {{@colorClasses}}"></div>
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'UiAttachment::Tooltip::Arrow': typeof AttachmentTooltipArrow;
        'ui-attachment/tooltip/arrow': typeof AttachmentTooltipArrow;
    }
}
