import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { on } from '@ember/modifier';
import eq from 'ember-truth-helpers/helpers/eq';
interface Signature {
    Args: {
        eventType?: 'click' | 'hover';
        guid: string;
        classes?: string;
        testSelector?: string;
        toggleTooltip: (event: MouseEvent) => void;
        showTooltip?: (event: MouseEvent) => void;
        hideTooltip?: (event: MouseEvent) => void;
    };
    Blocks: {
        default: [];
    };
    Element: HTMLDivElement;
}
export default class AttachmentTarget extends Component<Signature> {
    get eventType(): string {
        return this.args.eventType ?? 'hover';
    }
    static{
        template(`
    {{#if (eq this.eventType 'click')}}
      <div
        data-tether-target-id={{@guid}}
        class={{@classes}}
        {{on 'click' @toggleTooltip}}
        role='tooltip'
        data-test-attachment-target={{@testSelector}}
        ...attributes
      >
        {{yield}}
      </div>
    {{/if}}

    {{#if (eq this.eventType 'hover')}}
      <div
        data-tether-target-id={{@guid}}
        class={{@classes}}
        {{on 'mouseenter' @showTooltip}}
        {{on 'mouseleave' @hideTooltip}}
        role='tooltip'
        data-test-attachment-target={{@testSelector}}
        ...attributes
      >
        {{yield}}
      </div>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'UiAttachment::Target': typeof AttachmentTarget;
        'ui-attachment/target': typeof AttachmentTarget;
    }
}
