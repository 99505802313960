import { template } from "@ember/template-compiler";
interface ButtonContainerSignature {
    Args: {
        text?: string;
    };
    Blocks: {
        default: [];
    };
    Element: HTMLDivElement;
}
const ButtonContainer: TemplateOnlyComponent<ButtonContainerSignature> = template(`
  <div class="flex-grow" ...attributes>
    {{#if (has-block)}}
      {{yield}}
    {{else}}
      {{@text}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ButtonContainer;
