import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { hash } from '@ember/helper';
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import AttachmentTarget from './ui-attachment/target.gts';
import AttachmentTooltip from './ui-attachment/tooltip.gts';
export interface AttachmentSignature {
    Args: {
        displayTooltip?: boolean;
        isEnabled?: boolean;
        anchor?: string;
        onShowTooltip?: Function;
        onHideTooltip?: Function;
        testSelector?: string;
        classes?: string;
    };
    Blocks: {
        default: [{
                ui: {
                    target: any;
                    tooltip: any;
                    popover: any;
                };
                state: {
                    targetId: string;
                    isOpen: boolean;
                };
                actions: {
                    showTooltip: () => void;
                    hideTooltip: () => void;
                };
            }];
    };
}
export default class Attachment extends Component<AttachmentSignature> {
    @tracked
    displayTooltip: boolean;
    constructor(owner1: Attachment, args1: AttachmentSignature['Args']){
        super(owner1, args1);
        this.displayTooltip = args1.displayTooltip ?? false;
    }
    get isEnabled(): boolean {
        return this.args.isEnabled ?? true;
    }
    get anchor(): string {
        return this.args.anchor ?? 'top center';
    }
    get guid() {
        return guidFor(this);
    }
    @action
    showTooltip() {
        if (!this.isEnabled) return;
        this.displayTooltip = true;
        this.args.onShowTooltip?.();
    }
    @action
    hideTooltip() {
        if (!this.isEnabled) return;
        this.displayTooltip = false;
        this.args.onHideTooltip?.();
    }
    @action
    toggleTooltip(event1: Event) {
        event1.stopPropagation(); // Prevent parent clicks
        if (this.displayTooltip) {
            return this.hideTooltip();
        }
        return this.showTooltip();
    }
    static{
        template(`
    {{yield (hash
      ui=(hash
        target=(component AttachmentTarget
          guid=this.guid
          classes=@classes
          showTooltip=this.showTooltip
          hideTooltip=this.hideTooltip
          toggleTooltip=this.toggleTooltip
          testSelector=@testSelector
        )
        tooltip=(component AttachmentTooltip
          guid=this.guid
          anchor=this.anchor
          isPopover=false
          isEnabled=this.isEnabled
          displayTooltip=this.displayTooltip
          testSelector=@testSelector
          hideTooltip=this.hideTooltip
        )
        popover=(component AttachmentTooltip
          guid=this.guid
          anchor=this.anchor
          isPopover=true
          isEnabled=this.isEnabled
          displayTooltip=this.displayTooltip
          useShadow=true
          borderColor="gray-400"
          testSelector=@testSelector
        )
      )
      state=(hash
        targetId=this.guid
        isOpen=this.displayTooltip
      )
      actions=(hash
        showTooltip=this.showTooltip
        hideTooltip=this.hideTooltip
      )
    )}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
