import Component from '@glimmer/component';

import type { DropdownOption } from '../../dropdown.ts';
import { getTheme, type FormTheme } from '../../form/theme.ts';

interface Signature {
  Args: {
    selected?: DropdownOption | null;
    clearable?: boolean;
    triggerIcon?: string;
    placeholder?: string;
    theme?: FormTheme;
    usePlaceholderAlways?: boolean;
  };
  Element: HTMLButtonElement;
}

export default class DropdownTriggerButton extends Component<Signature> {
  style = getTheme(this.args.theme);

  get displayClearButton() {
    const { selected, clearable } = this.args;
    if (selected?.chipIcon) {
      // Chip provides clear button itself
      return false;
    }
    return selected && clearable;
  }

  get selectedLabel() {
    const { selected, usePlaceholderAlways, placeholder } = this.args;
    return usePlaceholderAlways && placeholder
      ? placeholder
      : selected?.triggerLabel || selected?.label;
  }

  get isEmpty() {
    return !this.args.selected && !this.args.placeholder;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Dropdown::Trigger::Button': typeof DropdownTriggerButton;
    'dropdown/trigger/button': typeof DropdownTriggerButton;
  }
}
