// @ts-ignore
import { ensureSafeComponent } from '@embroider/util';
import Component from '@glimmer/component';
import type { ValidationError } from 'yup';

import { getTheme, type FormTheme } from '../theme.ts';
import FormFieldLabel from './wrapper/label.ts';
import FormFieldLinkableLabel from './wrapper/linkable-label.ts';

interface FormBaseWrapperSignature {
  Args: {
    displayErrors: boolean;
    validationErrors: ValidationError[];
    guid: string;
    label?: string;
    theme?: FormTheme;
    labelIcon?: string;
    hint?: string;
    width?: string;
    withLinkableLabel?: boolean;
    inline?: boolean;
    testSelector?: string;
    isDisabled?: boolean;
    isRequired?: boolean;
    validationError?: any;
    classes?: string;
  };
  Blocks: {
    afterLabel: [];
    default: [unknown];
  };
  Element: HTMLDivElement;
}

export default class FormBaseWrapperComponent extends Component<FormBaseWrapperSignature> {
  style = getTheme(this.args.theme).label;

  get labelComponent() {
    return ensureSafeComponent(
      this.args.withLinkableLabel ? FormFieldLinkableLabel : FormFieldLabel,
      this
    );
  }

  get width(): string {
    return this.args.width ?? 'w-full';
  }

  get displayHint() {
    return !!this.args.hint;
  }

  get firstValidationError() {
    return this.args.validationErrors[0]?.message;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Form::Base::Wrapper': typeof FormBaseWrapperComponent;
    'form/base/wrapper': typeof FormBaseWrapperComponent;
  }
}
