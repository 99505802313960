import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { Machine } from 'xstate';
import { hash } from '@ember/helper';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task, timeout } from 'ember-concurrency';
import { useMachine } from 'ember-statecharts';
import type ErrorsService from '../services/-errors-base.ts';
import type EnvironmentService from '../services/environment.ts';
import { matchesState } from '../utils/statecharts.ts';
interface StateSchema {
    states: {
        idle: {
        };
        busy: {
        };
        success: {
        };
        error: {
        };
    };
}
type StatechartEvent = {
    type: 'DO';
    args: any[];
    resolve: Function;
    reject: Function;
} | {
    type: 'RESOLVE';
    result: any;
    resolve: Function;
} | {
    type: 'REJECT';
    error: any;
    reject: Function;
};
export interface AsyncSignature {
    Args: {
        do: Function;
        onSuccess?: Function;
        onError?: Function;
        debounce?: boolean;
        debounceTimeout?: number;
        /**
     * Similar to ember-concurrency `restartable` modifier, it is possible
     * to cancel the current request and start a new one.
     *
     * In contrast, if `isRestartable` is false, the `drop` modifier is used,
     * so a running request is never cancelled and new ones are dropped.
     */ isRestartable?: boolean;
    };
    Blocks: {
        default: [{
                state: {
                    isIdle: Async['isIdle'];
                    isBusy: Async['isBusy'];
                    didSucceed: Async['didSucceed'];
                    didError: Async['didError'];
                    result: Async['result'];
                    error: Async['error'];
                };
                actions: {
                    do: Async['do'];
                };
            }];
    };
}
function noop() {}
export default class Async extends Component<AsyncSignature> {
    @service
    errors: ErrorsService;
    @service
    environment: EnvironmentService;
    @tracked
    result: any;
    @tracked
    error: any;
    @matchesState('idle')
    isIdle: boolean;
    @matchesState('busy')
    isBusy: boolean;
    @matchesState('success')
    didSucceed: boolean;
    @matchesState('error')
    didError: boolean;
    statechart = useMachine(this, ()=>({
            machine: Machine<{
            }, StateSchema, StatechartEvent>({
                initial: 'idle',
                states: {
                    idle: {
                        on: {
                            DO: 'busy'
                        }
                    },
                    busy: {
                        entry: [
                            'startTask'
                        ],
                        on: {
                            RESOLVE: 'success',
                            REJECT: 'error',
                            DO: [
                                {
                                    target: 'busy',
                                    cond: 'isDebounce'
                                },
                                {
                                    target: 'busy',
                                    cond: 'isRestartable'
                                }
                            ]
                        }
                    },
                    success: {
                        entry: [
                            'handleSuccess'
                        ],
                        on: {
                            DO: 'busy'
                        }
                    },
                    error: {
                        entry: [
                            'handleError'
                        ],
                        on: {
                            DO: 'busy'
                        }
                    }
                }
            }).withConfig({
                actions: {
                    startTask: (_1, { args: args1, resolve: resolve1, reject: reject1 }: Extract<StatechartEvent, {
                        type: 'DO';
                    }>)=>{
                        if (this.isDebounce) {
                            this.debounceAsyncTask.perform({
                                args: args1,
                                resolve: resolve1,
                                reject: reject1
                            });
                        } else if (this.isRestartable) {
                            this.restartableAsyncTask.perform({
                                args: args1,
                                resolve: resolve1,
                                reject: reject1
                            });
                        } else {
                            this.asyncTask.perform({
                                args: args1,
                                resolve: resolve1,
                                reject: reject1
                            });
                        }
                    },
                    handleSuccess: (_1, { result: result1, resolve: resolve1 }: Extract<StatechartEvent, {
                        type: 'RESOLVE';
                    }>)=>{
                        this.result = result1;
                        resolve1(result1);
                        this.onSuccess(result1);
                    },
                    handleError: (_1, { error: error1, reject: reject1 }: Extract<StatechartEvent, {
                        type: 'REJECT';
                    }>)=>{
                        this.error = error1;
                        reject1(error1);
                        this.onError(error1);
                    }
                },
                guards: {
                    isDebounce: ()=>this.isDebounce,
                    isRestartable: ()=>this.isRestartable
                }
            })
        }));
    get onSuccess() {
        return this.args.onSuccess || noop;
    }
    get onError() {
        return this.args.onError || noop;
    }
    get isDebounce() {
        return this.args.debounce === true;
    }
    get debounceTimeout(): number {
        if (this.environment.isTest) {
            return 0;
        }
        return this.args.debounceTimeout ?? 250;
    }
    get isRestartable() {
        return this.args.isRestartable ?? false;
    }
    asyncTask = task({
        drop: true
    }, async ({ args: args1, resolve: resolve1, reject: reject1 }: {
        args: any[];
        resolve: Function;
        reject: Function;
    })=>{
        try {
            const result1 = await this.args.do(...args1);
            this.statechart.send('RESOLVE', {
                result: result1,
                resolve: resolve1
            });
        } catch (error1) {
            this.statechart.send('REJECT', {
                error: error1,
                reject: reject1
            });
        }
    });
    debounceAsyncTask = task({
        restartable: true
    }, async ({ args: args1, resolve: resolve1, reject: reject1 }: {
        args: any[];
        resolve: Function;
        reject: Function;
    })=>{
        await timeout(this.debounceTimeout);
        await this.asyncTask.perform({
            args: args1,
            resolve: resolve1,
            reject: reject1
        });
    });
    restartableAsyncTask = task({
        restartable: true
    }, async ({ args: args1, resolve: resolve1, reject: reject1 }: {
        args: any[];
        resolve: Function;
        reject: Function;
    })=>{
        try {
            const result1 = await this.args.do(...args1);
            this.statechart.send('RESOLVE', {
                result: result1,
                resolve: resolve1
            });
        } catch (error1) {
            this.statechart.send('REJECT', {
                error: error1,
                reject: reject1
            });
        }
    });
    @action
    _do(...args1: any[]): Promise<any> {
        return new Promise((resolve1, reject1)=>{
            this.statechart.send('DO', {
                args: args1,
                resolve: resolve1,
                reject: reject1
            });
        });
    }
    @action
    async do(): Promise<any> {
        try {
            return await this._do(...arguments);
        } catch (ex1) {
            this.errors.log(ex1);
        }
    }
    static{
        template(`
    {{yield (hash
      state=(hash
        isIdle=this.isIdle
        isBusy=this.isBusy
        didSucceed=this.didSucceed
        didError=this.didError
        result=this.result
        error=this.error
      )
      actions=(hash
        do=this.do
      )
    )}}

  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
