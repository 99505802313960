import { action } from '@ember/object';

import type {
  ExtractFormProps,
  ExtractFormValue,
  FormContext
} from './-control-base.ts';
import FormBaseComponent from './-control-base.ts';

type ToggleProperty = boolean;

interface Signature<
  Context extends FormContext,
  Property extends ExtractFormProps<Context, ToggleProperty>,
  Value
> {
  Args: {
    f: Context;
    property: Property;
    value?: Value;
    label?: string;
    testSelector?: string;
    hint?: string;
    isDisabled?: boolean;
    onValueChanged?: (value: boolean) => void;

    fullWidth?: boolean;
    labelClass?: string;
    alignCenter?: boolean;
    minHeight?: string;

    /**
     * @deprecated
     */
    onChange?: FormContext['actions']['change'];
  };
  Blocks: {
    default: [];
  };
  Element: HTMLButtonElement;
}

export default class FormCheckbox<
  Context extends FormContext,
  Property extends ExtractFormProps<Context, ToggleProperty>,
  Value extends ExtractFormValue<Context, Property>
> extends FormBaseComponent<
  Signature<Context, Property, Value>,
  ToggleProperty
> {
  get alignCenter() {
    return this.args.alignCenter ?? true;
  }

  @action
  onClick(inputEvent: { target: HTMLInputElement }) {
    const {
      target: { checked }
    } = inputEvent;

    return super.onChange(!!checked);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Form::Checkbox': typeof FormCheckbox;
  }
}
