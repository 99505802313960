import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import didInsert from '@ember/render-modifiers/modifiers/did-insert';
interface DialogSignature {
    Args: {
        close?: Function;
    };
    Blocks: {
        default: [];
    };
    Element: HTMLDialogElement;
}
export default class DialogComponent extends Component<DialogSignature> {
    private dialogElement?: HTMLDialogElement;
    @action
    setup(dialogElement1: HTMLDialogElement) {
        this.dialogElement = dialogElement1;
        dialogElement1.addEventListener('cancel', (ev1)=>{
            ev1.stopPropagation();
            ev1.preventDefault();
            this.close();
        });
        dialogElement1.showModal();
    }
    @action
    shouldClose(event1: MouseEvent) {
        const rect1 = this.dialogElement.getBoundingClientRect();
        const isInDialog1 = rect1.top <= event1.clientY && event1.clientY <= rect1.top + rect1.height && rect1.left <= event1.clientX && event1.clientX <= rect1.left + rect1.width;
        if (!isInDialog1) {
            this.close();
        }
    }
    @action
    close() {
        this.args.close?.();
    }
    static{
        template(`
    <dialog
      {{didInsert this.setup}}
      {{! template-lint-disable }}
      {{on "click" this.shouldClose}}
      class="text-base"
      ...attributes
    >
      {{yield}}
    </dialog>

  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
