import { template } from "@ember/template-compiler";
interface ModalBodySignature {
    Args: {
        noPadding?: boolean;
    };
    Blocks: {
        default: [];
    };
    Element: HTMLDivElement;
}
const ModalBodyComponent: TemplateOnlyComponent<ModalBodySignature> = template(`
  <div class="flex-grow {{unless @noPadding "px-4 py-8"}} overflow-y-auto" ...attributes>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ModalBodyComponent;
