import Component from '@glimmer/component';

interface TableReordeableCellSignature {
  Args: {
    withIcon?: boolean;
  };
  Element: HTMLTableCellElement;
}

export default class TableReordeableCellComponent extends Component<TableReordeableCellSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Table::ReordeableCell': typeof TableReordeableCellComponent;
    'table/reordeable-cell': typeof TableReordeableCellComponent;
  }
}
