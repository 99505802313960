import Component from '@glimmer/component';

import { getOwner } from '@ember/application';
import { action } from '@ember/object';

export abstract class Renderer {
  canvas: HTMLCanvasElement;
  context: CanvasRenderingContext2D;
  args?: any[];

  constructor(canvas: HTMLCanvasElement) {
    this.canvas = canvas;
    this.context = canvas.getContext('2d')!;
  }

  abstract render(...args: any[]): void;

  hasChanges(args: any[]) {
    const hasChanges =
      !this.args || this.args.some((arg, idx) => args[idx] !== arg);

    if (hasChanges) {
      this.args = args;
    }

    return hasChanges;
  }

  static create(subclass: RendererSubClass, canvas: HTMLCanvasElement) {
    return new subclass(canvas);
  }
}

type RendererSubClass = { new (canvas: HTMLCanvasElement): Renderer };

interface CanvasSignature {
  Args: {};
  Blocks: {
    default: [unknown];
  };
}

/**
 * A component to abstract working with an `HTMLCanvasElement`. Instead of working
 * with `HtmlCanvasElement`s directly we will work with `AbstractCanvas` in our
 * templates which yield canvas layers. We do this to reduce the amount of drawing
 * that we need to do on a canvas which is important for rendering speed.
 *
 * I.e. it's better to build up your canvas drawings with multiple layers that
 * redraw when the content the layer renders changes instead of drawing on one
 * layer that you need to redraw all the time. You can think of these layers as
 * layers in Photoshop that can be changed individually.
 *
 * `AbstractCanvas`' layers will use `CanvasRenderer`s to handle the drawing on
 * the actual HtmlCanvasElements. These renderes can be found in the
 * `app/canvas-renderers`-directory.
 *
 * Renderers for layers need to be registered on the `AbstractCanvas`. You do that
 * by using the `did-insert`-modifier and the `registerLayer`-action yielded by
 * `AbstractCanvas`:
 *
 * ```hbs
 * {{#canvas as |c|}}
 *  <c.ui.layer
 *    {{did-insert (fn c.actions.registerLayer (hash renderer="background"))}}
 * />
 * {{/canvas}}
 *
 * ```
 *
 * If you need to do additional work after a `Renderer`-instance has been registered
 * to the `AbstractCanvas` (e.g. to draw on the Layer immediately) you can pass a
 * `didSetupContext` function to `registerLayer`. This function will be called with
 * the setup Renderer-instance for the layer as a parameter.
 *
 * ```hbs
 * {{#canvas as |c|}}
 *  <c.ui.layer
 *    {{did-insert (fn c.actions.registerLayer
 *      (hash
 *        renderer="background"
 *        didSetupContext=(fn.c.actions.rerenderLayer "background")
 *      ))
 *    }}
 * />
 * {{/canvas}}
 * ```
 *
 * To actually draw on the registered layer you can use the `rerenderLayer`-action
 * yielded by `AbstractCanvas`. When this function gets called the renderer you
 * specified as the first parameter to `rerenderLayer` will call its `render`
 * function.
 *
 * This makes it easy to rerender a layer when properties in the template-context
 * change by using the `did-update` modifier. The following example will call the
 * `background`-renderers `render` when `@backgroundImage` changes:
 *
 * ```hbs
 * {{#canvas as |c|}}
 *   <c.ui.layer
 *     {{did-insert (fn c.actions.registerLayer (hash renderer="background")}}
 *     {{did-update (fn c.actions.rerenderLayer "background" @backgroundImage)}}
 *   />
 * {{/canvas}}
 * ```
 */
export default class CanvasComponent extends Component<CanvasSignature> {
  private renderers: { [name: string]: Renderer } = {};

  lookupRenderer(name: string): Renderer {
    return this.renderers[name];
  }

  lookupRendererClass(name: string): RendererSubClass {
    const owner = getOwner(this);
    const factory = owner.factoryFor(`canvas-renderer:${name}`) as {
      class: RendererSubClass;
    };
    return factory.class;
  }

  registerRenderer(name: string, canvas: HTMLCanvasElement) {
    const subclass = this.lookupRendererClass(name);
    this.renderers[name] = Renderer.create(subclass, canvas);
  }

  @action
  registerLayer(
    {
      renderer: name,
      didSetupContext = () => {}
    }: { renderer: string; didSetupContext?: Function },
    canvas: HTMLCanvasElement
  ) {
    const renderer = this.lookupRenderer(name);

    if (!renderer) {
      this.registerRenderer(name, canvas);
    }

    didSetupContext(this.lookupRenderer(name));
  }

  @action
  rerenderLayer(
    name: string,
    _canvasElement: HTMLCanvasElement,
    didUpdateArgs: any[]
  ) {
    const renderer = this.lookupRenderer(name);

    if (renderer && renderer.hasChanges(didUpdateArgs)) {
      renderer.render(...didUpdateArgs);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    Canvas: typeof CanvasComponent;
    canvas: typeof CanvasComponent;
  }
}
