import Component from '@glimmer/component';

import { capitalize, underscore } from '@ember/string';

import type FormObject from '../../utils/form-object.ts';
import type { FormYieldedArgs } from '../form.ts';
import type FormComponent from '../form.ts';

interface Args {
  property: keyof FormObject<Object>;
  onChange?: FormComponent['change'];
  f: FormYieldedArgs;
  testSelector?: string;
  label?: string;
  placeholder?: string;
  hint?: string;
  value?: any;
}

/**
 * Mirage to ./-control-base for better type support with glint
 *
 * @deprecated
 */
export default class FormBaseComponent<T = {}> extends Component<Args & T> {
  get formObject() {
    return this.args.f.state.formObject;
  }

  get value() {
    const formObject = this.formObject;
    const { property, value } = this.args;

    return value ?? formObject[property];
  }

  get testSelector() {
    return this.args.testSelector ?? this.args.property;
  }

  get label() {
    return (
      this.args.label ??
      capitalize(underscore(this.args.property).replace(/_/g, ' '))
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Form::Base': typeof FormBaseComponent;
    'form/base': typeof FormBaseComponent;
  }
}
