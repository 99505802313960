import Component from '@glimmer/component';

import { action } from '@ember/object';

interface FormCheckboxBaseSignature {
  Args: {
    onChange: (event: Event & { target: HTMLInputElement }) => void;
    markInteractedWith?: () => void;

    alignCenter?: boolean;
    id?: string;
    checked?: boolean;
    property?: string;
    isDisabled?: boolean;
    isInvalid?: boolean;
    isRequired?: boolean;
  };
  Element: HTMLInputElement;
  Blocks: {
    default: [];
  };
}

export default class CheckboxBase extends Component<FormCheckboxBaseSignature> {
  @action
  onChange(event: Event) {
    this.args.onChange(event as Event & { target: HTMLInputElement });
    this.args.markInteractedWith?.();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Form::Checkbox::Base': typeof CheckboxBase;
  }
}
