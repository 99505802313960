import { template } from "@ember/template-compiler";
import pipe from 'ember-composable-helpers/helpers/pipe';
import or from 'ember-truth-helpers/helpers/or';
import htmlSafeSanitized from '../../helpers/html-safe-sanitized.ts';
import noOp from '../../helpers/no-op.ts';
import Button from '../button.gts';
import ButtonTopPrimary from '../button/top/primary.gts';
import ButtonTopSecondary from '../button/top/secondary.gts';
import Confirmation from '../confirmation.gts';
import Dialog from '../dialog.gts';
import Icon from '../icon.gts';
interface ModalConfirmationSignature {
    Args: {
        header: string;
        message: string;
        isOpen?: boolean;
        onConfirm?: Function;
        onCancel?: Function;
        close?: Function;
        testSelector?: string;
        cancelText?: string;
        confirmText?: string;
    };
}
const ModalConfirmationComponent: TemplateOnlyComponent<ModalConfirmationSignature> = template(`
  <Confirmation
    @isOpen={{@isOpen}}
    @onConfirm={{@onConfirm}}
    @onCancel={{pipe (or @onCancel (noOp)) (or @close (noOp))}}
    as |c|
  >
    <Dialog @close={{or @close c.actions.cancel}} class="overflow-hidden border border-gray-100 rounded-lg shadow-modal">
      <div data-test-ui-confirm={{@testSelector}} data-test-modal={{@testSelector}} data-ui-confirm>
        <div class="flex items-center p-3 text-xl font-medium text-center text-gray-800">
          <div class="flex-grow text-center">{{@header}}</div>
          <Button class="px-2" @onClick={{or @close c.actions.cancel}} data-test-close-icon>
            <Icon @name="x" @w="w-4" />
          </Button>
        </div>
        <div class="p-6">
          <div class="text-center">
            {{htmlSafeSanitized @message}}
          </div>
        </div>
        <div class="flex gap-3 p-3">
          <ButtonTopSecondary @testSelector="cancel" @onClick={{c.actions.cancel}} @classes="flex-1">{{or @cancelText "Cancel"}}</ButtonTopSecondary>
          <ButtonTopPrimary @testSelector="confirm" @onClick={{c.actions.confirm}} @classes="flex-1">{{or @confirmText "Confirm"}}</ButtonTopPrimary>
        </div>
      </div>
    </Dialog>
  </Confirmation>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ModalConfirmationComponent;
