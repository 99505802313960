import Component from '@glimmer/component';
import without from 'lodash-es/without';
import { Machine, assign } from 'xstate';

import { action } from '@ember/object';

import { useMachine } from 'ember-statecharts';

import type { Offset } from '../utils/canvas-drawing.ts';
import type { SeatBase } from '../utils/seat-editor.ts';
import { matchesState } from '../utils/statecharts.ts';

type Context = {
  selectedSeats: SeatBase[];
};

const machine = Machine<Context>(
  {
    initial: 'noSelection',
    context: {
      selectedSeats: []
    },
    states: {
      noSelection: {
        entry: ['notifySeatSelectionCleared'],
        on: {
          CLICK_SEAT: {
            target: 'seatsSelected',
            actions: ['selectSeat']
          },
          SHIFT_CLICK_SEAT: {
            target: 'seatsSelected',
            actions: ['selectSeat']
          },
          MULTISELECT_SEATS: {
            target: 'seatsSelected',
            actions: ['selectSeats'],
            cond: 'seatSelectionIsNotEmpty'
          },
          SHIFT_MULTISELECT_SEATS: {
            target: 'seatsSelected',
            actions: ['selectSeats']
          }
        }
      },
      seatsSelected: {
        entry: ['notifySeatSelectionChanged'],
        on: {
          CLICK_SEAT: [
            {
              target: 'noSelection',
              cond: 'seatIsOnlySelectedSeat',
              actions: ['clearSelectedSeats']
            },
            {
              target: 'seatsSelected',
              cond: 'seatIsAlreadySelectedAndContinousSelection',
              actions: ['removeSeatFromSelection']
            },
            {
              target: 'seatsSelected',
              cond: 'continousSelection',
              actions: ['addSelectedSeatToSelection']
            },
            { target: 'seatsSelected', actions: ['selectSeat'] }
          ],
          SHIFT_CLICK_SEAT: [
            {
              target: 'seatsSelected',
              cond: 'seatIsAlreadySelected',
              actions: ['removeSeatFromSelection']
            },
            {
              target: 'seatsSelected',
              actions: ['addSelectedSeatToSelection']
            }
          ],
          MULTISELECT_SEATS: [
            {
              target: 'seatsSelected',
              cond: 'continousSelection',
              actions: ['addSelectedSeatsToSelection']
            },
            {
              target: 'noSelection',
              cond: 'seatSelectionIsEmpty',
              actions: ['clearSelectedSeats']
            },
            {
              target: 'seatsSelected',
              actions: ['selectSeats']
            }
          ],
          SHIFT_MULTISELECT_SEATS: {
            target: 'seatsSelected',
            actions: ['addSelectedSeatsToSelection']
          },
          APPLY_OFFSET_SEATS: {
            target: 'seatsSelected',
            actions: ['applyOffsetToSelection']
          }
        }
      }
    }
  },
  {
    actions: {
      selectSeat: assign({
        selectedSeats: (_, { seat }) => [seat]
      }),
      selectSeats: assign({
        selectedSeats: (_, { seats }) => seats
      }),
      clearSelectedSeats: assign({
        selectedSeats: []
      }),
      addSelectedSeatToSelection: assign({
        selectedSeats: (c, { seat }) => [...c.selectedSeats, seat].uniq()
      }),
      addSelectedSeatsToSelection: assign({
        selectedSeats: (c, { seats }) => [...c.selectedSeats, ...seats].uniq()
      }),
      removeSeatFromSelection: assign({
        selectedSeats: (c, { seat }) => without(c.selectedSeats, seat).uniq()
      }),
      applyOffsetToSelection() {},
      notifySeatSelectionChanged() {},
      notifySeatSelectionCleared() {}
    },
    guards: {
      continousSelection(_context, { continousSelection }) {
        return continousSelection;
      },
      seatIsAlreadySelectedAndContinousSelection: (
        c,
        { continousSelection, seat }
      ) => continousSelection && c.selectedSeats.includes(seat),
      seatIsOnlySelectedSeat(context, { seat }) {
        return (
          context.selectedSeats.length === 1 &&
          context.selectedSeats.includes(seat)
        );
      },
      seatIsAlreadySelected: (c, { seat }) => c.selectedSeats.includes(seat),
      seatSelectionIsEmpty: (_, { seats }) => seats.length === 0,
      seatSelectionIsNotEmpty: (_, { seats }) => seats.length > 0
    }
  }
);

interface SeatManagementSignature {
  Args: {
    applyOffsetToSelection?: (
      offsetX: number,
      offsetY: number,
      seats: SeatBase[]
    ) => void;
    continousSelection?: boolean;
    onSeatsDragged?: Function;
    onSeatsSelectionChanged?: Function;
    onSeatsSelectionCleared?: Function;
  };
  Blocks: {
    default: [unknown];
  };
}

/**
 * Manages seat selection (`selectedSeats` state)
 */
export default class SeatManagementComponent extends Component<SeatManagementSignature> {
  @matchesState('noSelection') noSelection!: boolean;

  @matchesState('seatsSelected') seatsAreSelected!: boolean;

  get selectedSeats(): SeatBase[] {
    return this.statechart.state?.context.selectedSeats || [];
  }

  statechart = useMachine(this, () => ({
    machine: machine.withConfig({
      actions: {
        applyOffsetToSelection: (_, { offset }) =>
          this.applyOffsetToSelection(offset),
        notifySeatSelectionChanged: context =>
          this.args.onSeatsSelectionChanged?.(context.selectedSeats),
        notifySeatSelectionCleared: context =>
          this.args.onSeatsSelectionCleared?.(context.selectedSeats)
      }
    })
  }));

  private applyOffsetToSelection(offset: Offset) {
    this.args.applyOffsetToSelection?.(offset.x, offset.y, this.selectedSeats);
    this.statechart.send('MULTISELECT_SEATS', {
      seats: [...this.selectedSeats]
    });
    this.args.onSeatsDragged?.();
  }

  @action
  onSeatSelected(seat: SeatBase, event?: MouseEvent) {
    const shiftKey = (event && event.shiftKey) || null;
    const { continousSelection } = this.args;

    if (shiftKey) {
      this.statechart.send('SHIFT_CLICK_SEAT', { seat, continousSelection });
    } else {
      this.statechart.send('CLICK_SEAT', { seat, continousSelection });
    }
  }

  @action
  onSeatsSelected(seats: SeatBase[], event?: MouseEvent) {
    if (event) {
      const { shiftKey } = event;

      if (shiftKey) {
        this.statechart.send('SHIFT_MULTISELECT_SEATS', { seats });
      } else {
        this.statechart.send('MULTISELECT_SEATS', { seats });
      }
    } else {
      this.statechart.send('MULTISELECT_SEATS', { seats });
    }
  }

  @action
  onSeatsDragged(offset: Offset) {
    if (offset.x || offset.y) {
      this.statechart.send('APPLY_OFFSET_SEATS', { offset });
    }
  }

  @action
  clearSelection() {
    this.statechart.send('MULTISELECT_SEATS', { seats: [] });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    SeatManagement: typeof SeatManagementComponent;
    'seat-management': typeof SeatManagementComponent;
  }
}
