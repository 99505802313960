import Component from '@glimmer/component';

interface FormBaseWrapperLinkableLabelSignature {
  Args: {};
  Blocks: {
    default: [unknown];
  };
  Element: HTMLDivElement;
}

export default class FormBaseWrapperLinkableLabelComponent extends Component<FormBaseWrapperLinkableLabelSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Form::Base::Wrapper::LinkableLabel': typeof FormBaseWrapperLinkableLabelComponent;
    'form/base/wrapper/linkable-label': typeof FormBaseWrapperLinkableLabelComponent;
  }
}
