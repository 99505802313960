import FormInputBaseComponent from './base.ts';

type Args = {
  symbolClass?: string;
};

export default class FormCurrency extends FormInputBaseComponent<Args> {
  get symbolClass(): string {
    return this.args.symbolClass ?? 'text-gray-600';
  }
}
