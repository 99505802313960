import { template } from "@ember/template-compiler";
import { action } from '@ember/object';
import didInsert from '@ember/render-modifiers/modifiers/did-insert';
import type RouterService from '@ember/routing/router';
import { inject as service } from '@ember/service';
import or from 'ember-truth-helpers/helpers/or';
import htmlSafeSanitized from '../../helpers/html-safe-sanitized.ts';
import type { NotificationType } from '../../services/notifications.ts';
import Button from '../button.gts';
import ButtonDark from '../button/neutral/dark.gts';
import Icon from '../icon.gts';
import Loading from '../loading.gts';
import NotificationBase from './notification-base.gts';
const COLORS: {
    borderColor: {
        [key in NotificationType & 'default']: string;
    };
} = {
    borderColor: {
        success: 'border-green-500',
        warning: 'border-amber-300',
        error: 'border-red-500',
        neutral: 'border-blue-300',
        default: 'border-teal-500',
        intermediate: 'border-blue-300'
    }
};
const ICONS: {
    [key in NotificationType & 'default']: string;
} = {
    success: 'check',
    warning: 'alert-new',
    error: 'alert-new',
    neutral: 'info',
    default: '',
    intermediate: ''
};
export default class NotificationModern extends NotificationBase<{
}> {
    @service
    router: RouterService;
    type = this.args.notification.type || 'default';
    isIntermediate = this.type === 'intermediate';
    borderColor = COLORS.borderColor[this.type];
    icon = ICONS[this.type];
    displayCloseButton = this.args.notification.closeable ?? true;
    @action
    checkLinks(element1: HTMLElement) {
        const icon1 = element1.querySelector('[data-notification-link-icon]');
        element1.querySelectorAll('a.notification-link').forEach((link1: HTMLAnchorElement)=>{
            link1.append(icon1!.firstElementChild!.cloneNode(true));
            link1.addEventListener('click', (e1)=>this.args.openLink(link1, e1));
        });
    }
    static{
        template(`
    <div
      data-test-notification={{or @notification.testSelector true}}
      data-test-notification-type={{@notification.type}}
      class="mb-3 max-w-md
        {{if @isVisible "animation-flash-show" "animation-flash-hide"}}
      "
      {{didInsert this.checkLinks}}
    >
      <div class="hidden" data-notification-link-icon>
        <Icon @name="popup" @w="w-3" @classes="ml-2" />
      </div>

      <div class="bg-[#222222] text-white shadow
        rounded border-t-8 {{this.borderColor}} p-3
        cursor-pointer
        {{if @isVisible "animation-flash-in" "animation-flash-out"}}
        {{if this.isIntermediate "pr-3"}}
        "
      >
        <div class="flex justify-between items-start gap-3">
          {{#if this.icon}}
            <div class="text-gray-600">
              <Icon @name={{this.icon}} @w="w-7" />
            </div>
          {{/if}}

          {{#if this.isIntermediate}}
            <div class="relative px-3">
              <Loading @type="dots" />
            </div>
          {{/if}}

          <div class="flex-grow mt-0.5">
            {{#if @notification.header}}
              <div class="font-medium" data-test-notification-header>
                {{@notification.header}}
              </div>
            {{else if @notification.message}}
              <div class="flex" data-test-notification-message>
                <div class="flex-grow font-medium">
                  {{htmlSafeSanitized @notification.message}}
                </div>
              </div>
            {{/if}}

          </div>

          {{#unless this.isIntermediate}}
            {{#if @notification.action}}
              <ButtonDark
                @onClick={{@notification.action}}
                @classes="mt-0.5 mr-3"
                @medium={{true}}
              >
                {{@notification.actionLabel}}
              </ButtonDark>
            {{/if}}
            {{#if this.displayCloseButton}}
              <Button @onClick={{@dismissNotification}} class="text-gray-600">
                <Icon @name="cross" @w="w-7" />
              </Button>
            {{/if}}
          {{/unless}}
        </div>
        {{#if @notification.header}}
          <div>
            {{#if @notification.message}}
              <div class="flex text-gray-400" data-test-notification-message>
                <div class="flex-grow mt-1">
                  {{htmlSafeSanitized @notification.message}}
                </div>
              </div>
            {{/if}}

            {{#if @notification.list}}
              <ul class="p-0 text-gray-400 mt-1">
                {{#each @notification.list as |listMessage|}}
                  <li data-test-notification-list-item class="mb-1">{{htmlSafeSanitized listMessage}}</li>
                {{/each}}
              </ul>
            {{/if}}
          </div>
        {{else if @notification.list}}
          <ul class="p-0 text-gray-400 mt-1">
            {{#each @notification.list as |listMessage|}}
              <li data-test-notification-list-item class="mb-1">{{htmlSafeSanitized listMessage}}</li>
            {{/each}}
          </ul>
        {{/if}}
      </div>
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
