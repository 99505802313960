import Component from '@glimmer/component';

import { htmlSafeSanitized } from '../../../helpers/html-safe-sanitized.ts';
import { getTheme, type FormTheme } from '../theme.ts';

interface Signature {
  Args: BaseArgs & InputArgs;
  Element: HTMLInputElement;
}
interface BaseArgs {
  id?: string;
  value?: string | null | undefined;
  placeholder?: string;
  isInvalid?: boolean;
  isRequired?: boolean;
  displayIconLeft?: boolean;
  displayIconRight?: boolean;
  onClickIcon?: Function;
  onClear?: Function;
  isDisabled?: boolean;
  theme?: FormTheme;
}
interface InputArgs {
  value?: string;
  icon?: string;
  iconClasses?: string;
  iconSize?: string;
  h?: string;
  wrapperClasses?: string;
  screenReaderText?: string;
  autoGrow?: boolean;
}

export default class FormInputBase<T> extends Component<T & Signature> {
  style = getTheme(this.args.theme);

  get screenReaderText() {
    return this.args.screenReaderText ?? this.args.icon;
  }

  get displayIconLeft() {
    return this.args.icon && !this.args.displayIconRight;
  }

  get displayIconRight() {
    return this.args.icon && this.args.displayIconRight;
  }

  get displayClearButton() {
    return (
      this.args.onClear &&
      typeof this.args.value === 'string' &&
      this.args.value !== ''
    );
  }

  get autoGrowStyle() {
    if (this.args.autoGrow) {
      return htmlSafeSanitized(['field-sizing: content;']);
    }
    return null;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Form::Input::Base': typeof FormInputBase;
    'form/input/base': typeof FormInputBase;
  }
}
