import EmberTether from 'ember-tether/components/ember-tether';

export default class extends EmberTether {
  sameWidth = false;

  positionTether() {
    if (this._tether && this.sameWidth && this._tether.element) {
      const { target, element } = this._tether;
      const targetWidth = target.offsetWidth || target.clientWidth;
      if (targetWidth) {
        const style = (element.getAttribute('style') || '').replace(
          /width:[^;]+;/gim,
          ''
        );
        const width = `${targetWidth}px`;
        element.setAttribute('style', `${width};${style}`);
        element.style.width = width;
      }
    }
    super.positionTether();

    if (this._tether?.element) {
      this.onPosition?.(this._tether.element);
    }
  }
}
