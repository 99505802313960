import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import type Model from '@ember-data/model';
import { action } from '@ember/object';

import type { LoadPaginatedResult } from './async-pagination.ts';

const DEFAULT_PAGE_LIMIT = 10;

export type AsyncFirstPage = (offset?: number, limit?: number) => Promise<void>;

interface AsyncInfinitePaginationSignature {
  Args: {
    do: LoadPaginatedResult;
    pageLimit?: number;
  };
  Blocks: {
    default: [unknown];
  };
}

export default class AsyncInfinitePaginationComponent extends Component<AsyncInfinitePaginationSignature> {
  @tracked offset: number = 0;
  @tracked total?: number;

  @tracked results: Model[] = [];

  get pageLimit(): number {
    return this.args.pageLimit ?? DEFAULT_PAGE_LIMIT;
  }
  get hasResults() {
    return this.results.length > 0;
  }
  get hasNextPage() {
    if (this.total === undefined) {
      return true;
    }
    return this.results.length < this.total;
  }

  @action
  firstPage(
    asyncLoadNextPage: Function,
    offset = 0,
    pageLimit = this.pageLimit
  ) {
    this.offset = offset;

    return asyncLoadNextPage(offset, pageLimit);
  }

  @action
  nextPage(asyncLoadNextPage: Function) {
    this.offset = this.results.length;

    return asyncLoadNextPage(this.offset, this.pageLimit);
  }

  @action
  retry(asyncLoadNextPage: Function) {
    return asyncLoadNextPage(this.offset, this.pageLimit);
  }

  @action
  async do(offset: number, pageLimit: number) {
    const { result, total } = await this.args.do(offset, pageLimit);

    this.total = total;

    if (this.offset === 0) {
      this.results = result;
    } else {
      this.results = [...this.results, ...result];
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    AsyncInfinitePagination: typeof AsyncInfinitePaginationComponent;
    'async-infinite-pagination': typeof AsyncInfinitePaginationComponent;
  }
}
