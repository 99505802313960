import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { action } from '@ember/object';
import and from 'ember-truth-helpers/helpers/and';
import eq from 'ember-truth-helpers/helpers/eq';
import or from 'ember-truth-helpers/helpers/or';
import TetheredPopup from '../tethered-popup.ts';
import AttachmentTooltipArrow from './tooltip/arrow.gts';
interface Signature {
    Args: {
        guid: string;
        isEnabled?: boolean;
        displayTooltip?: boolean;
        isPopover?: boolean;
        isLarge?: boolean;
        hideTooltip: Function;
        bgColor?: string;
        borderColor?: string;
        textColor?: string;
        anchor?: string;
        tetherClasses?: string;
        useShadow?: boolean;
        testSelector?: string;
        zIndex?: string;
        classes?: string;
    };
    Blocks: {
        default: [];
    };
}
export default class AttachmentTooltip extends Component<Signature> {
    get isEnabled() {
        return this.args.isEnabled ?? true;
    }
    get displayTooltip() {
        return this.args.displayTooltip ?? false;
    }
    get isPopover(): boolean {
        return this.args.isPopover;
    }
    get isTooltip(): boolean {
        return !this.isPopover;
    }
    get isOpen(): boolean {
        return this.isEnabled && this.displayTooltip;
    }
    get borderColor() {
        return this.args.borderColor ?? 'black';
    }
    get bgColor() {
        if (this.args.bgColor) return this.args.bgColor;
        if (this.isPopover) return 'white';
        return 'black';
    }
    get textColor() {
        if (this.args.textColor) return this.args.textColor;
        if (this.isPopover) return 'black';
        return 'white';
    }
    get isLarge() {
        if (this.args.isLarge) return this.args.isLarge;
        return this.isPopover;
    }
    get anchorTokens() {
        return this.args.anchor.split?.(' ');
    }
    get firstToken(): string {
        return this.anchorTokens[0];
    }
    get secondToken() {
        const secondToken1 = this.anchorTokens.objectAt(1);
        return secondToken1 || 'center';
    }
    get axis() {
        if ([
            'top',
            'bottom'
        ].includes(this.firstToken)) {
            return 'x';
        }
        if ([
            'left',
            'right'
        ].includes(this.firstToken)) {
            return 'y';
        }
        return undefined;
    }
    get axisPosition() {
        if (this.axis === 'x') {
            switch(this.secondToken){
                case 'left':
                    return 'start';
                case 'right':
                    return 'end';
                default:
                    return 'center';
            }
        }
        if (this.axis === 'y') {
            switch(this.secondToken){
                case 'top':
                    return 'start';
                case 'bottom':
                    return 'end';
                default:
                    return 'center';
            }
        }
        return undefined;
    }
    get positionClasses() {
        if (this.secondToken !== 'center') {
            return `-m${this.secondToken.substring(0, 1)}-3`;
        }
        return '';
    }
    get colorClasses() {
        return `bg-${this.bgColor} border-${this.borderColor} text-${this.textColor}`;
    }
    get target() {
        return `[data-tether-target-id="${this.args.guid}"]`;
    }
    get targetAttachment() {
        const { axis: axis1, firstToken: firstToken1, secondToken: secondToken1 } = this;
        if (axis1 === 'x') {
            return `${firstToken1} ${secondToken1}`;
        }
        if (axis1 === 'y') {
            return `${secondToken1} ${firstToken1}`;
        }
        return undefined;
    }
    get attachment() {
        const { axis: axis1, firstToken: firstToken1, secondToken: secondToken1 } = this;
        const inverseFor1 = (point1: string)=>{
            switch(point1){
                case 'top':
                    return 'bottom';
                case 'bottom':
                    return 'top';
                case 'left':
                    return 'right';
                case 'right':
                    return 'left';
                default:
                    return '';
            }
        };
        if (axis1 === 'x') {
            return `${inverseFor1(firstToken1)} ${secondToken1}`;
        }
        if (axis1 === 'y') {
            return `${secondToken1} ${inverseFor1(firstToken1)}`;
        }
        return undefined;
    }
    get isArrowBefore() {
        return [
            'bottom',
            'right'
        ].includes(this.firstToken);
    }
    get isArrowAfter(): boolean {
        return !this.isArrowBefore;
    }
    @action
    onPosition(element1: HTMLElement) {
        if (!element1) return;
        const arrow1 = element1.querySelector('.tooltip-arrow-container') as HTMLElement;
        const rect1 = element1.getBoundingClientRect();
        const padding1 = 30; // a bit of visual padding
        // If the rect is bigger than the window shrink it
        if (rect1.width > window.innerWidth) {
            element1.style.maxWidth = `${window.innerWidth - padding1}px`;
        } else {
            element1.style.maxWidth = `${window.innerWidth / 2}px`;
        }
        // if the rect overflows on the right
        if (Math.floor(rect1.x) + rect1.width > window.innerWidth) {
            const result1 = rect1.width + rect1.x + padding1 - window.innerWidth;
            element1.style.left = `-${result1}px`;
            if (arrow1) {
                // Get the percentage of change and move the arrow that far over
                // with a max of 45 to prevent the arrow moving too far
                arrow1.style.marginLeft = `${Math.min(45, (result1 / rect1.width) * 100)}%`;
            }
        }
        // If rect.x is > 0 the tooltip is out of bounds on the left
        if (Math.floor(rect1.x) < 0) {
            element1.style.left = `${Math.abs(rect1.x)}px`;
            if (arrow1) arrow1.style.marginLeft = `-${Math.abs(rect1.x)}px`;
        }
    }
    static{
        template(`
    <TetheredPopup
      @isOpen={{this.isOpen}}
      @onBackgroundClicked={{@hideTooltip}}
      @target={{this.target}}
      @targetAttachment={{this.targetAttachment}}
      @attachment={{this.attachment}}
      @tetherClasses="
        {{or @zIndex "z-tooltip"}}
        {{if this.isOpen "py-1" "hidden"}}
        {{if this.isOpen this.positionClasses}}
        {{@tetherClasses~}}
      "
      @contentClasses={{if @useShadow "shadow "}}
      @zIndex="z-tooltip"
      @onPosition={{this.onPosition}}
    >
      <div class="flex {{if (eq this.axis "x") "flex-col"}}">
        {{#let (component AttachmentTooltipArrow
            isLarge=this.isLarge
            axis=this.axis
            axisPosition=this.axisPosition
            isArrowBefore=this.isArrowBefore
            colorClasses=this.colorClasses
            testSelector=@testSelector
          ) as |arrow|
        }}
          {{#if (and this.isTooltip this.isArrowBefore)}}
            {{arrow}}
          {{/if}}
          <div
            class="tooltip-content {{this.colorClasses}} {{@classes}}"
            data-test-tooltip={{@testSelector}}
          >
            {{yield}}
          </div>
          {{#if (and this.isTooltip this.isArrowAfter)}}
            {{arrow}}
          {{/if}}
        {{/let}}
      </div>
    </TetheredPopup>

  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'UiAttachment::Tooltip': typeof AttachmentTooltip;
        'ui-attachment/tooltip': typeof AttachmentTooltip;
    }
}
