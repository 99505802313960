import Component from '@glimmer/component';

import { action } from '@ember/object';

import type { FormYieldedArgs } from '../form.ts';

interface FormFormSignature {
  Args: {
    f?: FormYieldedArgs;
    testSelector?: string;
  };
  Blocks: { default: [] };
  Element: HTMLFormElement;
}

export default class FormForm extends Component<FormFormSignature> {
  @action
  submit(event: SubmitEvent) {
    return this.args.f?.actions.submit(event);
  }

  @action
  cleanupFormState() {
    this.args.f?.state.formObject.cleanup();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Form::Form': typeof FormForm;
  }
}
