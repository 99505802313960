import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { action } from '@ember/object';
import { isBlank } from '@ember/utils';

import type { DropdownOption } from '../../../components/dropdown.ts';
import { optionsTransform } from '../../../components/dropdown.ts';

interface BaseArgs {
  id: string;
  value: any;
  placeholder: string;
  testSelector: string;
  displayErrors: boolean;
  markInteractedWith: Function;
  onChange: (value: any) => void;
}
interface TypeaheadArgs {
  search: Function;
  loadMoreResults: Function;
  recordsCount: number;

  canCreateOnEnter?: boolean;
  createOption: (name: string) => Promise<any>;

  icon?: string;
  optionsTransform?: (item: any) => DropdownOption;
  disabled?: boolean;
  styleAsDropdown?: boolean;
  sort?: Function;
  sortByParam?: string;
  onClear?: Function;
}

type Args<T> = BaseArgs & TypeaheadArgs & T;

export default class FormTypeaheadBaseComponent<T = {}> extends Component<
  Args<T>
> {
  get optionsTransform(): (item: any) => DropdownOption {
    return this.args.optionsTransform ?? optionsTransform();
  }

  get styleAsDropdown() {
    return this.args.styleAsDropdown ?? false;
  }

  @tracked displayValue = true;

  @action onPerformAdd(value: any) {
    this.args.onChange(value);
    this.displayValue = true;
  }
  @action
  async onEnterPressed(itemOrSearchTerm: any | string, searchResults: any[]) {
    this.args.onChange(
      await this.maybeCreateOption(itemOrSearchTerm, searchResults)
    );
    this.displayValue = true;
  }

  private async maybeCreateOption(
    itemOrSearchTerm: any | string,
    searchResults: any[]
  ) {
    const shouldCreateOption =
      this.args.createOption &&
      !searchResults.includes(itemOrSearchTerm) &&
      typeof itemOrSearchTerm === 'string' &&
      !isBlank(itemOrSearchTerm);

    if (shouldCreateOption) {
      return await this.args.createOption(itemOrSearchTerm);
    }

    return itemOrSearchTerm;
  }

  @action onResultsHidden() {
    this.displayValue = true;
    this.args.markInteractedWith();
  }

  @action
  clearValue() {
    this.args.onChange(null);
    this.args.onClear?.();
    this.args.markInteractedWith();
  }

  @action
  hideValue() {
    this.displayValue = false;

    if (this.styleAsDropdown) {
      this.clearValue();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Form::Typeahead::Base': typeof FormTypeaheadBaseComponent;
    'form/typeahead/base': typeof FormTypeaheadBaseComponent;
  }
}
