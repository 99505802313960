import { action } from '@ember/object';

import Component from '../../../components/form/typeahead/base.ts';

type Args = {
  searchOnEnter?: boolean;
  searchOnType?: boolean;
};

export default class TypeaheadBase extends Component<Args> {
  @action
  select(selectFn: Function, event: any) {
    return selectFn(event?.target?.value);
  }

  @action
  searchOnEnter(search: Function, event: KeyboardEvent) {
    if (this.args.searchOnEnter && event.key === 'Enter') {
      return search();
    }
  }
}
