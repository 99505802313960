import { tracked } from '@glimmer/tracking';
import without from 'lodash-es/without';

import { scheduleOnce } from '@ember/runloop';
import Service from '@ember/service';
import { inject as service } from '@ember/service';

type LinkManagerService = any;

export interface DeactivateHandler {
  canDeactivate: () => boolean;
  couldntDeactivate: Function;
}
export class TabsContext {
  private linkManager!: LinkManagerService;

  @tracked context!: string;
  @tracked tabs: TabInstance[] = [];
  @tracked activeTab?: TabInstance;
  @tracked contentWrapper?: HTMLElement;
  lastCloseAttempt: TabInstance | null = null;
  lastRemoveAttempt: TabInstance | null = null;

  constructor(context: string, linkManager: LinkManagerService) {
    this.context = context;
    this.linkManager = linkManager;
  }

  public addTab(tab: TabInstance) {
    const _tab = this.getTabById(tab.id);
    if (_tab) {
      return _tab;
    } else {
      this.tabs = [...this.tabs, tab];
    }
    return tab;
  }

  private async activateNextTab(tab: TabInstance, index: number) {
    let newIndex: number;
    if (this.tabs.length === 2) {
      // If there are only two tabs, switch to the other tab
      newIndex = 1 - index;
    } else {
      newIndex = Math.max(Math.min(index - 1, this.tabs.length - 2), 0);
    }
    const isActivated = await this.setActiveTab(this.tabs[newIndex]);
    this.lastRemoveAttempt = isActivated ? null : tab;
    return isActivated;
  }

  private async canRemoveTab(tab: TabInstance) {
    const index = this.tabs.indexOf(tab);
    if (index >= 0) {
      if (this.activeTab === tab) {
        return await this.activateNextTab(tab, index);
      } else {
        return true;
      }
    }
    return false;
  }

  public async removeTab(tab: TabInstance) {
    if (await this.canRemoveTab(tab)) {
      this.tabs = [...without(this.tabs, tab)];
    }
  }

  public getTabById(_id: string) {
    return this.tabs.find(({ id }) => id === _id);
  }

  public getTabByLabel(_label: string) {
    return this.tabs.find(({ label }) => label === _label);
  }

  private async activateTab(tab: TabInstance): Promise<void> {
    this.activeTab = tab;
    if (tab.route) {
      const { route, model, models, query } = tab;
      const linkParams = {
        route,
        models: [...(models ?? []), model].compact(),
        query
      };
      const link = this.linkManager.createLink(linkParams);
      if (!link.isActiveWithoutQueryParams) {
        // We need to wait for the transition to complete to finish
        // setting the tab active
        await new Promise<void>(resolve => {
          const replaceLink = async () => {
            await link.replaceWith();
            resolve();
          };
          scheduleOnce('afterRender', this, replaceLink);
        });
      }
    }
  }

  private canDeactivateActiveTab(tab: TabInstance) {
    if (this.activeTab && !this.activeTab.canDeactivate()) {
      this.lastCloseAttempt = tab;
      this.activeTab.deactivateHandlers.forEach(handler =>
        handler.couldntDeactivate()
      );
      return false;
    }
    this.lastCloseAttempt = null;
    return true;
  }

  public async setActiveTab(tab: TabInstance) {
    if (this.getTabById(tab.id) && this.activeTab?.id !== tab.id) {
      if (this.canDeactivateActiveTab(tab)) {
        await this.activateTab(tab);
        return true;
      }
    }
    return false;
  }
}

export type TabPosition = {
  left: number;
  width: number;
};

export class TabInstance<TExtra = any> {
  @tracked label: string;
  @tracked closeable: boolean = false;
  @tracked disabled: boolean = false;
  @tracked route?: string;
  @tracked model?: any;
  @tracked models?: any[];
  @tracked query?: any;
  @tracked extra?: TExtra;
  @tracked _id?: string;

  @tracked tabElement?: HTMLElement | null;

  get position() {
    if (this.tabElement) {
      return {
        left: this.tabElement.offsetLeft,
        width: this.tabElement.offsetWidth
      };
    }
    return null;
  }

  @tracked deactivateHandlers: DeactivateHandler[] = [
    { canDeactivate: () => true, couldntDeactivate: () => {} }
  ];

  constructor(
    label: string,
    options: Pick<
      TabInstance,
      | 'closeable'
      | 'disabled'
      | 'route'
      | 'model'
      | 'models'
      | 'query'
      | 'extra'
      | '_id'
    >
  ) {
    this.label = label;
    Object.assign(this, options);
  }

  get id() {
    const { label, route, model, models, query, _id } = this;
    if (_id) {
      return _id;
    }
    const modelsRep = models?.reduce((acc, model) => `${acc}_${model}`, '');
    return `${label}_${route}_${model}_${modelsRep}_${query}`;
  }

  public registerDeactivateHandler(handler: DeactivateHandler) {
    this.deactivateHandlers = [...this.deactivateHandlers, handler];
  }

  public unregisterDeactivateHandler(handler: DeactivateHandler) {
    this.deactivateHandlers = without(this.deactivateHandlers, handler);
  }

  canDeactivate() {
    return this.deactivateHandlers.every(handler => handler.canDeactivate());
  }
}

export default class TabsService extends Service {
  // @ts-ignore
  @service('link-manager')
  private linkManager!: LinkManagerService;

  @tracked contexts: { [key: string]: TabsContext } = {};

  public getTabsContext(contextName: string) {
    let context = this.contexts[contextName];
    if (!context) {
      context = new TabsContext(contextName, this.linkManager);
      this.contexts[contextName] = context;
    }
    return context;
  }
}

declare module '@ember/service' {
  interface Registry {
    tabs: TabsService;
  }
}
