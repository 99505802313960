import { helper } from '@ember/component/helper';

import type { OptionsTransformArg } from '../components/dropdown.ts';
import { optionsTransform } from '../components/dropdown.ts';

export interface OptionsTransformSignature {
  Args: {
    Positional: [OptionsTransformArg?];
  };
  Return: ReturnType<typeof transform>;
}

function transform([options]: [OptionsTransformArg?]) {
  return optionsTransform(options);
}

export default helper<OptionsTransformSignature>(transform);
