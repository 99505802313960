import type { Config } from 'dompurify';
import DOMPurify from 'dompurify';

import { helper } from '@ember/component/helper';
import { htmlSafe } from '@ember/template';
import type { SafeString } from '@ember/template/-private/handlebars';

export const DEFAULT_CONFIG: Config = {
  RETURN_TRUSTED_TYPE: false,
  RETURN_DOM_FRAGMENT: false,
  RETURN_DOM: false,
  USE_PROFILES: { html: true },
  ADD_ATTR: ['target']
};

export interface HtmlSafeSanitizedSignature {
  Args: {
    Positional: [dirty: string, config?: Config];
  };
  Return: SafeString;
}

/**
 * Sanitize HTML
 *
 * For usage see https://github.com/cure53/DOMPurify#can-i-configure-dompurify
 */
export function htmlSafeSanitized([dirty, config = DEFAULT_CONFIG]: [
  string,
  Config?
]): ReturnType<typeof htmlSafe> {
  const clean = DOMPurify.sanitize(dirty, config) as string;
  return htmlSafe(clean);
}

export function isSafeHtml([
  dirty,
  config = {
    RETURN_TRUSTED_TYPE: false,
    RETURN_DOM_FRAGMENT: false,
    RETURN_DOM: false,
    USE_PROFILES: { html: true },
    ADD_ATTR: ['target']
  }
]: [string, Config?]): boolean {
  const clean = DOMPurify.sanitize(dirty, config) as string;
  return clean === dirty;
}

export default helper<HtmlSafeSanitizedSignature>(htmlSafeSanitized);
