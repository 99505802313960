import camelCase from 'lodash-es/camelCase';
import { ValidationError } from 'yup';

import type AdapterError from '@ember-data/adapter/error';
import type { NotFoundError } from '@ember-data/adapter/error';
import { isEmpty, typeOf } from '@ember/utils';

import type { AjaxError } from 'ember-ajax/errors';

type ValuesOf<T extends any[]> = T[number];

export type DefaultKey = 'detail';
export type UserMessageKeys = [DefaultKey, 'description', 'message'];

export type Message = string;
export type UserMessage = Message;
export type UserMessageKey = ValuesOf<typeof USER_MESSAGE_KEYS>;
export type ErrorMessage = UserMessage | string;
export type ErrorMessageCollection = UserMessage[] | string[];

export type InternalServerError = {
  title: 'Internal Server Error';
  detail: 'Internal Server Error';
  code: '500';
  status: '500';
};

export type LibError = {
  error: 'invalid_error' | string;
};

type ErrorName = 'Error' | 'TypeError';

export type BaseError = {
  detail?: string;
  status?: string;
  code?: string;
  description?: string;
  message?: string;
  name?: ErrorName;
};

type ServerValidationError = BaseError & {
  detail: string;
  source: {
    pointer: string;
  };
};

export type ServerValidationErrorCollection = {
  errors: ServerValidationError[];
};

export type BaseErrorCollection = {
  errors: Array<BaseError>;
  [x: string]: any;
};

export type NormalizedError = {
  detail: string;

  status?: string;
  code?: string;
  description?: string;
  message?: string;
};

type NormalizedErrorOpts = {
  key: keyof BaseError;
};

export type Error =
  | BaseError
  | BaseErrorCollection
  | ServerValidationError
  | (NotFoundError & { message: string; detail?: string });

export type PotentialError =
  | undefined
  | null
  | string
  | string[]
  | Error
  | Error[]
  | AdapterError
  | AjaxError
  | any;

/* @property GENERIC_ERROR_MESSAGE
 * @type String
 *
 * A generic message to display when
 * - A 500 error is thrown
 * - No error message is returned
 */
export const GENERIC_ERROR_MESSAGE =
  'Please try again. Otherwise, contact support if problem persists.';

const ED_GENERIC_ERROR_MESSAGES = ['Request Rejected with an Unknown Error'];

export const INVALID_ERROR: LibError = { error: 'invalid_error' };

/* @property DEFAULT_KEY
 *
 * This param specifies the name of the key
 * we expect to contain the error message.
 */
export const DEFAULT_KEY: DefaultKey = 'detail';

/* @property USER_MESSAGE_KEYS
 *
 * This is an ___ordered list___ of possible keys
 * that contain a message we can show to a user.
 * These messages must contain actionable
 * information a user can use to rectify the issue
 * they are facing.
 *
 * If an error message does not meet that specification
 * then a generic message should be shown
 */
export const USER_MESSAGE_KEYS: UserMessageKeys = [
  DEFAULT_KEY,
  'description',
  'message'
];

/* @method getAllMessages
 *
 * This function takes an error and returns
 * all error messages from it. Including
 * internal server messages.
 */
export function getAllMessages(error: PotentialError) {
  return getErrors(error).reduce((acc, err) => {
    const message = getUserMessageFromError(err);
    return message ? acc.concat(message) : acc;
  }, [] as Array<Message>);
}

/* @method getAllUserMessagesOrDefault
 *
 * This function wraps getAllUserMessages
 * and providers a default message if none are
 * found
 */
export function getAllUserMessagesOrDefault(
  error: PotentialError,
  { defaultMsg = GENERIC_ERROR_MESSAGE } = {}
): UserMessage[] {
  const userMessages = getAllUserMessages(error);
  return isEmpty(userMessages) ? [defaultMsg] : userMessages;
}

/* @method getAllUserMessages
 *
 * This function will take an error and return all
 * the user messages contained within the errors
 * list under one of the UserMessageKeys.
 *
 * Note that internal server errors will also be
 * discarded as they do not contain any actionable
 * information for the user.
 */
export function getAllUserMessages(error: PotentialError): Array<UserMessage> {
  const errors = getErrors(error);

  return errors.reduce((acc, err) => {
    if (isInternalServerError(err)) return acc;

    const message = getUserMessageFromError(err);

    return message ? acc.concat(message) : acc;
  }, [] as Array<UserMessage>);
}

/* @method getUserMessageFromError
 *
 * This function takes an error object and
 * attempts to retrieve a user message from
 * it.
 *
 * You can also pass in an options hash
 * and provide a default message if none is
 * found. If you don't pass a default message
 * then an empty string will be returned.
 */
export function getUserMessageFromError(
  error: BaseError,
  { defaultMsg = '' } = {}
): UserMessage {
  return USER_MESSAGE_KEYS.reduce(
    (acc, key: UserMessageKey) =>
      keyExists(error, key) ? (error[key] as string) : acc,
    defaultMsg
  );
}

/* @method getFirstError
 *
 * This function wraps getErrors and
 * returns the first result or an object
 * with an error if no errors are returned
 */
export function getFirstError(
  error: PotentialError
): NormalizedError | LibError {
  const errors = getErrors(error);
  return errors[0] ? errors[0] : INVALID_ERROR;
}

/* @method getErrors
 *
 * If the PotentialError contains any errors (ErrorMessage,
 * BaseErrorCollection or BaseError) it will normalize the
 * error(s) and return them as a list.
 *
 * If no errors are found an empty list will be returned.
 */
export function getErrors(error: PotentialError): Array<NormalizedError> {
  if (isErrorMessage(error)) return [{ detail: error }];
  if (isErrorMessageCollection(error))
    return error.map((err: ErrorMessage) => ({ detail: err }));
  if (isErrorCollection(error)) {
    return error.map(error => ({ detail: error.message || error.detail }));
  }
  if (isBaseErrorCollectionEx(error))
    return normalizeErrors(error.originalErrors);
  if (isBaseErrorCollection(error)) return normalizeErrors(error.errors);
  if (isBaseError(error)) return [normalizeError(error)];

  return [];
}

/* @method getValidations
 *
 * If the server returns a set of validation errors we can display
 * those errors inline with the form. To do so we return an array
 * of validations created from the validation errors.
 */
export function getValidations(error: PotentialError): ValidationError[] {
  if (isServerValidationError(error)) return [createValidationError(error)];
  if (isServerValidationErrorCollection(error)) return createValidations(error);
  return [];
}

/* @method createValidations
 *
 * This function will leverage the createValidationError
 * function in order to return a list of validations from
 * a collection of errors.
 */
function createValidations(
  error: ServerValidationErrorCollection
): ValidationError[] {
  return error.errors.map(error => createValidationError(error));
}

/* @method createValidationError
 *
 * This function takes an error and returns a new ValidationError
 * from the parameters that were provided in the error itself.
 */
function createValidationError(error: ServerValidationError): ValidationError {
  const path = getValidationPath(error);
  return new ValidationError(error.detail, null, path, 'required');
}

/* @method getValidationPath
 *
 * This function takes server validation and returns the name of the
 * property that the validation should apply to
 */
function getValidationPath(error: ServerValidationError): string {
  return camelCase(getLast<string>(error.source.pointer.split('/')));
}

/* @method normalizeErrors
 *
 * This function wraps the normalizeError
 * function to work on lists of errors.
 */
export function normalizeErrors(
  errors: Array<BaseError> = []
): Array<NormalizedError> {
  return errors.map(error => normalizeError(error));
}

/* @method normalizeError
 *
 * This function checks for the possible
 * keys an error message can exist under
 * and assign the same value to the detail
 * param if found.
 *
 * Note that it does not iterate over the
 * default key as it will be assigned over
 * anyway.
 */
export function normalizeError(
  error: BaseError,
  opts: NormalizedErrorOpts = { key: DEFAULT_KEY }
): NormalizedError {
  return USER_MESSAGE_KEYS.reduce(
    (acc, key) =>
      keyExists(acc, key) ? { [opts.key]: acc[key], ...acc } : acc,
    getErrorObject(error)
  ) as NormalizedError;
}

/* @method toErrors
 *
 * This function takes a list of base
 * errors and converts them to real error
 * classes using toError
 */
export function toErrors<T = Error>(errorDefs: BaseError[]): T[] {
  return errorDefs.map(e => toError<T>(e));
}

/* @method toError
 *
 * This function takes an error and converts
 * them to real error classes. The error
 * class is defined by the name attribute
 * present on the error.
 */
function toError<T = Error>(errorDef: BaseError): T {
  return newError(
    errorDef.name as ErrorName,
    errorDef.detail ?? errorDef.message
  ) as unknown as T;
}

/* @method
 *
 * This is a helper function that will create
 * a new error given a valid ErrorName, such
 * as TypeError, and a message for the error.
 */
function newError(errorType: ErrorName, message: string = '') {
  return new window[errorType ?? 'Error'](message);
}

/* @method keyExists
 *
 * This function checks if a key exists on
 * an object.
 *
 * Note we could use the hasOwnProperty
 * function but it is much slower than the
 * get accessor and does not protect
 * an existing key with a value of
 * undefined
 */
function keyExists(obj: BaseError = {}, key: UserMessageKey): boolean {
  return obj[key] !== undefined;
}

/* @method getErrorObject
 *
 * This helper function will duplicate the keys
 * on an error and return them as an object.
 * This is useful as the spread operator does
 * not work on Errors.
 */
function getErrorObject(error: BaseError | PotentialError): BaseError {
  return getErrorsKeys(error).reduce(
    (acc, key: keyof BaseError) =>
      error[key] ? { [key]: error[key], ...acc } : acc,
    {}
  );
}

/* @method getErrorsKeys
 *
 * This function returns all the keys of
 * and error, including the errors name which
 * is normally not returned.
 */
function getErrorsKeys(error: BaseError | PotentialError) {
  return ['name', ...Object.getOwnPropertyNames(error)];
}

function lowercase<T>(str: string | T): string | T {
  return typeOf(str) === 'string' ? (str as string).toLowerCase() : str;
}

function isError(error: PotentialError): error is Error {
  // @ts-ignore Typescript does not correctly infer embers typeOf
  return typeOf(error) === 'object' || typeOf(error) === 'error';
}

function isErrorCollection(errors: PotentialError[]): errors is Error[] {
  return typeOf(errors) === 'array' && errors.every(error => isError(error));
}

function isServerValidationErrorCollection(
  error: PotentialError
): error is ServerValidationErrorCollection {
  return isArray(error.errors) && isServerValidationError(error.errors[0]);
}

function isServerValidationError(
  error: PotentialError | undefined
): error is ServerValidationError {
  return error?.source && typeOf(error?.source?.pointer) === 'string';
}

function isInternalServerError(
  error: NormalizedError
): error is InternalServerError {
  const normalizedError = getFirstError(error);

  if (isLibError(normalizedError)) return false;

  const { code = '', detail = '' } = normalizedError;

  return code === '500' || lowercase(detail) === 'internal server error';
}

export function isNotFoundError(
  error: NormalizedError
): error is NotFoundError & { detail: string } {
  const normalizedError = getFirstError(error);

  if (isLibError(normalizedError)) return false;

  const { code = '', status = '' } = normalizedError;

  return code === '404' || status === '404';
}

function isLibError(error: PotentialError | LibError): error is LibError {
  return typeof (error as LibError).error === 'string';
}

function isErrorMessage(error: PotentialError): error is ErrorMessage {
  return typeOf(error) === 'string';
}

function isErrorMessageCollection(
  errors: PotentialError
): errors is ErrorMessageCollection {
  return errors?.every?.((err: PotentialError) => isErrorMessage(err));
}

function isBaseErrorCollection(
  error: PotentialError
): error is BaseErrorCollection {
  return isError(error) && isArray((error as BaseErrorCollection).errors);
}

function isBaseErrorCollectionEx(
  error: PotentialError
): error is BaseErrorCollection {
  return (
    isError(error) && isArray((error as BaseErrorCollection).originalErrors)
  );
}

function isBaseError(error: PotentialError): error is BaseError {
  return (
    isError(error) &&
    USER_MESSAGE_KEYS.some(k => (error as BaseError)[k] !== undefined) &&
    !isEmberDataGenericMessage(error)
  );
}

function isEmberDataGenericMessage(error: PotentialError): boolean {
  return (
    error &&
    'message' in error &&
    ED_GENERIC_ERROR_MESSAGES.includes(error.message)
  );
}

function isArray(prop: any): boolean {
  return typeOf(prop) === 'array';
}

function getLast<T>(arr: Array<T>): T {
  return arr[arr.length - 1];
}
