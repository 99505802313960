import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import type { ComponentLike } from '@glint/template';
import { hash } from '@ember/helper';
import Button from '../../../components/button.gts';
import Icon from '../../icon.gts';
import ModalHeaderSubtitle from './subtitle.gts';
interface ModalHeaderBaseSignature {
    Args: {
        textSize?: string;
        closeable?: boolean;
        closeComponent?: ComponentLike<{
            Args: {
                onClick: Function;
            };
            Element: any;
        }>;
        close?: Function;
    };
    Blocks: {
        default: [{
                ui: {
                    subtitle: typeof ModalHeaderSubtitle;
                };
            }];
    };
    Element: HTMLDivElement;
}
export default class ModalHeaderBaseComponent extends Component<ModalHeaderBaseSignature> {
    get textSize(): string {
        return this.args.textSize === 'lg' ? 'text-lg' : 'text-sm';
    }
    get isCloseable() {
        return this.args.closeable ?? true;
    }
    static{
        template(`
    <div class="flex p-4 {{this.textSize}} gap-3 justify-center text-white text-xl font-medium bg-navy-600 rounded-t-lg" data-test-modal-header ...attributes>
      <h2 id="modal-header-label" class="flex-grow overflow-y-auto">
        {{yield (hash
          ui=(hash
            subtitle=ModalHeaderSubtitle
          )
        )}}
      </h2>
      {{#if this.isCloseable}}
        {{#if @closeComponent}}
          <@closeComponent @onClick={{@close}} data-test-close-icon tabindex="-1">
            <Icon @name="x" @screenReaderText="Close" />
          </@closeComponent>
        {{else}}
          <Button @onClick={{@close}} data-test-close-icon tabindex="-1">
            <Icon @name="x" @screenReaderText="Close" />
          </Button>
        {{/if}}
      {{/if}}
    </div>

  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
