import type { Moment } from 'moment';
import moment from 'moment';

import Service from '@ember/service';

export default class ClockService extends Service {
  /**
   * Returns the current day as a string formatted as 'YYYY-MM-DD'
   */
  get todayDateString(): string {
    return this.nowMoment().format('YYYY-MM-DD');
  }

  get now(): Date {
    return this.nowMoment().toDate();
  }

  nowMoment(param?: string): Moment {
    return moment(param);
  }

  today(param?: string): Moment {
    return moment(param).startOf('day');
  }
}
