import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import Button from '../button.gts';
import type { ButtonDefaultBlock } from '../button.gts';
export interface ButtonNeutralSignature<T = {
}> {
    Args: {
        onClick: Function;
        onSuccess?: Function;
        onError?: Function;
        baseColor?: 'gray' | 'lightgray' | 'navy' | 'red' | 'blue' | 'green';
        text?: string;
        medium?: boolean;
        small?: boolean;
        xSmall?: boolean;
        border?: boolean;
        background?: boolean;
        disabled?: boolean;
        active?: boolean;
        blurOnClick?: boolean;
        testSelector?: string;
        classes?: string;
        type?: string;
    } & T;
    Blocks: {
        default: [ButtonDefaultBlock];
    };
    Element: HTMLElement;
}
export const COLORS: {
    [base: string]: {
        normalTextColor: string;
        normalBorderColor: string;
        hoverTextColor: string;
        hoverBgColor: string;
        hoverBorderColor: string;
        focusBgColor: string;
        focusBorderColor: string;
        activeColor: string;
        activeTextColor: string;
        activeBgColor: string;
        activeBorderColor: string;
        disabledTextColor: string;
    };
} = {
    lightgray: {
        normalTextColor: 'text-gray-400',
        normalBorderColor: 'border-gray-600',
        hoverTextColor: 'enabled-hover:text-gray-400',
        hoverBgColor: '',
        hoverBorderColor: 'enabled-hover:border-gray-300',
        focusBgColor: '',
        focusBorderColor: 'enabled-focus:border-gray-300',
        activeColor: '',
        activeTextColor: 'enabled-active:text-gray-600',
        activeBgColor: '',
        activeBorderColor: 'enabled-active:border-gray-600',
        disabledTextColor: 'disabled:text-gray-100'
    },
    gray: {
        normalTextColor: 'text-gray-500',
        normalBorderColor: 'border-gray-300',
        hoverTextColor: 'enabled-hover:text-gray-500',
        hoverBgColor: 'enabled-hover:bg-gray-100',
        hoverBorderColor: 'enabled-hover:border-gray-100',
        focusBgColor: 'enabled-focus:bg-gray-100',
        focusBorderColor: 'enabled-focus:border-gray-100',
        activeColor: 'bg-gray-200',
        activeTextColor: 'enabled-active:text-gray-600',
        activeBgColor: 'enabled-active:bg-gray-200',
        activeBorderColor: 'enabled-active:border-gray-200',
        disabledTextColor: 'disabled:text-gray-300'
    },
    navy: {
        normalTextColor: 'text-navy-500',
        normalBorderColor: 'border-navy-300',
        hoverTextColor: 'enabled-hover:text-navy-500',
        hoverBgColor: 'enabled-hover:bg-navy-100',
        hoverBorderColor: 'enabled-hover:border-navy-100',
        focusBgColor: 'enabled-focus:bg-gray-100',
        focusBorderColor: 'enabled-focus:border-gray-100',
        activeColor: 'bg-navy-200',
        activeTextColor: 'enabled-active:enabled-active:text-navy-600',
        activeBgColor: 'enabled-active:bg-navy-200',
        activeBorderColor: 'enabled-active:border-navy-200',
        disabledTextColor: 'disabled:text-navy-300'
    },
    red: {
        normalTextColor: 'text-red-500',
        normalBorderColor: 'border-red-300',
        hoverTextColor: 'enabled-hover:text-red-500',
        hoverBgColor: 'enabled-hover:bg-red-100',
        hoverBorderColor: 'enabled-hover:border-red-100',
        focusBgColor: 'enabled-focus:bg-red-100',
        focusBorderColor: 'enabled-focus:border-red-100',
        activeColor: 'bg-red-200',
        activeTextColor: 'enabled-active:text-red-600',
        activeBgColor: 'enabled-active:bg-red-200',
        activeBorderColor: 'enabled-active:border-red-200',
        disabledTextColor: 'disabled:text-red-300'
    },
    blue: {
        normalTextColor: 'text-blue-600',
        normalBorderColor: 'border-blue-300',
        hoverTextColor: 'enabled-hover:text-blue-700',
        hoverBgColor: 'enabled-hover:bg-blue-200',
        hoverBorderColor: 'enabled-hover:border-blue-100',
        focusBgColor: 'enabled-focus:bg-blue-200',
        focusBorderColor: 'enabled-focus:border-blue-100',
        activeColor: 'bg-blue-200',
        activeTextColor: 'enabled-active:text-blue-800',
        activeBgColor: 'enabled-active:bg-blue-300',
        activeBorderColor: 'enabled-active:border-blue-200',
        disabledTextColor: 'disabled:text-blue-300'
    },
    green: {
        normalTextColor: 'text-green-500',
        normalBorderColor: 'border-green-300',
        hoverTextColor: 'enabled-hover:text-green-500',
        hoverBgColor: 'enabled-hover:bg-green-100',
        hoverBorderColor: 'enabled-hover:border-green-100',
        focusBgColor: 'enabled-focus:bg-green-100',
        focusBorderColor: 'enabled-focus:border-green-100',
        activeColor: 'bg-green-200',
        activeTextColor: 'enabled-active:text-green-600',
        activeBgColor: 'enabled-active:bg-green-200',
        activeBorderColor: 'enabled-active:border-green-200',
        disabledTextColor: 'disabled:text-green-300'
    }
};
export default class ButtonNeutralComponent<T = {
}> extends Component<ButtonNeutralSignature<T>> {
    protected baseColor = 'gray';
    protected bgColor = 'bg-white';
    protected borderWidth = 'border';
    get border() {
        return this.args.border ?? true;
    }
    get background() {
        return this.args.background ?? true;
    }
    get padding() {
        return this.args.xSmall ? 'px-2' : 'px-4';
    }
    get fontSize() {
        return this.args.xSmall ? 'text-xs' : 'text-sm';
    }
    get lineHeight() {
        return this.args.xSmall ? 'leading-4' : 'leading-10';
    }
    get colors() {
        return COLORS[this.args.baseColor ?? this.baseColor];
    }
    get normalTextColor() {
        return this.colors.normalTextColor;
    }
    get hoverTextColor() {
        return this.colors.hoverTextColor;
    }
    get normalBorderColor() {
        return this.colors.normalBorderColor;
    }
    get normalBgColor() {
        return this.args.active === true ? this.colors.activeColor : this.bgColor;
    }
    get hoverBgColor() {
        return this.colors.hoverBgColor;
    }
    get hoverBorderColor() {
        return this.colors.hoverBorderColor;
    }
    get focusBgColor() {
        return this.colors.focusBgColor;
    }
    get focusBorderColor() {
        return this.colors.focusBorderColor;
    }
    get activeTextColor() {
        return this.colors.activeTextColor;
    }
    get activeBgColor() {
        return this.colors.activeBgColor;
    }
    get activeBorderColor() {
        return this.colors.activeBorderColor;
    }
    get disabledTextColor() {
        return this.colors.disabledTextColor;
    }
    get height() {
        return this.args.small || this.args.xSmall ? 'h-6' : this.args.medium ? 'h-7' : 'h-9';
    }
    get rounded() {
        return this.args.small || this.args.xSmall ? 'rounded' : 'rounded-md';
    }
    static{
        template(`
    <Button
      @disabled={{@disabled}}
      @onClick={{@onClick}}
      @blurOnClick={{@blurOnClick}}
      @onSuccess={{@onSuccess}}
      @onError={{@onError}}
      @testSelector={{@testSelector}}
      @type={{@type}}
      class='focus:outline-none active:outline-none
        {{if this.background this.normalBgColor}}
        {{this.padding}}
        {{this.rounded}}
        {{this.height}}
        {{if this.border this.borderWidth}}
        {{this.fontSize}}
        {{this.lineHeight}}
        {{this.normalTextColor}}
        {{this.normalBorderColor}}
        {{this.hoverTextColor}}
        {{this.hoverBgColor}}
        {{this.hoverBorderColor}}
        {{this.focusBgColor}}
        {{this.focusBorderColor}}
        {{this.activeTextColor}}
        {{this.activeBgColor}}
        {{this.activeBorderColor}}
        {{this.disabledTextColor}}
        disabled:border-white disabled:cursor-not-allowed
        {{@classes}}'
      ...attributes
      as |c|
    >
      {{#if (has-block)}}
        {{yield c}}
      {{else}}
        <c.ui.content>
          {{@text}}
        </c.ui.content>
      {{/if}}
    </Button>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
