import Component from '@glimmer/component';

interface TableFootSignature {
  Args: {};
  Blocks: {
    default: [unknown];
  };
  Element: HTMLTableSectionElement;
}

export default class TableFootComponent extends Component<TableFootSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Table::Foot': typeof TableFootComponent;
    'table/foot': typeof TableFootComponent;
  }
}
