import { matchesState as xstateMatchesState } from 'xstate';
import type { StateValue } from 'xstate';

export function matchesState(
  state: StateValue,
  statechartPropertyName = 'statechart'
): any {
  return function () {
    return {
      get(this: any): boolean {
        const statechart = this[statechartPropertyName] as
          | { state: { value: StateValue } }
          | undefined;

        if (statechart) {
          return xstateMatchesState(state, statechart.state.value);
        } else {
          return false;
        }
      }
    };
  };
}
