export function isKeyValueObject(value: any): value is KeyValueObject {
  return typeof value === 'object' && value !== null;
}

export function isRegExp(value: any): value is RegExp {
  return value instanceof RegExp;
}

export function curry(fn: Function, ...curriedArgs: any[]): Function {
  return (...args: any[]) => fn(...curriedArgs, ...args);
}

export function curryr(fn: Function, ...curriedArgs: any[]): Function {
  return (...args: any[]) => curry(fn, ...args)(...curriedArgs);
}

export default {
  isKeyValueObject,
  isRegExp,
  curry,
  curryr
};
