import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import type { WithBoundArgs } from '@glint/template';
import { hash } from '@ember/helper';
import or from 'ember-truth-helpers/helpers/or';
import noOp from '../../helpers/no-op.ts';
import TableReordeableCellComponent from './reordeable-cell.ts';
import TableRow from './row.ts';
import type { SortableTableHeaderBound } from './th-sortable.gts';
import TableHeader from './th.gts';
type CustomTableCell = SortableTableHeaderBound;
interface Signature<TCell extends CustomTableCell> {
    Args: {
        registerHeaderCell: (th: typeof TableHeader) => void;
        unregisterHeaderCell: (th: typeof TableHeader) => void;
        cellComponent?: TCell;
        isReordeable?: boolean;
        cellClass?: string;
    };
    Blocks: {
        default: [{
                ui: {
                    row: WithBoundArgs<typeof TableRow, 'class' | 'isReordeable'>;
                    cell: TCell | WithBoundArgs<typeof TableHeader, 'cellClass' | 'registerHeaderCell' | 'unregisterHeaderCell'>;
                    reordeableCell: typeof TableReordeableCellComponent;
                };
            }];
    };
    Element: HTMLTableSectionElement;
}
export default class TableHead<TCell extends CustomTableCell> extends Component<Signature<TCell>> {
    static{
        template(`
    <thead ...attributes>
      {{yield
        (hash
          ui=(hash
            row=(component
              TableRow
              class="text-sm font-semibold text-left text-gray-600 uppercase border-gray-300"
              isReordeable=@isReordeable
              toggleExpand=(noOp)
              collapse=(noOp)
            )
            cell=(or @cellComponent (component
              TableHeader
              cellClass=@cellClass
              registerHeaderCell=@registerHeaderCell
              unregisterHeaderCell=@unregisterHeaderCell
            ))
            reordeableCell=TableReordeableCellComponent
          )
        )
      }}
    </thead>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Table::Head': typeof TableHead;
        'table/head': typeof TableHead;
    }
}
