import Component from '@glimmer/component';
import type { ITetherConstraint } from 'tether';

import { guidFor } from '@ember/object/internals';

import type TableActionCellContent from './actions-cell/content.gts';

interface Signature {
  Args: {
    colspan?: string;
    testSelector?: string;
    isExpanded?: boolean;
    backgroundClickEnabled?: boolean;
    popupConstraints?: ITetherConstraint[];
    close?: Function;
  };
  Blocks: {
    default: [
      {
        ui: {
          content: typeof TableActionCellContent;
        };
        actions: {
          close: Function;
        };
      }
    ];
  };
  Element: HTMLElement;
}
export default class TableActionsCell extends Component<Signature> {
  get backgroundClickEnabled() {
    return this.args.backgroundClickEnabled ?? true;
  }

  get popupConstraints(): ITetherConstraint[] {
    return (
      this.args.popupConstraints ?? [
        {
          to: 'window',
          attachment: 'together',
          pin: true
        }
      ]
    );
  }

  get testSelector() {
    return this.args.testSelector ?? guidFor(this);
  }

  get popupTargetSelector() {
    return `[data-test-actions-cell="${this.testSelector}"]`;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Table::ActionsCell': typeof TableActionsCell;
    'table/actions-cell': typeof TableActionsCell;
  }
}
