import { Renderer } from '../components/canvas.ts';
import type { Transform } from '../utils/canvas-drawing.ts';

export default class Background extends Renderer {
  render(
    transform: Transform,
    width: number,
    height: number,
    backgroundImage: HTMLImageElement,
    scaleFactor: number = 1
  ) {
    const { context } = this;
    if (context && backgroundImage) {
      context.save();
      context.clearRect(0, 0, width * scaleFactor, height * scaleFactor);
      context.translate(transform.x * scaleFactor, transform.y * scaleFactor);
      context.scale(transform.k * scaleFactor, transform.k * scaleFactor);
      context.imageSmoothingQuality = 'high';
      context.imageSmoothingEnabled = true;
      context.drawImage(backgroundImage, 0, 0);
      context.restore();
    }
  }
}
