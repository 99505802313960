import Component from '@glimmer/component';

import { action } from '@ember/object';

import type FormFormFieldComponent from '../../../components/form/base/form-field.ts';
import type FormObject from '../../../utils/form-object.ts';

interface FormSelectBaseSignature {
  Args: {
    property: keyof FormObject<Object>;
    updateValue: Function;
    formObject: FormObject<Object>;
    formField: FormFormFieldComponent;
    testSelector?: string;
    placeholder?: string;
    disabled?: boolean;
    small?: boolean;
    h?: string;
    collection: any[];
    handleSelect: Function;
    onClear?: Function;
    toggleFocus: Function;
    fieldHasFocus: boolean;
    displayErrors: boolean;
  };
  Blocks: {
    default: [unknown];
  };
  Element: HTMLElement;
}

export default class FormSelectBaseComponent extends Component<FormSelectBaseSignature> {
  get height() {
    if (this.args.h) {
      return this.args.h;
    }
    return this.args.small ? 'h-9' : null;
  }

  @action
  clear() {
    this.args.updateValue(null);
    return this.args.onClear?.();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Form::Select::Base': typeof FormSelectBaseComponent;
    'form/select/base': typeof FormSelectBaseComponent;
  }
}
