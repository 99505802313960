import { helper } from '@ember/component/helper';

export interface FirstSignature<T> {
  Args: {
    Positional: [T[]];
  };
  Return: T;
}

const first = helper<FirstSignature<any>>(([firstArg]) => firstArg[0]);

export default first;
