import { Renderer } from '../components/canvas.ts';
import type { Transform } from '../utils/canvas-drawing.ts';

export default class Background extends Renderer {
  render(
    transform: Transform,
    width: number,
    height: number,
    sourceWidth: number,
    sourceHeight: number
  ) {
    const { context } = this;
    if (context) {
      context.save();
      context.clearRect(0, 0, width, height);
      context.translate(transform.x, transform.y);
      context.scale(transform.k, transform.k);
      context.fillStyle = 'white';
      context.fillRect(0, 0, sourceWidth, sourceHeight);
      context.beginPath();
      context.setLineDash([10, 10]);
      context.rect(0, 0, sourceWidth, sourceHeight);
      context.stroke();
      context.restore();
    }
  }
}
