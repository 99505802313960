import templateOnlyComponent from '@ember/component/template-only';

interface DropdownWrapperSignature {
  Args: {};
  Blocks: {
    default: [];
  };
  Element: HTMLDivElement;
}

const DropdownWrapperComponent =
  templateOnlyComponent<DropdownWrapperSignature>();

export default DropdownWrapperComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Dropdown::Wrapper': typeof DropdownWrapperComponent;
    'dropdown/wrapper': typeof DropdownWrapperComponent;
  }
}
