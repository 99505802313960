import { helper } from '@ember/component/helper';

export type Locale = keyof typeof locales;

export const locales = {
  'en-GB': 'English',
  'es-ES': 'Spanish',
  'cy-CY': 'Welsh',
  'de-DE': 'Deutsch',
  'fr-FR': 'Français',
  'ga-IE': 'Irish'
};

export const localesShort = {
  'en-GB': 'EN',
  'es-ES': 'ES',
  'cy-CY': 'CY',
  'de-DE': 'DE',
  'fr-FR': 'FR',
  'ga-IE': 'IE'
};

export function humanizeLocale(locale: Locale, short: boolean) {
  const _locales = short ? localesShort : locales;
  return _locales[locale] || locale;
}

export default helper(function (
  [locale]: [Locale],
  options: { short?: boolean } = {}
) {
  const { short } = options;
  return humanizeLocale(locale, !!short);
});
