import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { schedule } from '@ember/runloop';

import type { DropdownOption } from '../dropdown.ts';
import type { FormTheme } from '../form/theme.ts';

interface DropdownContentSignature {
  Args: {
    collection?: DropdownOption[];
    testSelector: string;
    dropdownId: string | number;
    constraints?: object;
    classes?: string;
    selected?: DropdownOption;
    preselected?: DropdownOption;
    recordsCount?: number;
    scrollToSelected?: boolean;
    enableGrowth?: boolean;
    checkHeight?: boolean;
    withBorder?: boolean;
    withShadow?: boolean;
    tetherClasses?: string;
    isOpen?: boolean;
    isDropUp?: boolean;
    hasSearch?: boolean;
    shouldFocus?: boolean;
    sameWidth?: boolean;
    animation?: string;
    attachment?: string;
    targetAttachment?: string;
    sort?: Function;
    sortByParam?: string;
    onSelect?: Function;
    onPreselect?: Function;
    onBackgroundClicked?: Function;
    onSearch?: Function;
    onLoad?: Function;
    getCollection?: Function;
    optionsTransform?: Function;
    hide?: Function;
    show?: Function;
    theme?: FormTheme;
  };
  Blocks: {
    default: [unknown];
  };
  Element: HTMLElement;
}

export default class DropdownContentComponent extends Component<DropdownContentSignature> {
  @tracked hoveredDatum = null;

  defaultConstraints = [
    {
      to: 'window',
      attachment: 'together',
      pin: true
    }
  ];

  get enableGrowth() {
    return this.args.enableGrowth ?? false;
  }

  get checkHeight() {
    return this.args.checkHeight ?? true;
  }

  get withBorder() {
    return this.args.withBorder ?? true;
  }

  get withShadow() {
    return this.args.withShadow ?? true;
  }

  get scrollToSelected() {
    return this.args.scrollToSelected ?? true;
  }

  get shouldFocus() {
    return this.args.shouldFocus ?? true;
  }

  get componentId() {
    return guidFor(this);
  }

  get popupTargetSelector() {
    return `[data-tether-target-id="${this.args.dropdownId}"]`;
  }

  get popupContentSelector() {
    return `[data-tether-content-id="${this.args.dropdownId}"]`;
  }

  get _constraints() {
    if (typeof this.args.constraints !== 'undefined') {
      return this.args.constraints;
    }
    return this.defaultConstraints;
  }

  @action
  focus(el: HTMLElement) {
    if (this.args.isOpen && !this.args.hasSearch && this.shouldFocus) {
      schedule('afterRender', null, () => el.focus());
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Dropdown::Content': typeof DropdownContentComponent;
    'dropdown/content': typeof DropdownContentComponent;
  }
}
