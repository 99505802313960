import Component from '@glimmer/component';

import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';

import { scrollToElement } from '../../utils/dom-helpers.ts';

interface DropdownContentItemSignature {
  Args: {
    item?: any;
    selected?: any;
    preselected?: any;
    onInsert?: Function;
    onSelect?: Function;
    onPreselect?: Function;
    scrollToSelected?: boolean;
  };
  Blocks: {
    default: [unknown];
  };
  Element: HTMLElement;
}

export default class DropdownContentItemComponent extends Component<DropdownContentItemSignature> {
  get item() {
    // default to this so `dropdown.ui.contentItem` works without dropdown.collection
    return (
      this.args.item || {
        value: guidFor(this)
      }
    );
  }

  get isSelected() {
    return this.item?.value === this.args.selected?.value;
  }

  get isPreselected() {
    return this.item?.value === this.args.preselected?.value;
  }

  @action
  select(event: MouseEvent) {
    event.stopPropagation();
    return this.args.onSelect?.(this.item);
  }

  @action
  preselect() {
    return this.args.onPreselect?.(this.item);
  }

  @action
  scrollIfSelected(el: HTMLElement) {
    if (this.args.scrollToSelected && this.isSelected) {
      scrollToElement(el);
    }
  }

  @action
  scrollIfPreselected(el: HTMLElement) {
    if (this.args.scrollToSelected && this.isPreselected) {
      scrollToElement(el);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Dropdown::ContentItem': typeof DropdownContentItemComponent;
    'dropdown/content-item': typeof DropdownContentItemComponent;
  }
}
