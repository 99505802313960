import Component from '@glimmer/component';

import { action } from '@ember/object';
import { capitalize, underscore } from '@ember/string';

import type { FormObjectBase } from '../../utils/form-object.ts';
import type { FormSignature } from '../form.ts';

/**
 * Form Context === `<Form />` Default Block
 *
 * Example: *   `{ state: { formObject, ... }, actions: { ... } }`
 */
export type FormContext = FormSignature<FormObjectBase>['Blocks']['default'][0];

export type ExtractFormObject<T extends FormContext> = T['state']['formObject'];

/**
 * Form Props === Properties in Form Object
 *
 * Examples:
 *   - ExtractFormProperty<f> // get all form properties
 *   - ExtractFormProperty<f, 'boolean'> // get all boolean form properties
 */
export type ExtractFormProps<Context extends FormContext, Type = any> = Extract<
  keyof {
    [K in keyof ExtractFormObject<Context> as ExtractFormObject<Context>[K] extends Type
      ? K
      : never]: ExtractFormObject<Context>[K];
  },
  string
>;

export type ExtractFormValue<
  T extends FormContext,
  P extends keyof ExtractFormObject<T>
> = ExtractFormObject<T>[P];

interface BaseFormControlSignature {
  Args: {
    f: {
      state: { formObject: any };
      actions: { change: FormContext['actions']['change'] };
    };
    property: string;
    label?: string;
    value?: any;
    testSelector?: string;
    onValueChanged?: (value: any) => void;

    /**
     * @deprecated
     */
    onChange?: FormContext['actions']['change'];
  };
}

export default class FormBaseComponent<
  Signature extends BaseFormControlSignature,
  Value = any
> extends Component<Signature> {
  get formObject() {
    return this.args.f.state.formObject;
  }

  get value(): Value {
    const formObject = this.formObject;
    const { property, value } = this.args;

    return value ?? formObject[property];
  }

  get testSelector() {
    return this.args.testSelector ?? this.args.property;
  }

  get label() {
    return (
      this.args.label ??
      capitalize(underscore(this.args.property).replace(/_/g, ' '))
    );
  }

  @action
  onChange(value: Value) {
    const { property, onChange, f } = this.args;

    const maybeResponse = (onChange ?? f.actions.change)(property, value);

    this.args.onValueChanged?.(value);

    return maybeResponse;
  }
}
