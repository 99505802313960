import { template } from "@ember/template-compiler";
import didInsert from '@ember/render-modifiers/modifiers/did-insert';
interface ModalConfirmationObserverSignature {
    Args: {
        observe: boolean;
        needsConfirmation: () => void;
        noNeedConfirmation: () => void;
    };
}
const ModalConfirmationObserverComponent: TemplateOnlyComponent<ModalConfirmationObserverSignature> = template(`
  {{#if @observe}}
    <div class="hidden" {{didInsert @needsConfirmation}}></div>
  {{else}}
    <div class="hidden" {{didInsert @noNeedConfirmation}}></div>
  {{/if}}

`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ModalConfirmationObserverComponent;
