import { helper } from '@ember/component/helper';

export interface DisableBubblingSignature {
  Args: {
    Positional: [action: Function];
  };
  Return: ReturnType<typeof disableBubblingFunc>;
}

export function disableBubbling(event: Event) {
  event.stopPropagation();
}

export function disableBubblingFunc([action]: [Function]) {
  return function (event: Event) {
    disableBubbling(event);
    return typeof action === 'function' ? action(event) : event;
  };
}
export default helper(disableBubblingFunc);
