// @ts-ignore
import { template } from "@ember/template-compiler";
import { ensureSafeComponent } from '@embroider/util';
import Component from '@glimmer/component';
import type { ComponentLike } from '@glint/template';
import { hash } from '@ember/helper';
import type { DeprecatedUsage } from 'ember-elsewhere/components/from-elsewhere';
import ToElsewhere from 'ember-elsewhere/components/to-elsewhere';
interface ModalSignature {
    Args: {
        send?: ComponentLike;
        outsideParams?: Record<string, any>;
        /**
     * @deprecated
     */ isOpen?: boolean;
        /**
     * @deprecated
     */ close?: Function;
        /**
     * @deprecated
     */ modal?: DeprecatedUsage['modal'];
    };
}
export default class Modal extends Component<ModalSignature> {
    isDeprecatedUsage(ComponentOrOptions1: ComponentLike | DeprecatedUsage): ComponentOrOptions is DeprecatedUsage {
        return (ComponentOrOptions1 && typeof ComponentOrOptions1 === 'object' && 'modal' in ComponentOrOptions1);
    }
    static{
        template(`
    {{#if @send}}
      <ToElsewhere
        @named="modal-container"
        @send={{ensureSafeComponent @send this}}
        @outsideParams={{@outsideParams}}
      />
    {{else if @isOpen}}
      {{#let
        (hash
          close=@close
          modal=@modal
        ) as |modalOptions|
      }}
        <ToElsewhere @named="modal-container" @send={{modalOptions}}/>
      {{/let}}
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
