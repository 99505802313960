export type FormTheme = 'default' | 'inline' | 'plain';

const defaultTheme = {
  default: 'tw-form-control',
  active: 'tw-form-control_active',
  invalid: 'tw-form-control_invalid_active',
  width: 'w-full',
  height: 'h-12',
  minHeight: 'min-h-12',
  label: {
    wrapper: 'flex-col mx-2',
    labelWidth: '',
    label: 'mb-2'
  },
  datepicker: {
    leftIcon: true,
    rightIcon: true
  },
  dropdown: {
    padding: 'px-3',
    rightIcon: true,
    clearIconSize: 'w-3.5',
    empty: ''
  },
  multiselect: {
    selected: {
      default: 'mt-2',
      item: '',
      inputActive: ''
    }
  }
};

export function getTheme(theme?: FormTheme) {
  if (theme === 'inline') {
    return {
      default: 'tw-inline-form',
      active: 'tw-inline-form_active',
      invalid: 'tw-inline-form_invalid_active',
      width: 'w-auto',
      height: 'h-9',
      minHeight: 'min-h-9',
      label: {
        wrapper: 'items-center gap-2',
        labelWidth: 'w-1/4',
        label: 'font-medium text-sm'
      },
      datepicker: {
        leftIcon: false,
        rightIcon: false
      },
      dropdown: {
        padding: 'px-2',
        rightIcon: false,
        clearIconSize: 'w-2',
        empty: 'flex-1'
      },
      multiselect: {
        selected: {
          default: 'ml-2 absolute top-2 pointer-events-none',
          item: 'pointer-events-auto',
          inputActive: 'hidden'
        }
      }
    };
  }

  if (theme === 'plain') {
    return {
      ...defaultTheme,
      default: 'tw-plain-form'
    };
  }

  return defaultTheme;
}
