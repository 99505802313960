import { modifier } from 'ember-modifier';

// If the element is overflown perform some action
function isOverflownModifier(
  root: HTMLElement,
  [func]: [IntersectionObserverCallback]
) {
  const observer = new IntersectionObserver(func, {
    root,
    threshold: 1
  });
  Array.from(root.children).forEach(child => observer.observe(child));
}

export default modifier(isOverflownModifier);
