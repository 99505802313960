import templateOnlyComponent from '@ember/component/template-only';

interface DropdownNoResultsSignature {
  Args: {};
  Blocks: {
    default: [];
  };
  Element: HTMLDivElement;
}

const DropdownNoResultsComponent =
  templateOnlyComponent<DropdownNoResultsSignature>();

export default DropdownNoResultsComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Dropdown::NoResults': typeof DropdownNoResultsComponent;
    'dropdown/no-results': typeof DropdownNoResultsComponent;
  }
}
