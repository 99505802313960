import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type { WithBoundArgs } from '@glint/template';
import { fn, hash } from '@ember/helper';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import onKey from 'ember-keyboard/helpers/on-key';
import or from 'ember-truth-helpers/helpers/or';
import disableBubbling from '../../../helpers/disable-bubbling.ts';
import TopSecondaryButton from '../../button/top/secondary.gts';
import FormCta from '../../form/cta.ts';
import ModalsConfirmDiscard from '../../modals/confirm-discard.gts';
import ModalBody from '../body.gts';
import ModalCloseButton from '../close-button.gts';
import ModalConfirmationObserver from '../confirmation-observer.gts';
import ModalFooter from '../footer.gts';
import ModalHeader from '../header.gts';
export interface ModalContentBaseSignature<TModalHeader extends typeof ModalHeader = typeof ModalHeader> {
    Args: {
        isSmall?: boolean;
        isFullscreen?: boolean;
        close?: Function;
        testSelector?: string;
        headerComponent?: TModalHeader;
    };
    Blocks: {
        default: [{
                ui: {
                    header: WithBoundArgs<typeof ModalHeader, 'close'>;
                    body: typeof ModalBody;
                    footer: typeof ModalFooter;
                    confirmationObserver: WithBoundArgs<typeof ModalConfirmationObserver, 'needsConfirmation' | 'noNeedConfirmation'>;
                    closeButton: WithBoundArgs<typeof ModalCloseButton, 'close'>;
                    button: WithBoundArgs<typeof TopSecondaryButton, 'small'>;
                    cta: WithBoundArgs<typeof FormCta, 'small'>;
                };
                state: {
                    needsConfirmation: boolean;
                    displayConfirmation: boolean;
                };
                actions: {
                    close: () => void;
                    needsConfirmation: () => void;
                    noNeedConfirmation: () => void;
                };
            }];
    };
    Element: HTMLDivElement;
}
export default class ModalContentBaseComponent extends Component<ModalContentBaseSignature> {
    @tracked
    displayConfirmation = false;
    @tracked
    needsConfirmation = false;
    @action
    close() {
        if (this.needsConfirmation) {
            this.displayConfirmation = true;
        } else {
            this.args.close?.();
        }
    }
    @action
    setNeedsConfirmation(needsConfirmation1: boolean) {
        if (this.needsConfirmation !== needsConfirmation1) {
            this.needsConfirmation = needsConfirmation1;
        }
    }
    static{
        template(`
    {{onKey 'Escape' (disableBubbling this.close)}}
    <div class='fixed inset-0 flex items-start justify-center z-popup'>
      <div
        data-test-dialog='overlay'
        class='absolute inset-0 bg-gray-900/[.35] z-popup'
        role='button'
        {{on 'click' this.close}}
      >
        <div class='sr-only'>Close Modal</div>
      </div>
      <div
        role='dialog'
        aria-modal='true'
        aria-labelledby='modal-header-label'
        class='flex flex-col bg-white rounded-lg shadow-modal z-modal
          {{if @isSmall "w-screen h-screen !rounded-none"}}
          {{if
            @isFullscreen
            "!h-screen !w-screen !max-h-screen"
            "mt-8 max-h-modal"
          }}'
        ...attributes
      >
        {{yield
          (hash
            ui=(hash
              header=(component
                (or @headerComponent ModalHeader) close=this.close
              )
              body=ModalBody
              footer=ModalFooter
              confirmationObserver=(component
                ModalConfirmationObserver
                needsConfirmation=(fn this.setNeedsConfirmation true)
                noNeedConfirmation=(fn this.setNeedsConfirmation false)
              )
              closeButton=(component
                ModalCloseButton close=this.close small=true
              )
              button=(component TopSecondaryButton small=true)
              cta=(component FormCta small=true)
            )
            state=(hash
              needsConfirmation=this.needsConfirmation
              displayConfirmation=this.displayConfirmation
            )
            actions=(hash
              close=this.close
              needsConfirmation=(fn this.setNeedsConfirmation true)
              noNeedConfirmation=(fn this.setNeedsConfirmation false)
            )
          )
        }}
      </div>

    </div>

    <ModalsConfirmDiscard
      @message={{'You are about to leave this page without saving your changes. Do you want to proceed?'}}
      @isOpen={{this.displayConfirmation}}
      @onConfirm={{@close}}
      @onCancel={{fn (mut this.displayConfirmation) false}}
      @testSelector={{or @testSelector 'confirm-close'}}
    />
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
