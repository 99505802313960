import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type { WithBoundArgs } from '@glint/template';

import type TableComponent from '../table.ts';
import type TableReordeableCellComponent from './reordeable-cell.ts';
import type TableRow from './row.ts';
import TableCell from './td.ts';

interface TableBodySignature<T> {
  Args: {
    isReordeable?: boolean;
    isReordering?: boolean;
    reorderData?: T[];
    data: T[];

    rowClass?: string;
    cells?: TableCell[];
    expandedRows?: TableRow[];
    toggleExpand?: TableComponent['toggleExpand'];
    collapse?: TableComponent['collapse'];
    reorderItems?: TableComponent['reorderItems'];
    reorderStart?: TableComponent['reorderStart'];
    reorderEnd?: TableComponent['reorderEnd'];
  };
  Blocks: {
    default: [
      {
        ui: {
          row: WithBoundArgs<
            typeof TableRow,
            | 'class'
            | 'cells'
            | 'expandedRows'
            | 'toggleExpand'
            | 'collapse'
            | 'index'
            | 'reorderStart'
            | 'reorderEnd'
            | 'reorderItems'
            | 'isReordeable'
          >;
          cell: typeof TableCell;
          reordeableCell: WithBoundArgs<
            typeof TableReordeableCellComponent,
            'withIcon'
          >;
          noData?: WithBoundArgs<typeof TableRow, 'collapse' | 'toggleExpand'>;
        };
        actions: {
          hover: Function;
          unhover: Function;
        };
        state: {
          hoveredDatum?: T;
        };
      },
      T
    ];
  };
  Element: HTMLTableSectionElement;
}

export type TableBodyYield<T> = TableBodySignature<T>['Blocks']['default'][0];

export default class TableBodyComponent<T> extends Component<
  TableBodySignature<T>
> {
  @tracked hoveredDatum?: T;

  tdComponent = TableCell;

  get data(): T[] {
    const { isReordering, isReordeable, reorderData, data } = this.args;
    if (isReordeable && isReordering) {
      return reorderData;
    }
    return data;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Table::Body': typeof TableBodyComponent;
    'table/body': typeof TableBodyComponent;
  }
}
