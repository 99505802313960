import { template } from "@ember/template-compiler";
import or from 'ember-truth-helpers/helpers/or';
import ButtonTopSecondary from '../button/top/secondary.gts';
interface ModalCloseButtonSignature {
    Args: {
        close: Function;
        testSelector?: string;
        small?: boolean;
        label?: string;
    };
    Element: HTMLDivElement;
}
const ModalCloseButtonComponent: TemplateOnlyComponent<ModalCloseButtonSignature> = template(`
    <ButtonTopSecondary
      @onClick={{@close}}
      @testSelector={{@testSelector}}
      @small={{@small}}
      ...attributes
    >
      {{or @label 'Cancel'}}
    </ButtonTopSecondary>
  `, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ModalCloseButtonComponent;
