import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { on } from '@ember/modifier';
import { action } from '@ember/object';
import FormInputBase from '../form/input/base.ts';
import type { FormTheme } from '../form/theme.ts';
interface TypeaheadInputSignature {
    Args: {
        id?: string;
        value: string;
        disabled: boolean;
        querySelector: string;
        icon?: string;
        iconClasses?: string;
        iconSize?: string;
        h?: string;
        wrapperClasses?: string;
        isInvalid?: boolean;
        theme?: FormTheme;
        onInput: (searchTerm: string | null) => void;
        onFocus: (event: KeyboardEvent) => void;
        onKeyDown: (event: KeyboardEvent) => void;
        onBlur?: (event: KeyboardEvent) => void;
    };
    Element: HTMLInputElement;
}
export default class TypeaheadInputComponent extends Component<TypeaheadInputSignature> {
    @action
    onInput(event1: KeyboardEvent & {
        target: HTMLInputElement;
    }): void {
        return this.args.onInput(event1.target.value);
    }
    @action
    onBlur(event1: KeyboardEvent): void {
        if (this.args.onBlur) {
            this.args.onBlur(event1);
        }
    }
    static{
        template(`
    <FormInputBase
      @id={{@id}}
      @value={{@value}}
      @isDisabled={{@disabled}}
      @icon={{@icon}}
      @iconClasses={{@iconClasses}}
      @iconSize={{@iconSize}}
      @isInvalid={{@isInvalid}}
      @h={{@h}}
      @wrapperClasses={{@wrapperClasses}}
      @theme={{@theme}}
      {{on "input" this.onInput}}
      {{on "focus" @onFocus}}
      {{on "keydown" @onKeyDown}}
      {{on "blur" this.onBlur}}
      data-input={{@querySelector}}
      ...attributes
    />
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
