import Service from '@ember/service';
import { inject as service } from '@ember/service';
import { camelize, underscore } from '@ember/string';
import type DS from 'ember-data';

interface Setting {
  value: any;
}

export default class BaseSettingsService<
  TSettingModel extends Setting
> extends Service {
  @service() store!: DS.Store;

  public getSetting<TValue = unknown>(name: string): TValue {
    const setting = this.store
      .peekAll('setting')
      .slice()
      .find(setting => setting.name === camelize(name));

    return setting ? setting.value : false;
  }

  public async fetch<TValue = unknown>(name: string): Promise<TValue | false> {
    const storeSetting: TSettingModel | null = this.store
      .peekAll('setting')
      .slice()
      .find(setting => setting.name === camelize(name));

    if (storeSetting) {
      return storeSetting.value;
    }

    const settings = await this.filter(name);

    if (settings.length > 0) {
      return settings[0].value;
    }

    return false;
  }

  public async filter(name: string): Promise<TSettingModel[]> {
    return (
      await this.store.query('setting', {
        filter: { name: underscore(name) }
      })
    ).slice();
  }

  /**
   * Example: ['en-GB', 'de-DE', ...]
   */
  get locales(): string[] {
    return this.getSetting<string>('locales')
      .split(',')
      .map(l => l.trim());
  }
}
