import Component from '@glimmer/component';

interface FormPasswordBaseSignature {
  Args: {
    hasFocus: boolean;
    isInvalid: boolean;
    id: string;
    value: string;
    passwordIsVisible: boolean;
    onTogglePassword: Function;
    placeholder: string;
  };
  Element: HTMLElement;
}
export default class FormPasswordBaseComponent extends Component<FormPasswordBaseSignature> {
  get screenReaderText() {
    return this.args.passwordIsVisible ? 'Hide password' : 'Display password';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Form::Password::Base': typeof FormPasswordBaseComponent;
    'form/password/base': typeof FormPasswordBaseComponent;
  }
}
