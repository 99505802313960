import Service from '@ember/service';

interface SuccessPayload {
  method: 'share' | 'clipboard';
}

interface ErrorPayload {
  method: 'share' | 'clipboard';
  error?: any;
}

type SuccessHandler = (result: SuccessPayload) => void | PromiseLike<void>;
type ErrorHandler = (error: ErrorPayload) => void | PromiseLike<void>;

interface CopyClipboardOpts {
  text: string;
  onSuccess?: SuccessHandler;
  onError?: ErrorHandler;
}

type ShareOpts = {
  url: string;
  title?: string;
  text?: string;
  onSuccess?: SuccessHandler;
  onError?: ErrorHandler;
};

import { noOp as noop } from '../helpers/no-op.ts';

export default class ShareService extends Service {
  /**
   * Method to use the native share on devices. Will fallback to clipboard if
   * that isn't available.
   */
  async share(shareOpts: ShareOpts) {
    const {
      title,
      text,
      url,
      onSuccess: _onSuccess,
      onError: _onError
    } = shareOpts;

    const onSuccess: SuccessHandler = _onSuccess || noop();
    const onError: ErrorHandler = _onError || noop();

    if (this.canShare()) {
      try {
        await navigator.share({
          title,
          text,
          url
        });

        onSuccess({ method: 'share' });
      } catch (e) {
        onError({ method: 'share', error: e });
      }
    } else {
      this.copyToClipboard({ text, onSuccess, onError });
    }
  }

  async copyToClipboard({ text, onSuccess, onError }: CopyClipboardOpts) {
    try {
      const permissionStatus = await navigator.permissions.query({
        name: 'clipboard-write' as PermissionName
      });

      const handleClipboardWrite = async () => {
        if (!this.hasWindowFocus()) {
          window.focus();
        }
        await navigator.clipboard.writeText(text);
        onSuccess({ method: 'clipboard' });
      };

      if (permissionStatus.state === 'granted') {
        await handleClipboardWrite();
      } else if (permissionStatus.state === 'prompt') {
        try {
          await navigator.clipboard.writeText('');
          await handleClipboardWrite();
        } catch (e) {
          onError({ method: 'clipboard', error: e });
        }
      } else if (permissionStatus.state === 'denied') {
        onError({ method: 'clipboard' });
      }
    } catch (e) {
      onError({ method: 'clipboard', error: e });
    }
  }

  canShare() {
    return navigator.share !== undefined;
  }

  hasWindowFocus() {
    return document.hasFocus();
  }
}
