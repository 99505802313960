import { template } from "@ember/template-compiler";
import { fn } from '@ember/helper';
import didInsert from '@ember/render-modifiers/modifiers/did-insert';
import willDestroy from '@ember/render-modifiers/modifiers/will-destroy';
interface Signature {
    Args: {
        registerHeaderCell: (th: typeof TableHeader) => void;
        unregisterHeaderCell: (th: typeof TableHeader) => void;
        cellClass?: string;
    };
    Blocks: {
        default: [];
    };
    Element: HTMLTableCellElement;
}
const TableHeader: TemplateOnlyComponent<Signature> = template(`
  <th
    {{didInsert (fn @registerHeaderCell this)}}
    {{willDestroy (fn @unregisterHeaderCell this)}}
    class={{@cellClass}}
    ...attributes
  >
    {{yield}}
  </th>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default TableHeader;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Table::Th': typeof TableHeader;
        'table/th': typeof TableHeader;
    }
}
