import { tracked } from '@glimmer/tracking';
import type { Resource } from 'ticketoffice-api';

import { next } from '@ember/runloop';
import Service, { inject as service } from '@ember/service';

import Jwt from '../lib/jwt.ts';
import type ErrorsService from '../services/-errors-base.ts';
import type WindowService from '../services/-window-base.ts';
import type FullStoryService from '../services/full-story.ts';
import type StorageService from '../services/storage.ts';

function getQueryValue(href: string, key: string): string | undefined {
  const query = href.split('?')[1] || '';
  const queryKvPairs = query
    .split('&')
    .map(keyValue => keyValue.split('=')) as [string, string][];
  return new Map(queryKvPairs).get(key);
}

export type Credentials = {
  login: string;
  password: string;
  role: string;
  location?: string | null;
  otp?: string | null;
};
export type PasswordlessCredentials = {
  'magic-token': string;
};
export type SessionPayload = {
  data: {
    attributes: {
      token: string | null;
      password?: string;
    };
    relationships: {
      user: {
        data: {
          id: string;
        };
      };
    };
  };
  included: Resource[];
};

/**
 * Basic identification attributes are used for interop services, like fullstory or error logging
 */
export interface BaseUserModel {
  id: string;
  login: string;
}

export default abstract class BaseSessionService<
  UserModel extends BaseUserModel
> extends Service {
  @service private errors!: ErrorsService;
  @service() private fullStory!: FullStoryService;
  @service() private storage!: StorageService;
  @service() protected window!: WindowService;

  @tracked public currentUser: UserModel | null = null;
  @tracked public token: string | null = null;
  @tracked public role: string | null = null;

  public get isAuthenticated(): boolean {
    return !!this.currentUser;
  }

  protected abstract SESSION_KEY: string;
  protected abstract AFTER_LOGOUT_PATH: string;
  protected abstract authenticateApi(
    credentials: Credentials | PasswordlessCredentials
  ): Promise<SessionPayload>;
  protected abstract reloadUser(user: UserModel): Promise<void>;
  protected abstract storeIncluded(included: Resource[]): Promise<any>;
  protected abstract peekUser(id: string): UserModel;
  protected abstract authenticateSuccess(jwt: Jwt): void;

  public async authenticate(
    credentials: Credentials | PasswordlessCredentials,
    force?: boolean
  ): Promise<UserModel> {
    if (this.currentUser && force !== true) {
      return this.currentUser;
    }

    const payload = await this.authenticateApi(credentials);

    return this.didAuthenticate(payload);
  }

  public invalidate(opts: { params?: any } = {}): void {
    this.currentUser = null;
    this.token = null;
    this.errors.setUser(null);
    this.clearSession();
    this.loadAfterLogoutPath(opts);
  }

  public async retrieveSession(): Promise<void> {
    if (this.isAuthenticated || !this.storage.localStorageEnabled) {
      return;
    }
    const sessionData = this.storage.fetch(this.SESSION_KEY);
    if (!sessionData) {
      return;
    }
    const user = await this.didAuthenticate(JSON.parse(sessionData));
    await this.reloadUser(user);
    this.currentUser = user;
  }

  protected async didAuthenticate(data: SessionPayload): Promise<UserModel> {
    this.persistSession({ ...data });

    const token = data.data.attributes.token as string;
    const user = data.data.relationships.user.data.id;

    await this.storeIncluded(data.included);

    const currentUser = this.peekUser(user);

    const jwt = new Jwt(token);

    this.token = token;
    this.currentUser = currentUser;
    this.role = jwt.role;

    if (this.currentUser) {
      const { id, login: username } = this.currentUser;

      this.fullStory.identify(id.toString(), { displayName: username });
      this.errors.setUser({ id, username });
    }

    this.authenticateSuccess(jwt);

    return currentUser;
  }

  private loadAfterLogoutPath(opts: { params?: any }): void {
    next(this, function () {
      this.window.location.href = this.getLogoutPath(opts);
    });
  }

  protected getLogoutPath({ params }: any): string {
    const revision = getQueryValue(this.window.location.href, 'revision');
    const queryParams = [params, revision ? `revision=${revision}` : null]
      .filter(Boolean)
      .join('&');
    return `${this.AFTER_LOGOUT_PATH}?${queryParams}`;
  }

  private persistSession(userData: SessionPayload): void {
    delete userData.data.attributes.password;
    this.storage.add(this.SESSION_KEY, JSON.stringify(userData));
  }

  private clearSession(): void {
    this.storage.remove(this.SESSION_KEY);
  }
}
