import { template } from "@ember/template-compiler";
import or from 'ember-truth-helpers/helpers/or';
import htmlSafeSanitized from '../../helpers/html-safe-sanitized.ts';
import type { NotificationType } from '../../services/notifications.ts';
import Button from '../button.gts';
import NotificationBase from './notification-base.gts';
interface Signature {
    Element: HTMLButtonElement;
}
const COLORS: {
    bgColor: {
        [key in NotificationType & 'default']: string;
    };
    textColor: {
        [key in NotificationType & 'default']: string;
    };
    hoverTextColor: {
        [key in NotificationType & 'default']: string;
    };
} = {
    bgColor: {
        success: 'bg-green-500',
        warning: 'bg-orange-500',
        error: 'bg-red-500',
        neutral: 'bg-blue-500',
        default: 'bg-teal-500',
        intermediate: 'bg-black'
    },
    textColor: {
        success: 'text-green-500',
        warning: 'text-white',
        error: 'text-red-500',
        neutral: 'text-white',
        default: 'text-teal-500',
        intermediate: 'text-black'
    },
    hoverTextColor: {
        success: 'hover:text-green-500',
        warning: 'hover:text-orange-500',
        error: 'hover:text-red-500',
        neutral: 'hover:text-blue-500',
        default: 'hover:text-teal-500',
        intermediate: 'hover:text-black'
    }
};
const ICONS: {
    [key in NotificationType & 'default']: string;
} = {
    success: 'success-filled-white',
    error: 'error-filled-white',
    neutral: 'alert-filled',
    default: '',
    intermediate: ''
};
export default class FlashMessagesNotificationMinimal extends NotificationBase<Signature> {
    textColor = COLORS.textColor[this.type];
    icon = ICONS[this.type];
    get message() {
        return this.args.notification.message ?? '';
    }
    static{
        template(`
    <Button
      data-test-notification={{or @notification.testSelector true}}
      data-test-notification-type={{@notification.type}}
      class="w-full min-h-10 px-4 mb-1 text-white rounded-sm shadow focus:outline-none {{this.bgColor}}"
      @onClick={{@dismissNotification}}
      ...attributes
      as |c|
    >
      {{#if this.icon}}
        <c.ui.leftIcon @classes={{this.textColor}} @name={{this.icon}} @w="w-5" />
      {{/if}}

      <div role="alert" class="w-full text-left default-style [&_a]:text-white" data-test-notification-message>
        {{htmlSafeSanitized this.message}}
      </div>

      <c.ui.rightIcon @classes="text-black" @name="x" @w="w-3" />
    </Button>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
