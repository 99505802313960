/* eslint-disable prefer-const, prefer-template */
/*
  Based on http://bgrins.github.io/TinyColor/docs/tinycolor.html
*/

type HSL = string;
type HSLA = string;
type RGB = string;
type RGBA = string;
type RGB_WITH_KEYWORD = string;
type CSSColor = keyof typeof colors;
export type Color = HSL | HSLA | RGB | RGBA | RGB_WITH_KEYWORD | CSSColor;

type RgbaToHexOpts = {
  bgColor?: string;
};
type ColorToHexOpts = RgbaToHexOpts & {
  transformRGBA?: boolean;
};
type TRGB = {
  red: number;
  blue: number;
  green: number;
};

// Below this number the calculation for RGB -> sRGB changes
const NORMALIZED_BELOW_10 = 0.04045;

// The relative luminance value is from 0 -> 1. The midpoint
// is tells us at what point to swap from using white to black text.
// It is currently set slightly below half way as white text is less likely
// to meet the contrast threshold for any given colour
const RELATIVE_LUMINANCE_MIDPOINT = 0.45;

// These weights are percentages used for calculating the relative
// luminance value from a given sRGB value
const RELATIVE_LUMINANCE_WEIGHTS = {
  RED: 0.2126,
  GREEN: 0.7152,
  BLUE: 0.0722
};

const RGB_WITH_KEYWORD_REGEX = /(rgb)a?\(([a-z]+)(.+)\)/i;
const RGB_REGEX = /rgb\((\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*)\)/i;
const RGBA_REGEX =
  /rgba\(\s*(-?\d+|-?\d*\.\d+(?=%))(%?)\s*,\s*(-?\d+|-?\d*\.\d+(?=%))(\2)\s*,\s*(-?\d+|-?\d*\.\d+(?=%))(\2)\s*,\s*(-?\d+|-?\d*.\d+)(%?)\s*\)/;
const HSL_REGEX = /(hsl)\((.+),\s*(\d+.?\d*)\)/i;
const HSLA_REGEX = /(hsla)\((.+),\s*(\d+\.?\d*)\)/i;
const MATCH_DIGITS_REGEX = /[\d%.]+/g;

function isHSL(value: string): value is HSL {
  return HSL_REGEX.test(value);
}
function isHSLA(value: string): value is HSLA {
  return HSLA_REGEX.test(value);
}
function isRGB(value: string): value is RGB {
  return RGB_REGEX.test(value);
}
function isRGBWithKeyword(value: string): value is RGB_WITH_KEYWORD {
  return RGB_WITH_KEYWORD_REGEX.test(value);
}
function isRGBA(value: string): value is RGBA {
  return RGBA_REGEX.test(value);
}
function isCSSColor(value: any): value is CSSColor {
  return !!colors[value];
}
export function isColor(value: any): value is Color {
  return (
    isHSL(value) ||
    isHSLA(value) ||
    isRGB(value) ||
    isRGBA(value) ||
    isRGBWithKeyword(value) ||
    isCSSColor(value)
  );
}

function parseDigits(value: string): string[] {
  return value.match(MATCH_DIGITS_REGEX) ?? [];
}

function rgbToAbsoluteValue(value: string): number {
  return value.includes('%')
    ? (parseFloat(value) / 100) * 255
    : parseFloat(value);
}

function parseHSLValues(value: string | HSL) {
  if (!isHSL(value) && !isHSLA(value)) return value;
  return parseDigits(value);
}

export function parseRGBValues(value: string | RGB | RGBA) {
  if (!isRGBA(value) && !isRGB(value)) return value;
  return parseDigits(value).map(n => rgbToAbsoluteValue(n)) ?? [];
}

function santizeColor(color: string) {
  return color?.toLowerCase().trim();
}

// HSL <–> RGB

function hue2rgb(p: number, q: number, t: number) {
  if (t < 0) {
    t += 1;
  }
  if (t > 1) {
    t -= 1;
  }
  if (t < 1 / 6) {
    return p + (q - p) * 6 * t;
  }
  if (t < 1 / 2) {
    return q;
  }
  if (t < 2 / 3) {
    return p + (q - p) * (2 / 3 - t) * 6;
  }
  return p;
}

export function hslToRgb(
  h: number,
  s: number,
  l: number
): [number, number, number] {
  let r, g, b;
  h = h / 360;
  s = s / 100;
  l = l / 100;
  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    let q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    let p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }
  return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
}

export function rgbToHsl(r: number, g: number, b: number) {
  r = r / 255;
  g = g / 255;
  b = b / 255;
  let max = Math.max(r, g, b),
    min = Math.min(r, g, b);
  let h = 0,
    s = 0,
    l = (max + min) / 2;
  if (max !== min) {
    let d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }
  return [Math.round(h * 360), Math.round(s * 100), Math.round(l * 100)];
}

// RGB <-> Hex

function hexColor(value: number) {
  return `${value < 16 ? '0' : ''}${value.toString(16)}`;
}
export function rgbToHex(r: number, g: number, b: number) {
  return `#${hexColor(r)}${hexColor(g)}${hexColor(b)}`;
}

export function hexToRgb(hex: string): [number, number, number] {
  hex = colorToHex(hex);
  hex = hex.replace('#', '');
  const isShort = hex.length === 3;
  if (isShort) {
    const r = hex.substr(0, 1);
    const g = hex.substr(1, 1);
    const b = hex.substr(2, 1);
    return [
      parseInt(`${r}${r}`, 16),
      parseInt(`${g}${g}`, 16),
      parseInt(`${b}${b}`, 16)
    ];
  }
  const r = parseInt(hex.substr(0, 2), 16);
  const g = parseInt(hex.substr(2, 2), 16);
  const b = parseInt(hex.substr(4, 2), 16);
  return [r, g, b];
}

// HSL <-> Hex

export function hexToHsl(hex: string) {
  return rgbToHsl(...hexToRgb(hex));
}

export function hslToHex(h: number, s: number, l: number) {
  return rgbToHex(...hslToRgb(h, s, l));
}
export function rgbaToHex(color: string, options?: RgbaToHexOpts) {
  if (!isRGBA(color)) return color;
  const [r, g, b, alpha] = parseRGBValues(color);

  if (alpha < 1) {
    const [bg_r, bg_g, bg_b] = hexToRgb(
      colorToHex(extractAlpha(options?.bgColor || '#FFFFFF')[0])
    );
    const rgb = rgbaToRgb([bg_r, bg_g, bg_b], [r, g, b, alpha]);
    return rgbToHex(rgb[0], rgb[1], rgb[2]);
  }

  return colorToHex(extractAlpha(color)[0]);
}

export function colorToHex(value: string, options?: ColorToHexOpts): string {
  let color = santizeColor(value);
  if (colors[color]) return colors[color].toUpperCase();

  if (isRGB(color)) {
    const [r, g, b] = parseRGBValues(color);
    return rgbToHex(r, g, b).toUpperCase();
  }

  if (isHSL(color)) {
    const [h, s, l] = parseHSLValues(color).map(n => parseFloat(n));
    return hslToHex(h, s, l).toUpperCase();
  }

  if (options?.transformRGBA) {
    if (color === 'transparent') color = 'rgba(0,0,0,0)';
    if (isRGBA(color)) return rgbaToHex(color, options);
    return colorToHex(color);
  }
  return color;
}

export function extractAlpha(color: string): [string, number] {
  if (isRGBA(color)) {
    const [r, g, b, alpha] = parseRGBValues(color);
    return [`rgb(${r},${g},${b})`, alpha];
  } else if (isHSLA(color)) {
    const [r, g, b, alpha] = parseHSLValues(color);
    return [`hsl(${r},${g},${b})`, parseFloat(alpha)];
  }
  return [color, 1];
}

export function rgbaToRgb(
  [bg_r, bg_g, bg_b]: number[],
  [r, g, b, alpha]: number[]
) {
  return [
    Math.round((1 - alpha) * bg_r + alpha * r),
    Math.round((1 - alpha) * bg_g + alpha * g),
    Math.round((1 - alpha) * bg_b + alpha * b)
  ];
}

// RGB <-> sRGB <-> lRGB

/**
 * normalizeRGB/1
 *
 * This function reduces the R,G,B components of
 * a colour to an sRGB 0 - 1 value which can be used
 * to calculate an lRGB value for the colour
 */
function normalizeRGB(rgb: TRGB): TRGB {
  return Object.keys(rgb).reduce((acc, key: keyof TRGB) => {
    return { ...acc, [key]: rgb[key] / 255 };
  }, {} as TRGB);
}

/**
 * toSrgb/1
 *
 * Given a colour component of an RGB colour, convert that'
 * component to it's sRGB (0.0 -> 1) equivalent
 */
function toSrgb(color: number) {
  return color > NORMALIZED_BELOW_10
    ? Math.pow((color + 0.055) / 1.055, 2.4)
    : color / 12.92;
}

/**
 * rgbToSrgb/1
 *
 * Given an RGB colour, convert that colour into it's sRGB equivalent
 * in a TRGB type format.
 */
function rgbToSrgb(rgb: TRGB): TRGB {
  const normalized = normalizeRGB(rgb);
  return Object.keys(normalized).reduce((acc, key: keyof TRGB) => {
    return { ...acc, [key]: toSrgb(normalized[key]) };
  }, {} as TRGB);
}

/**
 * calculateRelativeLuminance/1
 *
 * This function is returns the relative luminance of a colour
 * which is used to calculate it's contrast ratio with another
 * colour.
 *
 * The method of doing this is laid out under the WCAG guidelines.
 * Link: https://www.w3.org/WAI/GL/wiki/Relative_luminance
 */
function calculateRelativeLuminance(rgb: TRGB): number {
  const { red, green, blue } = rgbToSrgb(rgb);
  return (
    red * RELATIVE_LUMINANCE_WEIGHTS.RED +
    green * RELATIVE_LUMINANCE_WEIGHTS.GREEN +
    blue * RELATIVE_LUMINANCE_WEIGHTS.BLUE
  );
}

// Utilities

export function isLight(
  value: string,
  options?: { bgColor?: string; threshold?: number }
) {
  const [color, alpha] = extractAlpha(value);

  if (alpha < 1 || options?.bgColor) {
    const [bg_r, bg_g, bg_b] = hexToRgb(
      colorToHex(extractAlpha(options?.bgColor || '#FFFFFF')[0])
    );

    const [red, green, blue] = rgbaToRgb(
      [bg_r, bg_g, bg_b],
      [...hexToRgb(colorToHex(color)), alpha]
    );

    return (
      calculateRelativeLuminance({ red, green, blue }) >=
      RELATIVE_LUMINANCE_MIDPOINT
    );
  } else {
    const [red, green, blue] = hexToRgb(colorToHex(color));

    return (
      calculateRelativeLuminance({ red, green, blue }) >=
      RELATIVE_LUMINANCE_MIDPOINT
    );
  }
}

export function isDark(
  color: string,
  options?: { bgColor?: string; threshold?: number }
) {
  return !isLight(color, options);
}

export function transformColor(
  _value: string,
  {
    alpha: _alpha,
    luminance: _luminance,
    hue: _hue,
    saturation: _saturation,
    contrastedColor,
    invertedColor,
    bgColor
  }: {
    alpha?: number;
    luminance?: number;
    hue?: number;
    saturation?: number;
    contrastedColor?: boolean;
    invertedColor?: boolean;
    bgColor?: string;
    contrastedThreshold?: number;
  }
) {
  let [value, alpha] = extractAlpha(_value);
  alpha *= _alpha ?? 1;
  let [hue, saturation, luminance] = rgbToHsl(...hexToRgb(value));
  saturation += _saturation ?? 0;
  luminance += _luminance ?? 0;
  hue += _hue ?? 0;
  if (invertedColor) {
    let [r, g, b] = hslToRgb(hue, saturation, luminance);
    r = 255 - r;
    g = 255 - g;
    b = 255 - b;
    [hue, saturation, luminance] = rgbToHsl(r, g, b);
  }
  if (contrastedColor) {
    luminance = isLight(value, { bgColor }) ? 0 : 100;
    alpha = 1;
  }
  return `hsla(${hue}, ${Math.min(100, Math.max(0, saturation))}%, ${Math.min(
    100,
    Math.max(0, luminance)
  )}%, ${Math.min(1, Math.max(0, alpha))})`;
}

/**
 * Mix two colors like sass mix function
 *
 * Implementation taken from https://gist.github.com/jedfoster/7939513
 */
export function mix(
  color1: string,
  color2: string,
  weight: number = 50
): string {
  color1 = colorToHex(color1);
  color2 = colorToHex(color2);
  color1 = color1.replace(/#/g, '');
  color2 = color2.replace(/#/g, '');

  // convert a hex value to decimal
  function h2d(h: string): number {
    return parseInt(h, 16);
  }

  let color = '#';

  // loop through each of the 3 hex pairs—red, green, and blue
  for (let i = 0; i <= 5; i += 2) {
    // extract the current pairs
    const v1 = h2d(color1.substr(i, 2));
    const v2 = h2d(color2.substr(i, 2));

    // combine the current pairs from each source color, according to the specified weight
    let val = hexColor(Math.floor(v2 + (v1 - v2) * (weight / 100.0)));

    while (val.length < 2) {
      // prepend a '0' if val results in a single digit
      val = '0' + val;
    }

    color += val; // concatenate val to our new color string
  }

  return color;
}

export function normalizeColor<T = string>(value: T): T | string {
  if (typeof value !== 'string') return value;

  const color = santizeColor(value);

  if (color && color.match(/^[0-9|A|B|C|D|E|F]{3,6}$/i)) {
    return `#${color}`;
  }

  if (isRGBWithKeyword(color)) {
    const keyword = color.match(/(?:rgba?)\(([a-z]+)/i)?.[1] as string;
    const alpha = parseDigits(color);
    const [r, g, b] = hexToRgb(colors[keyword].toUpperCase());
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }

  if (isRGBA(color)) {
    const matches = RGBA_REGEX.exec(color);
    const hexColor = colorToHex(matches![2]);
    if (hexColor !== matches![2]) {
      const [r, g, b] = hexToRgb(hexColor);
      return `${matches![1]}a(${r}, ${g}, ${b}, ${matches![3]})`;
    }
  }
  return color;
}

// Keywords
const colors: { [k: string]: string } = {
  aliceblue: '#F0F8FF',
  antiquewhite: '#FAEBD7',
  aqua: '#00FFFF',
  aquamarine: '#7FFFD4',
  azure: '#F0FFFF',
  beige: '#F5F5DC',
  bisque: '#FFE4C4',
  black: '#000000',
  blanchedalmond: '#FFEBCD',
  blue: '#0000FF',
  blueviolet: '#8A2BE2',
  brown: '#A52A2A',
  burlywood: '#DEB887',
  cadetblue: '#5F9EA0',
  chartreuse: '#7FFF00',
  chocolate: '#D2691E',
  coral: '#FF7F50',
  cornflowerblue: '#6495ED',
  cornsilk: '#FFF8DC',
  crimson: '#DC143C',
  cyan: '#00FFFF',
  darkblue: '#00008B',
  darkcyan: '#008B8B',
  darkgoldenrod: '#B8860B',
  darkgray: '#A9A9A9',
  darkgrey: '#A9A9A9',
  darkgreen: '#006400',
  darkkhaki: '#BDB76B',
  darkmagenta: '#8B008B',
  darkolivegreen: '#556B2F',
  darkorange: '#FF8C00',
  darkorchid: '#9932CC',
  darkred: '#8B0000',
  darksalmon: '#E9967A',
  darkseagreen: '#8FBC8F',
  darkslateblue: '#483D8B',
  darkslategray: '#2F4F4F',
  darkslategrey: '#2F4F4F',
  darkturquoise: '#00CED1',
  darkviolet: '#9400D3',
  deeppink: '#FF1493',
  deepskyblue: '#00BFFF',
  dimgray: '#696969',
  dimgrey: '#696969',
  dodgerblue: '#1E90FF',
  firebrick: '#B22222',
  floralwhite: '#FFFAF0',
  forestgreen: '#228B22',
  fuchsia: '#FF00FF',
  gainsboro: '#DCDCDC',
  ghostwhite: '#F8F8FF',
  gold: '#FFD700',
  goldenrod: '#DAA520',
  gray: '#808080',
  grey: '#808080',
  green: '#008000',
  greenyellow: '#ADFF2F',
  honeydew: '#F0FFF0',
  hotpink: '#FF69B4',
  indianred: '#CD5C5C',
  indigo: '#4B0082',
  ivory: '#FFFFF0',
  khaki: '#F0E68C',
  lavender: '#E6E6FA',
  lavenderblush: '#FFF0F5',
  lawngreen: '#7CFC00',
  lemonchiffon: '#FFFACD',
  lightblue: '#ADD8E6',
  lightcoral: '#F08080',
  lightcyan: '#E0FFFF',
  lightgoldenrodyellow: '#FAFAD2',
  lightgray: '#D3D3D3',
  lightgrey: '#D3D3D3',
  lightgreen: '#90EE90',
  lightpink: '#FFB6C1',
  lightsalmon: '#FFA07A',
  lightseagreen: '#20B2AA',
  lightskyblue: '#87CEFA',
  lightslategray: '#778899',
  lightslategrey: '#778899',
  lightsteelblue: '#B0C4DE',
  lightyellow: '#FFFFE0',
  lime: '#00FF00',
  limegreen: '#32CD32',
  linen: '#FAF0E6',
  magenta: '#FF00FF',
  maroon: '#800000',
  mediumaquamarine: '#66CDAA',
  mediumblue: '#0000CD',
  mediumorchid: '#BA55D3',
  mediumpurple: '#9370D8',
  mediumseagreen: '#3CB371',
  mediumslateblue: '#7B68EE',
  mediumspringgreen: '#00FA9A',
  mediumturquoise: '#48D1CC',
  mediumvioletred: '#C71585',
  midnightblue: '#191970',
  mintcream: '#F5FFFA',
  mistyrose: '#FFE4E1',
  moccasin: '#FFE4B5',
  navajowhite: '#FFDEAD',
  navy: '#000080',
  oldlace: '#FDF5E6',
  olive: '#808000',
  olivedrab: '#6B8E23',
  orange: '#FFA500',
  orangered: '#FF4500',
  orchid: '#DA70D6',
  palegoldenrod: '#EEE8AA',
  palegreen: '#98FB98',
  paleturquoise: '#AFEEEE',
  palevioletred: '#D87093',
  papayawhip: '#FFEFD5',
  peachpuff: '#FFDAB9',
  peru: '#CD853F',
  pink: '#FFC0CB',
  plum: '#DDA0DD',
  powderblue: '#B0E0E6',
  purple: '#800080',
  red: '#FF0000',
  rosybrown: '#BC8F8F',
  royalblue: '#4169E1',
  saddlebrown: '#8B4513',
  salmon: '#FA8072',
  sandybrown: '#F4A460',
  seagreen: '#2E8B57',
  seashell: '#FFF5EE',
  sienna: '#A0522D',
  silver: '#C0C0C0',
  skyblue: '#87CEEB',
  slateblue: '#6A5ACD',
  slategray: '#708090',
  slategrey: '#708090',
  snow: '#FFFAFA',
  springgreen: '#00FF7F',
  steelblue: '#4682B4',
  tan: '#D2B48C',
  teal: '#008080',
  thistle: '#D8BFD8',
  tomato: '#FF6347',
  turquoise: '#40E0D0',
  violet: '#EE82EE',
  wheat: '#F5DEB3',
  white: '#FFFFFF',
  whitesmoke: '#F5F5F5',
  yellow: '#FFFF00',
  yellowgreen: '#9ACD'
};
