import Component from '@glimmer/component';

import { htmlSafeSanitized } from '../../../helpers/html-safe-sanitized.ts';
import { getTheme, type FormTheme } from '../theme.ts';

interface FormTextareaBaseSignature {
  Args: {
    id: string;
    value: string;
    rows: string;
    placeholder?: string;
    displayErrors?: boolean;
    wrapperClasses?: string;
    theme?: FormTheme;

    isInvalid?: boolean;
    isRequired: boolean;
    autoGrow?: boolean;
  };
  Element: HTMLTextAreaElement;
}

export default class FormTextareaBase extends Component<FormTextareaBaseSignature> {
  style = getTheme(this.args.theme);

  get autoGrowStyle() {
    if (this.args.autoGrow) {
      return htmlSafeSanitized(['field-sizing: content;']);
    }
    return null;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Form::Textarea::Base': typeof FormTextareaBase;
    'form/textarea/base': typeof FormTextareaBase;
  }
}
