import { helper } from '@ember/component/helper';
import { assert } from '@ember/debug';
import { dasherize } from '@ember/string';
import { htmlSafe } from '@ember/template';

export function inlineStyle([hash]: any[], _options?: any) {
  assert('inline-style should receive a hash', typeof hash === 'object');
  const styles = Object.keys(hash).map(key => {
    const prop = dasherize(key);
    let value = hash[key] ?? '';
    if (_isURLproperty(prop)) {
      value = `url('${value}')`;
    }
    return `${prop}: ${value}`;
  });
  return htmlSafe(styles.join('; '));
}

function _isURLproperty(prop: string) {
  return ['background-image'].includes(prop);
}

export default helper(inlineStyle);
