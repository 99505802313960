import { Renderer } from '../components/canvas.ts';
import type { InteractionType } from '../components/seat-management/seat-map.ts';
import type { Rectangle, Offset } from '../utils/canvas-drawing.ts';
import {
  CIRCLE_RADIUS,
  reverseTransformRect,
  getCanvasCoordinatesFromMouseEvent,
  createRectangleFromCoordinates
} from '../utils/canvas-drawing.ts';
import type { Transform } from '../utils/canvas-drawing.ts';
import type { SeatBase } from '../utils/seat-editor.ts';
import { circleCoordinatesForSeat } from '../utils/seat-editor.ts';

type SelectionRectangle = {
  x: number;
  y: number;
  width: number;
  height: number;
};

export default class InteractionRenderer extends Renderer {
  /**
   * Renders the interaction canvas. This will clear the existing canvas and draw a
   * rectangle when necessary.
   *
   * @param width The width of the canvas to draw on.
   * @param height The height of the canvas to draw on.
   * @param mouseDownEvent The mouseEvent for when the user pressed down on the layer
   * @param mouseMoveEvent The mouseEvent of the last mousemove event on the layer
   * @param interaction An object where it tells what interaction is happening
   * @param boundingClientRect the ClientRect of the drawing surface
   * @param transform the Transform component of the current canvas
   * @param selectedSeats the seats to be dragged
   * @param backgroundCanvas the Canvas to get the background image while dragging
   * @param seatsCanvas the Canvas to get the main image while dragging
   */
  render(
    width: number,
    height: number,
    mouseDownEvent: MouseEvent,
    mouseMoveEvent: MouseEvent,
    interaction: InteractionType,
    boundingClientRect: DOMRect,
    transform: Transform,
    selectedSeats: SeatBase[],
    renderers: { [k: string]: Renderer },
    backgroundCanvasName: string,
    seatsCanvasName: string
  ) {
    this.context.clearRect(0, 0, width, height);

    if (interaction.isSelecting) {
      this._renderSelectInteraction(
        mouseDownEvent,
        mouseMoveEvent,
        boundingClientRect,
        interaction.preselectedSeatsLength
      );
    } else if (interaction.isDragging && mouseMoveEvent) {
      const backgroundCanvas = renderers[backgroundCanvasName].context;
      const seatsCanvas = renderers[seatsCanvasName].context;
      this._renderDragInteraction(
        mouseMoveEvent,
        mouseDownEvent,
        selectedSeats,
        transform,
        backgroundCanvas,
        seatsCanvas
      );
    }
  }

  private _renderSelectInteraction(
    mouseDownEvent: MouseEvent,
    mouseMoveEvent: MouseEvent,
    boundingClientRect: DOMRect,
    preselectedSeatsLength: number | null
  ) {
    this.context.beginPath();
    const rect = this._createSelectionRect(
      mouseDownEvent,
      mouseMoveEvent,
      boundingClientRect
    );
    if (rect) {
      this.context.rect(rect.x, rect.y, rect.width, rect.height);
    }
    this.context.stroke();
    this.context.closePath();

    if (rect && preselectedSeatsLength) {
      const text = `${preselectedSeatsLength} seat(s) selected`;
      const fontSize = 14;
      this.context.font = `${fontSize}px Graphik, sans-serif`;
      const textMetrics = this.context.measureText(text);
      this.context.beginPath();
      this.context.rect(
        rect.x,
        rect.y - 2 - 1.6 * fontSize,
        textMetrics.width + 10,
        fontSize * 1.6
      );
      this.context.fillStyle = 'rgba(255,255,255,0.9)';
      this.context.fill();
      this.context.closePath();
      this.context.fillStyle = '#000';
      this.context.fillText(text, rect.x + 5, rect.y - 2 - fontSize * 0.4);
    }
  }

  private _renderDragInteraction(
    mouseMoveEvent: MouseEvent,
    mouseDownEvent: MouseEvent,
    selectedSeats: SeatBase[],
    transform: Transform,
    backgroundCanvas: CanvasRenderingContext2D,
    seatsCanvas: CanvasRenderingContext2D
  ) {
    const offset = {
      x: mouseMoveEvent.clientX - mouseDownEvent.clientX,
      y: mouseMoveEvent.clientY - mouseDownEvent.clientY
    };
    // overlap seat with the background
    selectedSeats.forEach(seat => {
      const rect = this._getRectFromSeat(seat, transform);
      this._drawRectFromAnotherCanvas(backgroundCanvas, rect);
    });
    // offset seat with mouseevent
    selectedSeats.forEach(seat => {
      const rect = this._getRectFromSeat(seat, transform);
      this._drawRectFromAnotherCanvas(seatsCanvas, rect, offset);
    });
  }

  private _getRectFromSeat(seat: SeatBase, transform: Transform) {
    const radius = CIRCLE_RADIUS + 2;

    const [cx, cy] = circleCoordinatesForSeat(seat);
    return reverseTransformRect(
      { x: cx - radius, y: cy - radius, width: radius * 2, height: radius * 2 },
      transform
    );
  }

  private _drawRectFromAnotherCanvas(
    canvas: CanvasRenderingContext2D,
    rect: Rectangle,
    { x: offsetX, y: offsetY }: Offset = { x: 0, y: 0 }
  ) {
    this.context.drawImage(
      canvas.canvas,
      rect.x,
      rect.y,
      rect.width,
      rect.height,
      rect.x + offsetX,
      rect.y + offsetY,
      rect.width,
      rect.height
    );
  }

  private _selectionRectFromDownMoveEvents(
    mouseDownEvent: MouseEvent,
    mouseMoveEvent: MouseEvent,
    boundingClientRect: DOMRect
  ): SelectionRectangle {
    return createRectangleFromCoordinates(
      getCanvasCoordinatesFromMouseEvent(mouseDownEvent, boundingClientRect),
      getCanvasCoordinatesFromMouseEvent(mouseMoveEvent, boundingClientRect)
    );
  }

  private _createSelectionRect(
    mouseDownEvent: MouseEvent,
    mouseMoveEvent: MouseEvent,
    boundingClientRect: DOMRect
  ): SelectionRectangle | undefined {
    if (mouseDownEvent && mouseMoveEvent) {
      return this._selectionRectFromDownMoveEvents(
        mouseDownEvent,
        mouseMoveEvent,
        boundingClientRect
      );
    }

    return undefined;
  }
}
