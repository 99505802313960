import Component from '@glimmer/component';

import { action } from '@ember/object';

interface Signature<T> {
  Args: {
    selectedItem: T | null;
    searchResults: T[];
    isOpen: boolean;
    displayLoadingUI: boolean;
    searchDidComplete: boolean;
    clickOutsideExceptSelector?: string;
    loadMoreResults?: Function;
    constraints?: any;
    target?: string;
    classes?: string;
    itemClasses?: string;
    maxHeight?: string;
    onSelectItem?: (item: T) => void;
    onDeselectItem?: (item: T) => void;
    onSubmitItem?: (item: T) => void;
    onBackgroundClicked?: () => void;
  };
  Blocks: {
    default: [unknown];
  };
  Element: HTMLDivElement;
}

export default class TypeaheadDropdownComponent<Item = any> extends Component<
  Signature<Item>
> {
  get constraints() {
    return (
      this.args.constraints ?? [
        {
          to: 'window',
          attachment: 'together',
          pin: true
        }
      ]
    );
  }

  @action
  focusItem(item: Item) {
    return this.args.onSelectItem?.(item);
  }

  @action
  unfocusItem(item: Item) {
    return this.args.onDeselectItem?.(item);
  }

  @action
  clickItem(item: Item) {
    return this.args.onSubmitItem?.(item);
  }

  @action
  backgroundClicked() {
    return this.args.onBackgroundClicked?.();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Typeahead::Dropdown': typeof TypeaheadDropdownComponent;
    'typeahead/dropdown': typeof TypeaheadDropdownComponent;
  }
}
