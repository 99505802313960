import Component from '@glimmer/component';

interface TableTableSignature {
  Args: {
    fullWidth?: boolean;
    isFixed?: boolean;
    isSticky?: boolean;
  };
  Blocks: {
    default: [];
  };
  Element: HTMLTableElement;
}

export default class TableTableComponent extends Component<TableTableSignature> {
  get fullWidth() {
    return this.args.fullWidth ?? true;
  }

  get isFixed() {
    return this.args.isFixed ?? true;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Table::Table': typeof TableTableComponent;
    'table/table': typeof TableTableComponent;
  }
}
