import type { Coordinates2D, Transform } from './canvas-drawing.ts';
import {
  circleCoordinatesForXY,
  CIRCLE_CORRECTION,
  CIRCLE_RADIUS,
  createSelectionRectangleFromMouseEvents,
  rectCircleCollision
} from './canvas-drawing.ts';

/**
 * Minimal interface for SeatModel and SeatAssignmentModel to use in seat editor
 *
 *  - SeatManagement
 *  - SeatManagement::SeatMap
 *  - Canvas Renderers (interaction, seats-selection)
 *
 */
export interface SeatBase {
  readonly x: number;
  readonly y: number;
  readonly blocked: boolean;
}

export function circleCoordinatesForSeat(seat: SeatBase): Coordinates2D {
  const { x, y } = seat;
  return circleCoordinatesForXY(x, y);
}

export function seatsForSelection({
  mouseDownEvent,
  mouseUpEvent,
  boundingClientRect,
  transform,
  seats,
  offsetContainer
}: {
  mouseDownEvent: MouseEvent;
  mouseUpEvent: MouseEvent;
  boundingClientRect: DOMRect;
  transform: Transform;
  seats: SeatBase[];
  offsetContainer?: HTMLElement;
}) {
  const rect = createSelectionRectangleFromMouseEvents(
    mouseDownEvent,
    mouseUpEvent,
    boundingClientRect,
    transform,
    offsetContainer
  );

  const seatsInSelection = seats.filter(seat => {
    const [x, y] = circleCoordinatesForSeat(seat);
    const circle = {
      x,
      y,
      r: CIRCLE_RADIUS
    };

    return rectCircleCollision(circle, rect);
  });

  return Object.values(
    seatsInSelection.reduce((acc: { [key: string]: SeatBase }, seat) => {
      const { x, y } = seat;
      const uniquenesString = `${x}:${y}`;

      acc[uniquenesString] = seat;

      return acc;
    }, {})
  );
}

export function findSeatByCoordinates<T extends SeatBase>(
  [x, y]: Coordinates2D,
  seats: T[]
): T | undefined {
  const selection = { x, y, width: 1, height: 1 };
  return seats.find(({ x, y }) => {
    const circle = {
      x: x + CIRCLE_CORRECTION,
      y: y + CIRCLE_CORRECTION,
      r: CIRCLE_RADIUS
    };
    return rectCircleCollision(circle, selection);
  });
}
