import { template } from "@ember/template-compiler";
interface ModalHeaderSubtitleSignature {
    Args: {
    };
    Blocks: {
        default: [];
    };
    Element: HTMLDivElement;
}
const ModalHeaderSubtitleComponent: TemplateOnlyComponent<ModalHeaderSubtitleSignature> = template(`
  <div class="mb-px mt-0.5 text-base font-normal opacity-70" ...attributes>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ModalHeaderSubtitleComponent;
