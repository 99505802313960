import { tracked } from '@glimmer/tracking';

export default class MemoryStorage {
  @tracked store: any = {};

  clear() {
    this.store = {};
  }

  getItem(key: string): any {
    return this.store[key];
  }

  setItem(key: string, value: any) {
    this.store = { ...this.store, [key]: value };
  }

  removeItem(key: string): void {
    delete this.store[key];
    this.store = { ...this.store };
  }
}
