import { template } from "@ember/template-compiler";
import Button from './button.gts';
import Icon from './icon.gts';
interface ChipSignature {
    Args: {
        icon: string;
        label?: string;
        contentClasses?: string;
        onRemove?: Function;
        color?: string;
    };
    Element: HTMLDivElement;
    Blocks: {
        default: [];
    };
}
function colorClasses(color1: string = 'green') {
    if (color1 === 'yellow') {
        return `text-yellow-500 bg-yellow-50 border-yellow-500 hover:bg-yellow-200 active:text-yellow-600 active:bg-yellow-300 active:border-yellow-600`;
    }
    return `text-green-500 bg-green-50 border-green-500 hover:bg-green-200 active:text-green-600 active:bg-green-300 active:border-green-600`;
}
const ChipComponent: TemplateOnlyComponent<ChipSignature> = template(`
  <div
    class='text-sm font-medium border px-2 h-6 rounded-2xl inline-flex flex items-center gap-2
      {{colorClasses @color}}'
    ...attributes
  >
    <Icon @name={{@icon}} @w='w-4' @classes="min-w-4" />
    <div class={{@contentClasses}}>
      {{#if (has-block)}}
        {{yield}}
      {{else}}
        {{@label}}
      {{/if}}
    </div>

    {{#if @onRemove}}
      <Button @onClick={{@onRemove}} data-test-remove-chip as |c|>
        <c.ui.rightIcon @name='x' @w='w-2' @_classes='mt-px' />
      </Button>
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ChipComponent;
