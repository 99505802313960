import { helper } from '@ember/component/helper';

import type { OptionsTransformArg } from '../components/dropdown.ts';
import { optionsTransform } from '../components/dropdown.ts';

export function mapOptions([value, options]: [any[]?, OptionsTransformArg?]) {
  if (!value) {
    return [];
  }
  return value.map(optionsTransform(options));
}

export default helper(mapOptions);
