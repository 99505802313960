import { action } from '@ember/object';

import type {
  ExtractFormProps,
  ExtractFormValue,
  FormContext
} from './-control-base.ts';
import FormBaseComponent from './-control-base.ts';
import type { FormTheme } from './theme.ts';

type TextareaProperty = string;

interface Signature<
  Context extends FormContext,
  Property extends ExtractFormProps<Context, TextareaProperty>,
  Value
> {
  Args: {
    f: Context;
    property: Property;
    value?: Value;
    label?: string;
    testSelector?: string;
    hint?: string;
    wrapperClasses?: string;
    formFieldWrapperClasses?: string;
    theme?: FormTheme;

    isDisabled?: boolean;
    inline?: boolean;
    placeholder?: string;
    rows?: string;
    autoGrow?: boolean;
  };
  Blocks: {
    default: [];
  };
  Element: HTMLTextAreaElement;
}

export default class FormTextarea<
  Context extends FormContext,
  Property extends ExtractFormProps<Context, TextareaProperty>,
  Value extends ExtractFormValue<Context, Property>
> extends FormBaseComponent<
  Signature<Context, Property, Value>,
  TextareaProperty
> {
  @action
  onInput(event: Event) {
    const {
      target: { value }
    } = event as Event & { target: HTMLTextAreaElement };

    return this.onChange(value);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Form::Textarea': typeof FormTextarea;
  }
}
