import { next } from '@ember/runloop';

export function scrollToElement(
  element: string | HTMLElement,
  parent?: string | Element
) {
  next(null, () => {
    let domElement: HTMLElement | null = null;
    if (element instanceof Element) {
      domElement = element;
    } else {
      domElement = document.querySelector(element);
    }
    let domParentElement: Element | null | undefined = null;
    if (parent instanceof Element) {
      domParentElement = parent;
    } else {
      domParentElement = parent
        ? document.querySelector(parent)
        : domElement?.parentElement;
    }

    if (
      domElement &&
      domParentElement &&
      !selectedElementIsVisible(domElement, domParentElement)
    ) {
      domParentElement.scrollTo({ left: 0, top: domElement.offsetTop });
    }
  });
}

function selectedElementIsVisible(element: HTMLElement, parent: Element) {
  const containerRect = parent.getBoundingClientRect();
  const childRect = element.getBoundingClientRect();

  const elemTop = childRect.top;
  const elemBottom = childRect.bottom;
  const containerTop = containerRect.top;
  const containerBottom = containerRect.bottom;

  return elemTop >= containerTop && elemBottom <= containerBottom;
}
