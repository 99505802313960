import { template } from "@ember/template-compiler";
import type { ButtonDefaultBlock } from '../../button.gts';
import ButtonTopBase from './base.gts';
interface ButtonTopSecondarySignature {
    Args: {
        disabled?: boolean;
        isBusy?: string;
        type?: string;
        onClick?: Function;
        onSuccess?: Function;
        onError?: Function;
        testSelector?: string;
        narrowStyle?: boolean;
        contentClasses?: string;
        classes?: string;
        text?: string;
        small?: boolean;
        borderRadius?: 'normal' | 'small';
    };
    Blocks: {
        default: [ButtonDefaultBlock];
    };
    Element: HTMLDivElement;
}
const ButtonTopSecondaryComponent: TemplateOnlyComponent<ButtonTopSecondarySignature> = template(`
  <ButtonTopBase
    @disabled={{@disabled}}
    @onClick={{@onClick}}
    @onSuccess={{@onSuccess}}
    @onError={{@onError}}
    @narrowStyle={{@narrowStyle}}
    @contentClasses={{@contentClasses}}
    @testSelector={{@testSelector}}
    @small={{@small}}
    @borderRadius={{@borderRadius}}
    class="cta-button--top-secondary {{@classes}}" as |c|>
    <c.ui.content  ...attributes>
      {{#if (has-block)}}
        {{yield c}}
      {{else}}
        {{@text}}
      {{/if}}
    </c.ui.content>
  </ButtonTopBase>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ButtonTopSecondaryComponent;
