import type AnnoucerService from 'a11y-announcer';

import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import FormInputBaseComponent from './base.ts';

type Args = {
  key: string;
  currentLocale?: 'en' | 'cy' | 'ie';

  property: string;
  label?: string;
  ariaDescription?: string;
  wrapperClasses?: string;
  placeholder?: string;
};

type SearchParameters = {
  Key: string;
  Container?: string;
  Origin?: string;
  Limit?: number;
  Language?: Args['currentLocale'];
  $block?: boolean;
  $cache: true;
};

const wait = (time: number) => new Promise(r => setTimeout(r, time));

export default class FormAddressFinder extends FormInputBaseComponent<Args> {
  @service announcer!: AnnoucerService;

  get currentLocale() {
    return this.args.currentLocale ?? 'en';
  }

  get key() {
    return this.args.key;
  }

  get defaultSearchParams(): SearchParameters {
    return {
      Key: this.key,
      Origin: 'GBR',
      Limit: 7,
      Language: this.currentLocale,
      $block: true,
      $cache: true
    };
  }

  get customerFields() {
    return {
      Line1: 'addressLine1',
      Line2: 'addressLine2',
      PostalCode: 'postCode',
      City: 'cityTown',
      Province: 'region',
      CountryName: 'country'
    };
  }

  setAddressLabel({ Text, Description }: any) {
    if (!Description) return Text;
    return `${Text}, ${Description}`;
  }

  loqateFetch(endpoint: string, params: object) {
    return new Promise((success, error) => {
      // @ts-ignore
      return window.pca?.fetch(endpoint, params, success, error);
    });
  }

  @action
  shouldResubmit(context: any, event: any) {
    const itemType = context.selectedItem?.Type ?? event.item?.Type;
    return itemType !== 'Address';
  }

  @action
  async searchForAddress(text: string, event?: { item: { Id: string } }) {
    if (!text) return;
    await wait(500);

    const searchParameters = {
      ...this.defaultSearchParams,
      Text: text
    };

    if (event.item) searchParameters.Container = event.item.Id;

    return this.loqateFetch('Capture/Interactive/Find/v1.00', searchParameters);
  }

  @action
  async applyAddress(change: Function, _prop: string, address: any) {
    if (!address.Id) return;

    const params = {
      Key: this.key,
      id: address.Id,
      $cache: true
    };

    const [finalAddress] = (await this.loqateFetch(
      'Capture/Interactive/Retrieve/v1.00',
      params
    )) as any[];

    Object.keys(this.customerFields).forEach(
      k => finalAddress[k] && change(this.customerFields[k], finalAddress[k])
    );
    this.announcer?.announce('address applied', 'assertive');
  }
}
