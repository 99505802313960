import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type { WithBoundArgs } from '@glint/template';
import { dedupeTracked } from 'tracked-toolbox';
import type { DoneEventObject } from 'xstate';
import { assign, Machine } from 'xstate';
import type { StringSchema } from 'yup';
import { object } from 'yup';
import { hash } from '@ember/helper';
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import { task, timeout } from 'ember-concurrency';
import { useMachine } from 'ember-statecharts';
import type ErrorsService from '../services/-errors-base.ts';
import type EnvironmentService from '../services/environment.ts';
import { scrollToElement } from '../utils/dom-helpers.ts';
import TypeaheadDropdown from './typeahead/dropdown.ts';
import TypeaheadInput from './typeahead/input.gts';
interface TypeaheadContext<Item = any> {
    searchTerm: string | null;
    selectedItem: Item | null;
    searchResults: Item[];
}
interface StateSchema {
    states: {
        idle: {
        };
        changed: {
            states: {
                validity: {
                    states: {
                        validating: {
                        };
                        valid: {
                        };
                        invalid: {
                            states: {
                                hidden: {
                                };
                                shown: {
                                };
                            };
                        };
                        busy: {
                        };
                        remoteInvalid: {
                        };
                    };
                };
                search: {
                    states: {
                        resubmiting: {
                        };
                        searching: {
                        };
                        results: {
                            states: {
                                selection: {
                                    states: {
                                        noneSelected: {
                                        };
                                        selected: {
                                        };
                                        adding: {
                                        };
                                        addingFailed: {
                                        };
                                    };
                                };
                                loading: {
                                    states: {
                                        idle: {
                                        };
                                        loadingMore: {
                                        };
                                    };
                                };
                            };
                        };
                        resultsHidden: {
                        };
                        error: {
                        };
                    };
                };
            };
        };
    };
}
type Event<Item = any> = {
    type: 'resolve';
    searchResults: Item[];
} | {
    type: 'pressEnter';
    searchTerm: string | null;
} | {
    type: 'hideResults';
} | {
    type: 'clearValue';
} | {
    type: 'pressEscape';
    event: KeyboardEvent;
} | {
    type: 'selectNext';
} | {
    type: 'selectPrevious';
} | {
    type: 'type';
    searchTerm: string | null;
} | {
    type: 'search';
    searchTerm: string | null;
} | {
    type: 'select';
    item: Item;
} | {
    type: 'deselect';
} | {
    type: 'submit';
    item: Item;
} | {
    type: 'showResults';
} | {
    type: 'loadMore';
};
interface Signature<T> {
    Args: {
        typeaheadInputId?: string;
        allowEmptySearch?: boolean;
        allowFastEmptySearch?: boolean;
        searchOnType?: boolean;
        searchTerm?: string;
        selectFirstResult?: boolean;
        searchOnOpen?: boolean;
        blockEnterWhileSearching?: boolean;
        recordsCount?: number;
        Validations?: StringSchema;
        onBlur?: () => void;
        onEnterPressed?: (selectedItemOrSearchTerm: T | string, searchResults: T[]) => void;
        onPerformSearch?: (searchTerm: string, event?: object) => Promise<T[] | undefined>;
        loadMoreResults?: (searchResults: T[], searchTerm: string | null) => Promise<T[]>;
        onRejectSearch?: (error: any) => void;
        onPerformAdd?: (item: T) => void;
        onRejectAdd?: (error: any) => void;
        onResultsHidden?: () => void;
        onRejectEnter?: (error: any) => void;
        sort?: (items: T[]) => T[];
        sortByParam?: string;
        shouldResubmit?: Function;
    };
    Blocks: {
        default: [{
                ui: {
                    input: WithBoundArgs<typeof TypeaheadInput, 'value' | 'disabled' | 'querySelector' | 'onInput' | 'onFocus' | 'onKeyDown'>;
                    dropdown: WithBoundArgs<typeof TypeaheadDropdown, 'isOpen' | 'searchResults' | 'searchDidComplete' | 'loadMoreResults' | 'selectedItem' | 'displayLoadingUI' | 'clickOutsideExceptSelector' | 'onDeselectItem' | 'onSelectItem' | 'onSubmitItem' | 'onBackgroundClicked'>;
                };
                actions: {
                    enter: TypeaheadComponent<T>['enter'];
                    type: TypeaheadComponent<T>['type'];
                    select: TypeaheadComponent<T>['selectItem'];
                    search: TypeaheadComponent<T>['search'];
                    onDeselectItem: TypeaheadComponent<T>['resetSelection'];
                    onSelectItem: TypeaheadComponent<T>['selectItem'];
                    onSubmitItem: TypeaheadComponent<T>['submitItem'];
                    hideResults: TypeaheadComponent<T>['hideResults'];
                    showResults: TypeaheadComponent<T>['showResults'];
                    focusInput: TypeaheadComponent<T>['focusInput'];
                };
                state: {
                    searchTerm: TypeaheadComponent<T>['searchTerm'];
                    searchIsInProgress: TypeaheadComponent<T>['searchIsInProgress'];
                    searchDidError: TypeaheadComponent<T>['searchDidError'];
                    searchDidComplete: TypeaheadComponent<T>['searchDidComplete'];
                    searchResults: TypeaheadComponent<T>['searchResults'];
                    enterIsInProgress: TypeaheadComponent<T>['enterIsInProgress'];
                    enterDidError: TypeaheadComponent<T>['enterDidError'];
                    showValidationError: TypeaheadComponent<T>['showValidationError'];
                    showSearchUI: TypeaheadComponent<T>['showSearchUI'];
                    disableInput: TypeaheadComponent<T>['disableInput'];
                    inputSelector: TypeaheadComponent<T>['inputSelector'];
                    selectedItem: TypeaheadComponent<T>['selectedItem'];
                };
            }, TypeaheadComponent<T>];
    };
}
export default class TypeaheadComponent<Item> extends Component<Signature<Item>> {
    @service()
    private environment: EnvironmentService;
    @service
    private errors: ErrorsService;
    @tracked
    typeaheadInputId: string;
    get selectedItem() {
        return this.statechart.state?.context.selectedItem;
    }
    get searchTerm() {
        return (this.statechart.state?.context.searchTerm ?? this.args.searchTerm ?? null);
    }
    @dedupeTracked
    searchResults: TypeaheadContext['searchResults'] = [];
    private get allowEmptySearch(): boolean {
        return this.args.allowEmptySearch ?? false;
    }
    private get searchOnType(): boolean {
        return this.args.searchOnType ?? true;
    }
    private get allowFastEmptySearch(): boolean {
        return this.args.allowFastEmptySearch ?? false;
    }
    private get selectFirstResult(): boolean {
        return this.args.selectFirstResult ?? false;
    }
    private get blockEnterWhileSearching(): boolean {
        return this.args.blockEnterWhileSearching ?? false;
    }
    private get recordsCount(): number {
        return this.args.recordsCount ?? 0;
    }
    get inputSelector(): string {
        return `[data-input="${this.typeaheadInputId}"]`;
    }
    private get debounceTimeout(): number {
        if (this.environment.isTest) {
            return 0;
        }
        if (this.allowFastEmptySearch && isEmpty(this.searchTerm)) {
            return 0;
        }
        return 500;
    }
    constructor(owner1: unknown, args1: Signature<Item>['Args']){
        super(owner1, args1);
        this.typeaheadInputId = this.args.typeaheadInputId ?? guidFor(this);
    }
    statechart = useMachine(this, ()=>({
            machine: Machine<TypeaheadContext, StateSchema, Event>({
                initial: 'idle',
                context: {
                    searchTerm: this.args.searchTerm ?? null,
                    selectedItem: null,
                    searchResults: []
                },
                states: {
                    idle: {
                        id: 'idle',
                        on: {
                            select: {
                                target: 'changed.search.results.selection.adding',
                                actions: [
                                    assign({
                                        selectedItem: (_context1, { item: item1 })=>item1
                                    })
                                ]
                            },
                            type: [
                                {
                                    target: 'changed',
                                    cond: (): boolean =>this.searchOnType,
                                    actions: assign({
                                        searchTerm: (_context1, event1)=>event1.searchTerm
                                    })
                                },
                                {
                                    actions: assign({
                                        searchTerm: (_context1, event1)=>event1.searchTerm
                                    })
                                }
                            ],
                            showResults: {
                                target: 'changed',
                                cond: (): boolean =>this.allowEmptySearch
                            }
                        }
                    },
                    changed: {
                        type: 'parallel',
                        on: {
                            search: [
                                {
                                    target: 'idle',
                                    cond: (_context1, { searchTerm: searchTerm1 }): boolean =>isEmpty(searchTerm1) && !this.allowEmptySearch,
                                    actions: [
                                        '_clearState'
                                    ]
                                },
                                {
                                    target: 'changed',
                                    cond: (_context1, { searchTerm: searchTerm1 }): boolean =>!isEmpty(searchTerm1) || this.allowEmptySearch,
                                    actions: assign({
                                        searchTerm: (_context1, event1)=>event1.searchTerm
                                    })
                                }
                            ],
                            type: [
                                {
                                    target: 'idle',
                                    cond: (_context1, { searchTerm: searchTerm1 }): boolean =>isEmpty(searchTerm1) && !this.allowEmptySearch,
                                    actions: [
                                        '_clearState'
                                    ]
                                },
                                {
                                    target: 'changed',
                                    cond: (_context1, { searchTerm: searchTerm1 }): boolean =>this.searchOnType && (!isEmpty(searchTerm1) || this.allowEmptySearch),
                                    actions: assign({
                                        searchTerm: (_context1, event1)=>event1.searchTerm
                                    })
                                },
                                {
                                    actions: assign({
                                        searchTerm: (_context1, event1)=>event1.searchTerm
                                    })
                                }
                            ]
                        },
                        states: {
                            validity: {
                                initial: 'validating',
                                states: {
                                    validating: {
                                        invoke: {
                                            src: 'validateInput',
                                            onDone: 'valid',
                                            onError: 'invalid'
                                        }
                                    },
                                    valid: {
                                        on: {
                                            pressEnter: [
                                                {
                                                    target: '#resubmiting',
                                                    cond: 'shouldResubmit'
                                                },
                                                {
                                                    target: 'busy',
                                                    cond: (): boolean =>!(this.blockEnterWhileSearching && this.searchIsInProgress)
                                                }
                                            ]
                                        }
                                    },
                                    invalid: {
                                        initial: 'hidden',
                                        states: {
                                            hidden: {
                                                on: {
                                                    pressEnter: 'shown'
                                                }
                                            },
                                            shown: {}
                                        }
                                    },
                                    busy: {
                                        invoke: {
                                            src: 'performEnter',
                                            onDone: {
                                                target: '#idle',
                                                actions: [
                                                    '_clearState'
                                                ]
                                            },
                                            onError: {
                                                target: 'remoteInvalid',
                                                actions: [
                                                    '_rejectEnter'
                                                ]
                                            }
                                        }
                                    },
                                    remoteInvalid: {
                                        on: {
                                            pressEnter: 'busy'
                                        }
                                    }
                                }
                            },
                            search: {
                                initial: 'searching',
                                states: {
                                    resubmiting: {
                                        id: 'resubmiting',
                                        entry: assign({
                                            searchTerm: (context1: any)=>context1.selectedItem.Id ?? context1.searchTerm
                                        }),
                                        always: 'searching'
                                    },
                                    searching: {
                                        id: 'searching',
                                        invoke: {
                                            src: 'performSearch',
                                            onDone: {
                                                target: 'results',
                                                actions: assign({
                                                    searchResults: (_context1, { data: data1 })=>this._sort([
                                                            ...data1
                                                        ])
                                                })
                                            },
                                            onError: {
                                                target: 'error',
                                                actions: [
                                                    '_rejectSearch'
                                                ]
                                            }
                                        }
                                    },
                                    results: {
                                        type: 'parallel',
                                        states: {
                                            selection: {
                                                initial: 'noneSelected',
                                                on: {
                                                    hideResults: '#resultsHidden',
                                                    clearValue: {
                                                        target: '#resultsHidden',
                                                        actions: [
                                                            '_setItemToNull'
                                                        ]
                                                    }
                                                },
                                                states: {
                                                    noneSelected: {
                                                        entry: [
                                                            '_checkSelected'
                                                        ],
                                                        on: {
                                                            select: {
                                                                target: 'selected',
                                                                actions: assign({
                                                                    selectedItem: (_context1, { item: item1 })=>item1
                                                                })
                                                            },
                                                            pressEscape: {
                                                                target: '#resultsHidden',
                                                                actions: [
                                                                    '_blur'
                                                                ]
                                                            },
                                                            selectPrevious: {
                                                                target: 'selected',
                                                                actions: [
                                                                    '_selectPrevious'
                                                                ]
                                                            },
                                                            selectNext: {
                                                                target: 'selected',
                                                                actions: [
                                                                    '_selectNext'
                                                                ]
                                                            },
                                                            submit: [
                                                                {
                                                                    target: '#resubmiting',
                                                                    cond: 'shouldResubmit'
                                                                },
                                                                {
                                                                    target: 'adding'
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    selected: {
                                                        on: {
                                                            deselect: {
                                                                target: 'noneSelected',
                                                                actions: [
                                                                    '_setItemToNull'
                                                                ]
                                                            },
                                                            pressEscape: {
                                                                target: 'noneSelected',
                                                                actions: [
                                                                    '_setItemToNull'
                                                                ]
                                                            },
                                                            select: {
                                                                target: 'selected',
                                                                actions: assign({
                                                                    selectedItem: (_context1, { item: item1 })=>item1
                                                                })
                                                            },
                                                            selectNext: {
                                                                target: 'selected',
                                                                actions: [
                                                                    '_selectNext'
                                                                ]
                                                            },
                                                            selectPrevious: {
                                                                target: 'selected',
                                                                actions: [
                                                                    '_selectPrevious'
                                                                ]
                                                            },
                                                            submit: [
                                                                {
                                                                    target: '#resubmiting',
                                                                    cond: 'shouldResubmit'
                                                                },
                                                                {
                                                                    target: 'adding'
                                                                }
                                                            ]
                                                        }
                                                    },
                                                    adding: {
                                                        invoke: {
                                                            src: 'performAdd',
                                                            onDone: {
                                                                target: '#idle',
                                                                actions: [
                                                                    '_clearState'
                                                                ]
                                                            },
                                                            onError: {
                                                                target: 'addingFailed',
                                                                actions: [
                                                                    '_rejectAdd'
                                                                ]
                                                            }
                                                        }
                                                    },
                                                    addingFailed: {
                                                        on: {
                                                            select: {
                                                                target: 'selected',
                                                                actions: assign({
                                                                    selectedItem: (_context1, { item: item1 })=>item1
                                                                })
                                                            }
                                                        }
                                                    }
                                                }
                                            },
                                            loading: {
                                                initial: 'idle',
                                                states: {
                                                    idle: {
                                                        on: {
                                                            loadMore: {
                                                                target: 'loadingMore',
                                                                cond: (context1): boolean =>context1.searchResults.length < this.recordsCount
                                                            }
                                                        }
                                                    },
                                                    loadingMore: {
                                                        invoke: {
                                                            src: 'performLoadingMore',
                                                            onDone: {
                                                                target: 'idle',
                                                                actions: assign({
                                                                    searchResults: (_context1, { data: data1 })=>this._sort([
                                                                            ...data1
                                                                        ])
                                                                })
                                                            },
                                                            onError: {
                                                                target: 'idle',
                                                                actions: [
                                                                    '_rejectSearch'
                                                                ]
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    },
                                    resultsHidden: {
                                        id: 'resultsHidden',
                                        entry: [
                                            '_onResultsHidden'
                                        ],
                                        on: {
                                            showResults: [
                                                {
                                                    target: 'searching',
                                                    cond: (): boolean =>this.searchOnOpen
                                                },
                                                'results'
                                            ]
                                        }
                                    },
                                    error: {}
                                }
                            }
                        }
                    }
                }
            }).withConfig({
                guards: {
                    shouldResubmit: (context1, event1)=>this.args.shouldResubmit?.(context1, event1) ?? false
                },
                services: {
                    performSearch: async (context1, event1?: Extract<Event, {
                        type: 'submit';
                    }>)=>this.searchTask.perform(context1.searchTerm, event1),
                    performLoadingMore: async (context1)=>this.loadMoreTask.perform(context1.searchTerm, context1.searchResults),
                    performAdd: async (_context1, { item: item1 }: Extract<Event, {
                        type: 'submit';
                    }>)=>this.args.onPerformAdd?.(item1),
                    performEnter: async (context1)=>{
                        const { searchResults: searchResults1, searchTerm: searchTerm1, selectedItem: selectedItem1 } = context1;
                        const value1 = searchResults1.find((result1)=>result1 === selectedItem1);
                        return this.args.onEnterPressed?.(value1 || searchTerm1, searchResults1);
                    },
                    validateInput: async (context1)=>this.validationTask.perform(context1.searchTerm)
                },
                actions: {
                    _checkSelected: ()=>{
                        if (this.selectFirstResult) {
                            this.statechart.send('selectNext');
                        }
                    },
                    _selectPrevious (context1) {
                        let { selectedItem: selectedItem1, searchResults: searchResults1 } = context1, index1 = searchResults1.indexOf(selectedItem1);
                        if (!selectedItem1) {
                            context1.selectedItem = searchResults1[searchResults1.length - 1];
                        } else {
                            context1.selectedItem = index1 > 0 ? searchResults1[index1 - 1] : searchResults1[searchResults1.length - 1];
                        }
                        scrollToElement('[data-typeahead-item-selected]', '[data-typeahead-results-container]');
                    },
                    _selectNext (context1) {
                        let { selectedItem: selectedItem1, searchResults: searchResults1 } = context1, index1 = searchResults1.indexOf(selectedItem1);
                        if (!selectedItem1) {
                            context1.selectedItem = searchResults1[0];
                        } else if (index1 < searchResults1.length - 1) {
                            context1.selectedItem = searchResults1[index1 + 1];
                        } else {
                            context1.selectedItem = searchResults1[0];
                        }
                        scrollToElement('[data-typeahead-item-selected]', '[data-typeahead-results-container]');
                    },
                    _setItemToNull (context1) {
                        context1.selectedItem = null;
                    },
                    _blur (_context1, { event: event1 }: Extract<Event, {
                        type: 'pressEscape';
                    }>) {
                        if (event1.target) {
                            (event1.target as HTMLElement).blur();
                        }
                    },
                    _clearState: assign({
                        searchResults: [],
                        searchTerm: ()=>null,
                        selectedItem: null
                    }),
                    _onResultsHidden: ()=>{
                        this.args.onResultsHidden?.();
                    },
                    _rejectSearch: (_context1, { data: data1 }: DoneEventObject)=>{
                        this.errors.log(data1);
                        this.args.onRejectSearch?.(data1);
                    },
                    _rejectAdd: (_context1, { data: data1 }: DoneEventObject)=>{
                        this.errors.log(data1);
                        this.args.onRejectAdd?.(data1);
                    },
                    _rejectEnter: (_context1, { data: data1 }: DoneEventObject)=>{
                        this.errors.log(data1);
                        this.args.onRejectEnter?.(data1);
                    }
                }
            }),
            onTransition: ({ context: { searchResults: searchResults1 } })=>{
                this.searchResults = searchResults1;
            }
        }));
    get searchDidError(): boolean {
        return !!this.statechart.state?.matches({
            changed: {
                search: 'error'
            }
        });
    }
    get searchDidComplete(): boolean {
        return !!this.statechart.state?.matches({
            changed: {
                search: 'results'
            }
        });
    }
    get searchIsInProgress(): boolean {
        return !!this.statechart.state?.matches({
            changed: {
                search: 'searching'
            }
        });
    }
    get enterDidError(): boolean {
        return !!this.statechart.state?.matches({
            changed: {
                validity: 'remoteInvalid'
            }
        });
    }
    get enterIsInProgress(): boolean {
        return !!this.statechart.state?.matches({
            changed: {
                validity: 'busy'
            }
        });
    }
    get showValidationError(): boolean {
        return !!this.statechart.state?.matches({
            changed: {
                validity: {
                    invalid: 'shown'
                }
            }
        });
    }
    get addIsInProgress(): boolean {
        return !!this.statechart.state?.matches({
            changed: {
                search: 'adding'
            }
        });
    }
    get searchOnOpen(): boolean {
        return this.args.searchOnOpen ?? false;
    }
    get showSearchUI() {
        return (this.searchIsInProgress || this.searchDidError || this.searchDidComplete);
    }
    get disableInput() {
        return this.enterIsInProgress || this.addIsInProgress;
    }
    private sortBy(items1: Item[], sortByParam1: string) {
        return items1.sortBy(sortByParam1);
    }
    private _sort(items1: Item[]) {
        if (this.args.sort) return this.args.sort(items1);
        if (this.args.sortByParam) return this.sortBy(items1, this.args.sortByParam);
        return items1;
    }
    private searchTask = task({
        restartable: true
    }, async (searchTerm1: string, event1?: object)=>{
        await timeout(this.debounceTimeout);
        const searchResults1 = await this.args.onPerformSearch?.(searchTerm1, event1);
        if (!searchResults1) {
            return [];
        }
        if (searchResults1.toArray) {
            return searchResults1.slice();
        }
        return searchResults1;
    });
    private loadMoreTask = task({
        restartable: true
    }, async (searchTerm1: string | null, searchResults1: Item[])=>{
        const more1 = await this.args.loadMoreResults?.(searchResults1, searchTerm1);
        if (more1?.toArray) {
            return [
                ...searchResults1,
                ...more1.slice()
            ];
        }
        return [
            ...searchResults1,
            ...more1
        ];
    });
    private validationTask = task({
        restartable: true
    }, async (searchTerm1: string | null)=>{
        if (this.args.Validations) {
            const schema1 = object().shape({
                searchTerm: this.args.Validations!
            });
            await schema1.validate({
                searchTerm: searchTerm1
            }, {
                abortEarly: false
            });
        }
    });
    @action
    enter(): void {
        this.statechart.send('pressEnter');
    }
    @action
    handleKeyDown(event1: KeyboardEvent): void {
        // https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key/Key_Values
        switch(event1.key){
            case 'Enter':
                this.statechart.send('pressEnter');
                event1.preventDefault();
                break;
            case 'Tab':
                this.statechart.send('clearValue');
                break;
            case 'Escape':
                this.statechart.send('pressEscape', {
                    event: event1
                });
                break;
            case 'ArrowDown':
                this.statechart.send('selectNext');
                event1.stopPropagation();
                event1.preventDefault();
                break;
            case 'ArrowUp':
                this.statechart.send('selectPrevious');
                event1.stopPropagation();
                event1.preventDefault();
                break;
        }
    }
    @action
    type(value1: string | null): void {
        this.statechart.send('type', {
            searchTerm: value1
        });
    }
    @action
    search(value1: string | null): void {
        this.statechart.send('search', {
            searchTerm: value1
        });
    }
    @action
    selectItem(item1: Item): void {
        this.statechart.send('select', {
            item: item1
        });
    }
    @action
    resetSelection(): void {
        this.statechart.send('deselect');
    }
    @action
    submitItem(item1: Item): void {
        this.statechart.send('submit', {
            item: item1
        });
    }
    @action
    hideResults(): void {
        this.statechart.send('hideResults');
    }
    @action
    showResults(): void {
        this.statechart.send('showResults');
    }
    @action
    focusInput(): void {
        const input1: HTMLElement | null = document.querySelector(`input[data-input="${this.typeaheadInputId}"]`);
        if (input1) {
            input1.focus();
        }
    }
    @action
    _loadMoreResults() {
        this.statechart.send('loadMore');
    }
    static{
        template(`
    {{yield
      (hash
        ui=(hash
          input=(component
            TypeaheadInput
            value=this.searchTerm
            disabled=this.disableInput
            querySelector=this.typeaheadInputId
            onInput=this.type
            onFocus=this.showResults
            onKeyDown=this.handleKeyDown
          )
          dropdown=(component
            TypeaheadDropdown
            isOpen=this.showSearchUI
            searchResults=this.searchResults
            searchDidComplete=this.searchDidComplete
            loadMoreResults=this._loadMoreResults
            selectedItem=this.selectedItem
            displayLoadingUI=this.searchIsInProgress
            clickOutsideExceptSelector=this.inputSelector
            onDeselectItem=this.resetSelection
            onSelectItem=this.selectItem
            onSubmitItem=this.submitItem
            onBackgroundClicked=this.hideResults
          )
        )
        actions=(hash
          enter=this.enter
          type=this.type
          select=this.selectItem
          search=this.search
          onDeselectItem=this.resetSelection
          onSelectItem=this.selectItem
          onSubmitItem=this.submitItem
          hideResults=this.hideResults
          showResults=this.showResults
          focusInput=this.focusInput
        )
        state=(hash
          searchTerm=this.searchTerm
          searchIsInProgress=this.searchIsInProgress
          searchDidError=this.searchDidError
          searchDidComplete=this.searchDidComplete
          searchResults=this.searchResults
          enterIsInProgress=this.enterIsInProgress
          enterDidError=this.enterDidError
          showValidationError=this.showValidationError
          showSearchUI=this.showSearchUI
          disableInput=this.disableInput
          inputSelector=this.inputSelector
          selectedItem=this.selectedItem
        )
      )
      this
    }}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
