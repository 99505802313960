import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { resolve } from 'rsvp';
import { Machine } from 'xstate';
import { hash } from '@ember/helper';
import { action } from '@ember/object';
import didInsert from '@ember/render-modifiers/modifiers/did-insert';
import didUpdate from '@ember/render-modifiers/modifiers/did-update';
import { useMachine } from 'ember-statecharts';
import { matchesState } from '../utils/statecharts.ts';
interface StateSchema {
    states: {
        idle: {
        };
        disabled: {
        };
        busy: {
        };
        success: {
        };
        error: {
        };
    };
}
type StatechartEvent = {
    type: 'CLICK';
    event: Event;
} | {
    type: 'DISABLE';
} | {
    type: 'ACTIVATE';
} | {
    type: 'RESOLVE';
    result: any;
} | {
    type: 'REJECT';
    error: any;
};
interface TriggerSignature {
    Args: {
        onClick: Function;
        disabled?: boolean;
        onSuccess?: Function;
        onError?: Function;
    };
    Blocks: {
        default: [{
                state: {
                    isBusy: boolean;
                    isDisabled: boolean;
                };
                actions: {
                    click: Trigger['handleButtonClick'];
                };
            }];
    };
}
export default class Trigger extends Component<TriggerSignature> {
    constructor(owner1: unknown, args1: TriggerSignature['Args']){
        super(owner1, args1);
        if (this.args.disabled) {
            this.statechart.send('DISABLE');
        } else {
            this.statechart.send('ACTIVATE');
        }
    }
    @matchesState('busy')
    isBusy: boolean;
    @matchesState('disabled')
    isInDisabledState: boolean;
    get isDisabled() {
        return this.isBusy || this.isInDisabledState;
    }
    statechart = useMachine(this, ()=>({
            machine: Machine<{
            }, StateSchema, StatechartEvent>({
                initial: 'idle',
                states: {
                    idle: {
                        on: {
                            CLICK: 'busy',
                            DISABLE: 'disabled'
                        }
                    },
                    disabled: {
                        on: {
                            ACTIVATE: 'idle'
                        }
                    },
                    busy: {
                        entry: [
                            'triggerOnClick'
                        ],
                        on: {
                            RESOLVE: 'success',
                            REJECT: 'error',
                            DISABLE: 'disabled',
                            ACTIVATE: 'idle'
                        }
                    },
                    success: {
                        entry: [
                            'handleSuccess'
                        ],
                        on: {
                            ACTIVATE: 'idle'
                        }
                    },
                    error: {
                        entry: [
                            'handleError'
                        ],
                        on: {
                            ACTIVATE: 'idle'
                        }
                    }
                }
            }).withConfig({
                actions: {
                    triggerOnClick: (_1, { event: event1 }: Extract<StatechartEvent, {
                        type: 'CLICK';
                    }>)=>{
                        return resolve(event1).then((event1)=>this.args.onClick(event1)).then(()=>this.resolve()).catch((error1: any)=>this.reject(error1));
                    },
                    handleSuccess: ()=>{
                        return resolve().then(()=>this.args.onSuccess?.()).then(()=>this.enableButtonAgain());
                    },
                    handleError: (_1, { error: error1 }: Extract<StatechartEvent, {
                        type: 'REJECT';
                    }>)=>{
                        return resolve().then(()=>this.args.onError?.(error1)).then(()=>this.enableButtonAgain());
                    }
                }
            })
        }));
    enableButtonAgain(): any {
        return this.statechart.send('ACTIVATE');
    }
    resolve(): any {
        return this.statechart.send('RESOLVE');
    }
    reject(error1: any): any {
        return this.statechart.send('REJECT', {
            error: error1
        });
    }
    @action
    handleButtonClick(event1: Event) {
        if (!this.isDisabled) {
            return this.statechart.send('CLICK', {
                event: event1
            });
        }
        return;
    }
    @action
    handleDisabled() {
        if (this.args.disabled) {
            this.statechart.send('DISABLE');
        } else {
            this.statechart.send('ACTIVATE');
        }
    }
    static{
        template(`
    <div
      class='hidden'
      {{didInsert this.handleDisabled}}
      {{didUpdate this.handleDisabled @disabled}}
    ></div>
    {{yield
      (hash
        state=(hash isBusy=this.isBusy isDisabled=this.isDisabled)
        actions=(hash click=this.handleButtonClick)
      )
    }}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        Trigger: typeof Trigger;
        trigger: typeof Trigger;
    }
}
