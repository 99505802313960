import { template } from "@ember/template-compiler";
import or from 'ember-truth-helpers/helpers/or';
import ModalConfirmation from '../modal/confirmation.gts';
interface ModalsConfirmDiscardSignature {
    Args: {
        header?: string;
        message?: string;
        confirmText?: string;
        cancelText?: string;
        isOpen: boolean;
        onConfirm?: Function;
        onCancel?: Function;
        close?: Function;
        testSelector?: string;
    };
}
const ModalConfirmDiscard: TemplateOnlyComponent<ModalsConfirmDiscardSignature> = template(`
  <ModalConfirmation
    @header={{or @header "Unsaved changes"}}
    @message={{or @message "You are about to leave this page without saving your changes. Do you want to proceed?"}}
    @confirmText={{or @confirmText "Discard changes"}}
    @cancelText={{or @cancelText "Cancel"}}
    @isOpen={{@isOpen}}
    @onConfirm={{@onConfirm}}
    @onCancel={{@onCancel}}
    @close={{@close}}
    @testSelector={{or @testSelector "confirm-discard"}}
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ModalConfirmDiscard;
