import { helper } from '@ember/component/helper';

import { isDark } from '../utils/color.ts';

export interface IsDarkSignature {
  Args: {
    Positional: [string];
    Named: {
      bgColor?: string;
      threshold?: number;
    };
  };
  Return: boolean;
}

function isDarkHelper(
  [value]: [string],
  options: { bgColor?: string; threshold?: number }
) {
  if (value) {
    return isDark(value, options);
  }
  return false;
}

export default helper<IsDarkSignature>(isDarkHelper);
