import { cached } from 'tracked-toolbox';

import Service from '@ember/service';

export default class MediaDevicesService extends Service {
  @cached
  get mediaDevices(): MediaDevices {
    return navigator.mediaDevices;
  }

  getUserMedia(constraints: MediaStreamConstraints): Promise<MediaStream> {
    return this.mediaDevices.getUserMedia(constraints);
  }
}
