import Component from '@glimmer/component';

import { action } from '@ember/object';

import type { DropdownOption } from '../dropdown.ts';

const UP_KEY_CODE = 38;
const DOWN_KEY_CODE = 40;

interface DropdownTriggerSignature {
  Args: {
    onSelectError?: (error: Error) => void;
    stopPropagation?: boolean;
    guid?: number | string;
    selected?: any;
    show?: Function;
    toggleDropdown?: Function;
    onClear?: Function;
    onSelect?: Function;
    icon?: string;
    dropdownId?: string;
    testSelector?: string;
    native?: boolean;
    isOpen?: boolean;
    dropdownOptions?: DropdownOption[];
  };
  Blocks: {
    default: [unknown];
  };
  Element: HTMLElement;
}
export default class DropdownTriggerComponent extends Component<DropdownTriggerSignature> {
  get triggerIcon() {
    return this.args.selected?.icon ?? this.args.icon;
  }

  @action
  toggle(event: PointerEvent) {
    if (this.args.stopPropagation) {
      event.stopPropagation();
    }

    return this.args.toggleDropdown?.();
  }

  @action
  async select(inputEvent: { target: HTMLInputElement }) {
    try {
      const {
        target: { value }
      } = inputEvent;
      const option = this.args.dropdownOptions?.find(d => d.value === value);
      return await this.args.onSelect?.(option ?? value);
    } catch (error) {
      this.args.onSelectError?.(error);
    }
  }

  @action
  clear(event?: MouseEvent) {
    event?.preventDefault();
    event?.stopPropagation();
    return this.args.onClear?.();
  }

  @action
  onKeyPress(event: KeyboardEvent) {
    switch (event.which || event.keyCode) {
      case UP_KEY_CODE:
      case DOWN_KEY_CODE:
        event.preventDefault();
        if (!this.args.native) {
          this.args.show?.();
        }
        break;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Dropdown::Trigger': typeof DropdownTriggerComponent;
    'dropdown/trigger': typeof DropdownTriggerComponent;
  }
}
