import { ValidationError } from 'yup';

import type Model from '@ember-data/model';
import { warn } from '@ember/debug';

type InvalidError = { name: string; message: string; errors: any[] };

import { FormServerValidationError } from '../../services/-errors-base.ts';
import {
  getAllUserMessagesOrDefault,
  getValidations,
  getErrors,
  toErrors
} from '../errors.ts';
import type FormObject from '../form-object.ts';

type Schema = {
  [x: string]: any;
};

function isEmberDataModel(model: any): model is Model {
  return model && typeof model === 'object' && 'currentState' in model;
}
function isEmberDataInvalidError(error: Error): error is InvalidError {
  return (
    error &&
    typeof error === 'object' &&
    'isAdapterError' in error &&
    'code' in error &&
    error.code === 'InvalidError'
  );
}

function copyServerValidationErrors(
  model: Model,
  formObject: FormObject<any>,
  errors: {
    attribute: keyof Model;
    message: string;
  }[]
): (ValidationError | Error)[] {
  return errors.map(({ attribute, message }) => {
    if (attribute in model || attribute in formObject) {
      return new ValidationError(
        message,
        model[attribute],
        // @ts-ignore
        attribute,
        'required'
      );
    }
    return new FormServerValidationError(message);
  });
}

function copyServerValidationErrorsFromError(
  model: Model,
  formObject: FormObject<any>,
  adapterError: (InvalidError | TypeError) & { errors?: any[] }
) {
  if (typeof adapterError !== 'object' || !adapterError.errors) {
    return [];
  }

  const modelClass = model.constructor as unknown as Model;
  // @ts-ignore
  // prettier-ignore
  const serializer = model.store.serializerFor(modelClass.modelName) as DS.JSONAPISerializer;

  const errors = serializer.extractErrors(
    model.store,
    modelClass,
    adapterError,
    model.id
  ) as { [attribute in keyof Model]: string[] };

  return copyServerValidationErrors(
    model,
    formObject,
    Object.keys(errors).reduce((acc, attribute: keyof Model) => {
      const messages = errors[attribute];
      return [...acc, ...messages.map(message => ({ attribute, message }))];
    }, [])
  );
}

function getErrorsAndValidations(
  model: Model | Schema,
  formObject: FormObject<any>,
  error: Error
): Array<ValidationError | Error | string> {
  if (
    isEmberDataModel(model) &&
    (isEmberDataInvalidError(error) || error instanceof TypeError)
  ) {
    const modelErrors = model.get('errors').slice();
    const errors: ValidationError | Error[] =
      modelErrors.length > 0
        ? copyServerValidationErrors(model, formObject, modelErrors.slice())
        : copyServerValidationErrorsFromError(model, formObject, error);

    return errors.length > 0
      ? errors
      : toErrors<typeof error>(getErrors(error));
  } else {
    const validations = getValidations(error);
    return validations.length > 0
      ? validations
      : getAllUserMessagesOrDefault(error);
  }
}

export async function submitModel(
  model: Model | Schema,
  formObject: FormObject<any>,
  submit: Function,
  rollback: Function
) {
  try {
    return await submit();
  } catch (error) {
    const errors = getErrorsAndValidations(model, formObject, error);

    if (isEmberDataModel(model) && model.get('isNew')) {
      model.unloadRecord();
    } else {
      rollback();
    }

    throw errors;
  }
}

export function rollbackModelDefault(
  model: Model,
  formObject: FormObject<any>,
  _childFormObjects?: FormObject<any>[]
) {
  const { original } = formObject;

  // Rollback Ember-Data Attributes
  model.rollbackAttributes();

  // Rollback Ember-Data Relationships
  const belongsTo: { key: string; value: Model | null }[] = [];
  const hasMany: { key: string; value: Model[] }[] = [];
  Object.keys(original).forEach(key => {
    // @ts-ignore
    const meta = model.relationshipFor(key);
    if (meta?.kind === 'belongsTo') {
      belongsTo.push({ key, value: original[key] });
    } else if (meta?.kind === 'hasMany') {
      const value = original[key];
      hasMany.push({
        key,
        value: value.toArray ? value.slice() : [...value]
      });
    }
  });
  belongsTo.forEach(({ key, value }) => {
    if (isEmberDataModel(value) || value === null) {
      // @ts-ignore
      model[key] = value;
    } else {
      warn(
        `"${key}" is a belongs-to relationship of ${model} but is not a valid model or null`,
        false,
        { id: 'tangram-invalid-belongs-to-rollback' }
      );
    }
  });
  hasMany.forEach(({ key, value }) => {
    const isModelArray = value.every(item => isEmberDataModel(item));
    if (isModelArray) {
      // @ts-ignore
      model[key].splice(0, model[key].length);
      model[key].push(...value);
    } else {
      warn(
        `"${key}" is a has-many relationship of ${model} but does not contain models`,
        false,
        { id: 'tangram-invalid-has-many-rollback' }
      );
    }
  });
}

export function applyFormAndSubmit(
  applyFormObject: (
    formObject: FormObject<any>,
    childFormObjects?: FormObject<any>[]
  ) => Model,
  rollbackModel?: (
    model: Model,
    formObject: FormObject<any>,
    childFormObjects?: FormObject<any>[]
  ) => void
) {
  return function (
    _object: any,
    _property: string,
    descriptor: PropertyDescriptor
  ) {
    return {
      get() {
        const target = this;
        return async function (formObject: any, childFormObjects?: any) {
          const model = applyFormObject.bind(target)(
            formObject,
            childFormObjects
          );
          const rollback = (rollbackModel ?? rollbackModelDefault).bind(
            target,
            model,
            formObject,
            childFormObjects
          );
          const submit = () => descriptor.value.bind(target)(model);
          return submitModel(model, formObject, submit, rollback);
        };
      }
    };
  };
}
