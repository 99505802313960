import type {
  NewResource,
  Resource,
  ResourceIdentifier
} from 'ticketoffice-api';

import type Model from '@ember-data/model';

import { identity } from './json-api.ts';

export type ResourceOperations = {
  ops: ResourceOperation[];
};

export type ResourceOperation =
  | DeleteOperation
  | CreateOperation
  | UpdateOperation;
export type DeleteOperation = { action: 'delete'; data: ResourceIdentifier };
export type CreateOperation = { action: 'create'; data: NewResource };
/**
 * Supports only updating `extras` currently
 */
export type UpdateOperation = {
  action: 'update';
  data: Resource;
};

export function isDeleteOperation(
  op: ResourceOperation
): op is DeleteOperation {
  return op.action === 'delete';
}
export function isCreateOperation(
  op: ResourceOperation
): op is CreateOperation {
  return op.action === 'create';
}

export function isUpdateOperation(
  op: ResourceOperation
): op is UpdateOperation {
  return op.action === 'update';
}

export function deleteOperation(lineItem: Model): DeleteOperation {
  return { action: 'delete', data: identity(lineItem) };
}
export function createOperation(data: NewResource): CreateOperation {
  return { action: 'create', data };
}

export function updateOperation(data: Resource): UpdateOperation {
  return { action: 'update', data };
}
