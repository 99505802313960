import { template } from "@ember/template-compiler";
import Button from '../button.gts';
import type { ButtonDefaultBlock } from '../button.gts';
interface ButtonTextSignature {
    Args: {
        onClick?: Function;
        onSuccess?: Function;
        onError?: Function;
        text?: string;
        disabled?: boolean;
        testSelector?: string;
        classes?: string;
    };
    Blocks: {
        default: [ButtonDefaultBlock];
    };
    Element: HTMLElement;
}
const ButtonTextComponent: TemplateOnlyComponent<ButtonTextSignature> = template(`
  <Button
    @disabled={{@disabled}}
    @onClick={{@onClick}}
    @onSuccess={{@onSuccess}}
    @onError={{@onError}}
    @testSelector={{@testSelector}}
    class="focus:outline-none text-left {{@classes}}" as |c|>
    <c.ui.content ...attributes>
      {{#if (has-block)}}
        {{yield c}}
      {{else}}
        {{@text}}
      {{/if}}
    </c.ui.content>
  </Button>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ButtonTextComponent;
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        'Button::Text': typeof ButtonTextComponent;
        'button/text': typeof ButtonTextComponent;
    }
}
