import Component from '@glimmer/component';
import { compact } from 'lodash-es';

interface TypeaheadDropdownResultsSignature {
  Args: {
    searchResults?: any[];
  };
  Blocks: {
    default: [unknown, unknown];
    else: [];
  };
}

export default class TypeaheadDropdownResultsComponent extends Component<TypeaheadDropdownResultsSignature> {
  get items() {
    return compact(this.args.searchResults ?? []);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Typeahead::Dropdown::Results': typeof TypeaheadDropdownResultsComponent;
    'typeahead/dropdown/results': typeof TypeaheadDropdownResultsComponent;
  }
}
