import Component from '@glimmer/component';

interface FormBaseWrapperLabelSignature {
  Args: {};
  Element: HTMLLabelElement;
}

export default class FormBaseWrapperLabelComponent extends Component<FormBaseWrapperLabelSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Form::Base::Wrapper::Label': typeof FormBaseWrapperLabelComponent;
    'form/base/wrapper/label': typeof FormBaseWrapperLabelComponent;
  }
}
