import templateOnlyComponent from '@ember/component/template-only';

interface DropdownResultsSignature {
  Args: {};
  Blocks: {
    default: [unknown];
  };
}

const DropdownResultsComponent =
  templateOnlyComponent<DropdownResultsSignature>();

export default DropdownResultsComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Dropdown::Results': typeof DropdownResultsComponent;
    'dropdown/results': typeof DropdownResultsComponent;
  }
}
