import templateOnlyComponent from '@ember/component/template-only';

interface BadgeNeonSignature {
  Args: {
    color: string;
    label: string;

    icon?: string;
    isSmall?: boolean;
    isOnlyIcon?: boolean;
    onRemove?: Function;

    /**
     * Default: inline-flex
     */
    display?: string;
  };
  Element: HTMLElement;
}

const BadgeNeonComponent = templateOnlyComponent<BadgeNeonSignature>();

export default BadgeNeonComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Badge::Neon': typeof BadgeNeonComponent;
    'badge/neon': typeof BadgeNeonComponent;
  }
}
