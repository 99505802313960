export default class Jwt {
  header!: any;
  payload!: any;

  constructor(token: string | null) {
    if (token) {
      this.header = JSON.parse(atob(token.split('.')[0]));
      this.payload = JSON.parse(atob(token.split('.')[1]));
    } else {
      this.header = {};
      this.payload = {};
    }
  }

  get encoded() {
    return `${btoa(JSON.stringify(this.header))}.${btoa(
      JSON.stringify(this.payload)
    )}`;
  }

  get locationId() {
    return this.payload.to_loc;
  }

  get role() {
    return this.payload.aud;
  }
}
