import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import Attachment, { type AttachmentSignature } from './attachment.gts';
/**
 * @deprecated - Use <Attachment /> instead
 */ export default class UiAttachment extends Component<AttachmentSignature> {
    static{
        template(`
    <Attachment
      @displayTooltip={{@displayTooltip}}
      @isEnabled={{@isEnabled}}
      @anchor={{@anchor}}

      @onShowTooltip={{@onShowTooltip}}
      @onHideTooltip={{@onHideTooltip}}

      @testSelector={{@testSelector}}
      @classes={{@classes}}
      as |attachment|
    >
      {{yield attachment}}
    </Attachment>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
