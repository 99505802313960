import Service, { inject as service } from '@ember/service';

import type { BaseUserModel, Credentials } from './-session-base.ts';
import type BaseSessionService from './-session-base.ts';

type SetupServerResponse = {
  data: { svg: string; code: string; link: string };
};
type SetupResponse = SetupServerResponse['data'];

export default abstract class BaseTwoFactorAuthService extends Service {
  @service private session!: BaseSessionService<BaseUserModel>;

  protected abstract BASIC_AUTH_SETUP_KEY_ENDPOINT: string;
  protected abstract BASIC_AUTH_ACTIVATE_ENDPOINT: string;

  protected SETUP_KEY_ENDPOINT?: string;
  protected ACTIVATE_ENDPOINT?: string;
  protected DEACTIVATE_ENDPOINT?: string;

  private async fetchJson<T = void>({
    url,
    method,
    headers = {},
    body
  }: RequestInit & { url?: string }): Promise<T> {
    if (!url) {
      throw new Error('Invalid request url');
    }

    const response = await fetch(url, {
      method,
      headers: new Headers({
        ...headers,
        'Content-Type': 'application/vnd.api+json'
      }),
      body
    });

    if (!response.ok) {
      throw response;
    }

    return await response.json();
  }

  public async loadSetupKey(basicAuth?: Credentials): Promise<SetupResponse> {
    const request = basicAuth
      ? {
          url: this.BASIC_AUTH_SETUP_KEY_ENDPOINT,
          method: 'POST',
          body: JSON.stringify({
            data: {
              login: basicAuth.login,
              password: basicAuth.password,
              role: basicAuth.role
            }
          })
        }
      : {
          url: this.SETUP_KEY_ENDPOINT!,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${this.session.token}`
          }
        };
    const { data } = await this.fetchJson<SetupServerResponse>(request);
    return data;
  }

  public async activate(otp: string, basicAuth?: Credentials): Promise<void> {
    const request = basicAuth
      ? {
          url: this.BASIC_AUTH_ACTIVATE_ENDPOINT,
          method: 'POST',
          body: JSON.stringify({
            data: {
              login: basicAuth.login,
              password: basicAuth.password,
              role: basicAuth.role,
              otp
            }
          })
        }
      : {
          url: this.ACTIVATE_ENDPOINT,
          method: 'POST',
          headers: {
            Authorization: `Bearer ${this.session.token}`
          },
          body: JSON.stringify({ data: { otp } })
        };

    await this.fetchJson(request);
  }

  public async deactivate(otp: string): Promise<void> {
    const request = {
      url: this.DEACTIVATE_ENDPOINT,
      method: 'POST',
      headers: {
        Authorization: `Bearer ${this.session.token}`
      },
      body: JSON.stringify({ data: { otp } })
    };

    await this.fetchJson(request);
  }
}
