import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import type { ValidationError } from 'yup';
import type { ObjectSchema } from 'yup';
import type { ObjectShape } from 'yup/lib/object';
import { consume } from 'ember-provide-consume-context';
import type { FormObjectBase } from '../../utils/form-object.ts';
import type { FormSignature } from '../form.ts';
import Form from '../form.ts';
interface ChildFormSignature<T extends FormObjectBase = any> {
    Args: {
        f?: FormSignature<any>['Blocks']['default'][0];
        formObject: T;
        schema?: ObjectSchema<ObjectShape>;
        onSubmit?: Function;
        onSuccess?: Function;
        onError?: Function;
        onChanged?: Function;
        onUnchanged?: Function;
        onSubmitInvalid?: (errors: ValidationError[]) => void;
        isSubmittableUnchanged?: boolean;
        bubbleSubmit?: boolean;
    };
    Blocks: {
        default: FormSignature<T>['Blocks']['default'];
    };
}
export default class FormChildFormComponent extends Component<ChildFormSignature> {
    @consume('form')
    _f: ChildFormSignature['Args']['f'];
    get f() {
        const { f: f1 } = this.args;
        if (f1) {
            return f1;
        } else if (this._f) {
            return this._f;
        } else {
            throw new Error('Either args.f must be passed or this compnent needs to be a descendent of a `Form`');
        }
    }
    static{
        template(`
    <Form
      @register={{this.f.actions.register}}
      @unregister={{this.f.actions.unregister}}
      @parentDidTrySubmit={{this.f.state.parentDidTrySubmit}}
      @formObject={{@formObject}}
      @schema={{@schema}}
      @onSubmit={{@onSubmit}}
      @onSuccess={{@onSuccess}}
      @onError={{@onError}}
      @onChanged={{@onChanged}}
      @onUnchanged={{@onUnchanged}}
      @onSubmitInvalid={{@onSubmitInvalid}}
      @isSubmittableUnchanged={{@isSubmittableUnchanged}}
      @bubbleSubmit={{@bubbleSubmit}}
      as |f|
    >
      {{yield f}}
    </Form>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
