import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { htmlSafeSanitized } from '../helpers/html-safe-sanitized.ts';
interface LoadingSignature {
    Args: {
        type?: 'ring' | 'dots';
        backgroundColor?: string;
        ringColor?: string;
    };
}
export default class Loading extends Component<LoadingSignature> {
    get isRingType() {
        const { type: type1 } = this.args;
        return !type1 || type1 === 'ring';
    }
    get ringStyles() {
        if (!this.args.ringColor) return null;
        return htmlSafeSanitized([
            `border-top-color: ${this.args.ringColor}`
        ]);
    }
    get backgroundStyles() {
        if (!this.args.backgroundColor) return null;
        return htmlSafeSanitized([
            `border-color: ${this.args.backgroundColor}`
        ]);
    }
    static{
        template(`
    {{#if this.isRingType}}
      <div class="lds-ring" data-test-loading>
        <div class="lds-ring_background" style={{this.backgroundStyles}}></div>
        <div class="lds-ring_color" style={{this.ringStyles}}></div>
        <div class="lds-ring_color" style={{this.ringStyles}}></div>
        <div class="lds-ring_color" style={{this.ringStyles}}></div>
        <div class="lds-ring_color" style={{this.ringStyles}}></div>
      </div>
    {{else}}
      <div class="lds-dots" data-test-loading></div>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
