import type {
  ExtractFormProps,
  ExtractFormValue,
  FormContext
} from './-control-base.ts';
import FormBaseComponent from './-control-base.ts';
import type { FormTheme } from './theme.ts';

export type SelectProperty =
  | null
  | undefined
  | boolean
  | number
  | string
  | Exclude<object, Function | any[]>;

interface Signature<
  Context extends FormContext,
  Property extends ExtractFormProps<Context, SelectProperty>,
  Value
> {
  Args: {
    f: Context;
    property: Property;
    value?: Value;
    label?: string;
    testSelector?: string;
    hint?: string;
    theme?: FormTheme;
    labelIcon?: string;

    onValueChanged?: (value: any) => void;
    optionsTransform?: Function;
    getCollection?: Function;
    sort?: Function;

    collection?: any[];
    inline?: boolean;
    disabled?: boolean;
    sameWidth?: boolean;
    icon?: string;
    native?: boolean;
    nativeFilter?: boolean;
    clearable?: boolean;
    recordsCount?: number | null;
    collectionName?: string;
    hasSearch?: boolean;
    small?: boolean;
    isBusy?: boolean;
    h?: string;
    wrapperWidth?: string;
    sortByParam?: string;
    placeholder?: string;
    contentClasses?: string;
  };
  Blocks: {
    default: [];
  };
  Element: HTMLDivElement;
}

export default class FormSelect<
  Context extends FormContext,
  Property extends ExtractFormProps<Context, SelectProperty>,
  Value extends ExtractFormValue<Context, Property>
> extends FormBaseComponent<
  Signature<Context, Property, Value>,
  SelectProperty
> {
  MINIMUM_SEARCHABLE_ITEMS = 50;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Form::Select': typeof FormSelect;
  }
}
