import { tracked } from '@glimmer/tracking';

import Service from '@ember/service';
import Ember from 'ember';

export default class BaseWindowService extends Service {
  @tracked location!: Location | { href: string };

  init(): void {
    super.init();
    this.location = Ember.testing ? { href: '' } : window.location;
  }

  open(...args: any[]): Window | null {
    if (arguments.length === 1) {
      return window.open(args[0], '_blank');
    } else {
      return window.open(...args);
    }
  }

  get fs(): FullStory {
    return Ember.testing || !window.FS
      ? { identify() {}, consent() {} }
      : window.FS;
  }

  get profiler(): Window['MiniProfiler'] {
    return window.MiniProfiler ? window.MiniProfiler : { pageTransition() {} };
  }

  get hostname(): string {
    return window.location.hostname;
  }

  get subdomain(): string {
    const domain = this.hostname.split('.');
    return domain[0];
  }
}
