import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import sum from 'ember-math-helpers/helpers/sum';
import eq from 'ember-truth-helpers/helpers/eq';
import not from 'ember-truth-helpers/helpers/not';
import Regex from '../../../../lib/regex.ts';
import type { DropdownOption } from '../../../dropdown.ts';
interface Signature {
    Args: {
        dropdownOptions: DropdownOption[];
        filterText?: string;
        selected?: DropdownOption;
        testSelector?: string;
        placeholder?: string;
        clearable?: boolean;
    };
}
export default class NativeOptionsComponent extends Component<Signature> {
    get dropdownOptions() {
        const { dropdownOptions: dropdownOptions1, filterText: filterText1 } = this.args;
        if (filterText1?.length > 0) {
            const regex1 = Regex.toRegexp(filterText1, {
                flags: 'i'
            });
            return dropdownOptions1.filter((option1)=>regex1.test(option1.label));
        }
        return dropdownOptions1;
    }
    get isNotSelectedInDropdownOptions() {
        const { selected: selected1 } = this.args;
        return (selected1 && !this.dropdownOptions.find(({ value: value1 })=>selected1.value === value1));
    }
    static{
        template(`
    {{#if @selected}}
      {{#if this.isNotSelectedInDropdownOptions}}
        <option
          selected
          value={{@selected.value}}
          disabled={{@selected.disabled}}
          data-test-dropdown-item-index="0"
          data-test-dropdown-item-label={{@selected.label}}
          data-test-dropdown-selected={{@testSelector}}>
          {{@selected.label}}
        </option>
      {{/if}}
    {{else if @placeholder}}
      <option value={{undefined}} disabled selected>
        {{@placeholder}}
      </option>
    {{/if}}
    {{#if @clearable}}
      <option value={{undefined}} selected={{not @placeholder @selected}}></option>
    {{/if}}
    {{#each this.dropdownOptions as |d index|}}
      {{#if d.divider}}
        <option disabled>--------------</option>
      {{else}}
        <option
          value={{d.value}}
          selected={{eq @selected.value d.value}}
          disabled={{d.disabled}}
          data-test-dropdown-item-index={{sum index (if this.isNotSelectedInDropdownOptions 1 0)}}
          data-test-dropdown-item-label={{d.label}}
          data-test-dropdown-selected={{if (eq @selected.label d.label) @testSelector}}>
          {{d.label}}
        </option>
      {{/if}}
    {{/each}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
