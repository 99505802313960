import { template } from "@ember/template-compiler";
interface ModalFooterSignature {
    Args: {
    };
    Blocks: {
        default: [];
    };
    Element: HTMLDivElement;
}
const ModalFooterComponent: TemplateOnlyComponent<ModalFooterSignature> = template(`
  <div class="flex items-center justify-end p-3 border-t border-gray-200 gap-3" ...attributes>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ModalFooterComponent;
