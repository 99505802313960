export default class Engine {
  private _tick: FrameRequestCallback;
  private rafId?: number;

  constructor(callback: Function) {
    this._tick = this.tick.bind(this, callback);
  }

  start() {
    this.rafId = requestAnimationFrame(this._tick);
  }

  tick(callback: Function, timestamp: DOMHighResTimeStamp) {
    callback(timestamp);

    this.rafId = requestAnimationFrame(this._tick);
  }

  stop() {
    cancelAnimationFrame(this.rafId!);
  }
}
