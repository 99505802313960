import { template } from "@ember/template-compiler";
import type { ComponentLike } from '@glint/template';
import ModalHeaderBase from './header/base.gts';
import type ModalHeaderSubtitle from './header/subtitle.gts';
interface ModalHeaderSignature {
    Args: {
        close?: Function;
        textSize?: string;
        closeable?: boolean;
        closeComponent?: ComponentLike<{
            Args: {
                onClick: Function;
            };
            Element: any;
        }>;
    };
    Blocks: {
        default: [{
                ui: {
                    subtitle: typeof ModalHeaderSubtitle;
                };
            }];
    };
    Element: HTMLElement;
}
const ModalHeaderComponent: TemplateOnlyComponent<ModalHeaderSignature> = template(`
  <ModalHeaderBase
    @textSize={{@textSize}}
    @closeable={{@closeable}}
    @closeComponent={{@closeComponent}}
    @close={{@close}}
    ...attributes
    as |header|>
    {{yield header}}
  </ModalHeaderBase>

`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ModalHeaderComponent;
