import { template } from "@ember/template-compiler";
import type { ModalContentBaseSignature } from './content/base.gts';
import ModalContentBase from './content/base.gts';
interface ModalContentSignature {
    Args: {
        close?: Function;
        testSelector?: string;
        isSmall?: boolean;
        isFullscreen?: boolean;
    };
    Blocks: {
        default: [ModalContentBaseSignature['Blocks']['default'][0]];
    };
    Element: ModalContentBaseSignature['Element'];
}
const ModalContent: TemplateOnlyComponent<ModalContentSignature> = template(`
  <ModalContentBase
    @close={{@close}}
    @isSmall={{@isSmall}}
    @isFullscreen={{@isFullscreen}}
    @testSelector={{@testSelector}}
    ...attributes
    as |content|>
    {{yield content}}
  </ModalContentBase>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ModalContent;
