import { getOwner } from '@ember/application';
import { get } from '@ember/object';
import Service from '@ember/service';

export default class ConfigService extends Service {
  private get environment() {
    return getOwner(this).resolveRegistration('config:environment');
  }

  getValue(path: string, defaultValue?: any): any {
    return get(this.environment, path) === undefined
      ? defaultValue
      : get(this.environment, path);
  }
}
declare module '@ember/service' {
  interface Registry {
    config: ConfigService;
  }
}
