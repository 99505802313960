import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import sortBy from 'lodash-es/sortBy';
import type { DoneEventObject } from 'xstate';
import { assign, Machine } from 'xstate';

import { get } from '@ember/object';
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { inject as service } from '@ember/service';
import { isNone } from '@ember/utils';
import { isPresent } from '@ember/utils';

import { useMachine } from 'ember-statecharts';

import type ConfigService from '../services/config.ts';
import type EnvironmentService from '../services/environment.ts';
import escapeRegExp from '../utils/escape-regex.ts';
import { matchesState } from '../utils/statecharts.ts';
import DropdownContentItem from './dropdown/content-item.ts';
import DropdownTrigger from './dropdown/trigger.ts';

interface StateContext {
  searchTerm: string;
  isLocal: boolean;
}

interface StateSchema {
  states: {
    setup: {};
    closed: {};
    opened: {
      states: {
        loading: {};
        loaded: {
          states: {
            idle: {};
            debounceSearch: {};
            searching: {};
          };
        };
        error: {};
      };
    };
    native: {
      states: {
        loading: {};
        loadingMore: {};
        loaded: {};
        error: {};
      };
    };
  };
}

type StateEvent =
  | { type: 'TYPE'; searchTerm: string }
  | { type: 'REFRESH' }
  | { type: 'RESET' }
  | { type: 'CLEAR' }
  | { type: 'LOAD_MORE' }
  | { type: 'OPEN' }
  | { type: 'CLOSE' }
  | { type: 'PRESS_ENTER' }
  | { type: 'PRESS_ESCAPE' }
  | { type: 'SELECT_NEXT' }
  | { type: 'SELECT_PREVIOUS' };

const TAB_KEY_CODE = 9;
const ENTER_KEY_CODE = 13;
const ESCAPE_KEY_CODE = 27;
const UP_KEY_CODE = 38;
const DOWN_KEY_CODE = 40;

type OptionValue = any;
type OptionIcon = string;
export type DropdownGetCollection = (
  items: any[],
  searchTerm: string
) => Promise<any[]>;
export type DropdownOption<T = OptionValue, U = OptionIcon> =
  | {
      label: string;
      value: T;
      icon?: U;
      triggerLabel?: string;
      disabled?: boolean;
      bgColor?: string | null;
      chipIcon?: string;
      divider?: false;
    }
  | {
      label: '';
      value: null;
      divider: true;
      triggerLabel?: string;
      disabled?: false;
      chipIcon?: string;
    };
export function isDropdownOption(obj: unknown): obj is DropdownOption {
  return (
    typeof obj === 'object' &&
    obj != null &&
    Object.keys(obj).includes('label') &&
    Object.keys(obj).includes('value')
  );
}

function getKeyValueOrDefault(
  key: string,
  value: any,
  defaultValue?: any
): any {
  return get(value, key) ?? defaultValue ?? value;
}

export function optionsTransform(options?: OptionsTransformArg) {
  const mapLabel = options && options.mapLabel;
  const mapValue = options && options.mapValue;
  return function (value: any): DropdownOption {
    if (isNone(value)) {
      return { label: '', value: null };
    }
    if (isDropdownOption(value)) {
      return value;
    }
    if (!options) {
      return { label: value, value };
    }

    let optionLabel = getKeyValueOrDefault(options.label || '', value);
    let optionValue = getKeyValueOrDefault(options.value || '', value);

    if (mapLabel) {
      optionLabel = mapLabel(optionLabel);
    }
    if (mapValue) {
      optionValue = mapValue(optionValue);
    }

    const option: DropdownOption = {
      label: optionLabel,
      value: optionValue
    };
    if (options.bgColor) {
      const optionBgColor = getKeyValueOrDefault(options.bgColor, value, false);
      return { ...option, bgColor: optionBgColor };
    }
    if (options.chipIcon) {
      option.chipIcon = options.chipIcon;
    }
    return option;
  };
}

export type OptionsTransformArg =
  | {
      label?: string;
      value?: string;
      mapLabel?: Function;
      mapValue?: Function;
      bgColor?: string;
      chipIcon?: string;
    }
  | undefined;

export interface UiDropdownTrigger {
  dropdownId: string;
  isOpen: boolean;
  isDisabled: boolean;
  displayClearing: boolean;
  selected?: DropdownOption;
  placeholder?: string;
  testSelector?: string;
  isBusy?: boolean;
  icon?: string;
  toggleDropdown: () => void;
  onClear: () => void;
}

interface DropdownSignature {
  Args: {
    value?: OptionValue;
    selected?: OptionValue;
    collection?: DropdownOption[];
    recordsCount?: number;
    onSelect?: Function;
    onShow?: Function;
    onHide?: Function;
    onClear?: Function;
    onBackgroundClicked?: Function;
    onFilter?: Function;
    optionsTransform?: Function;
    getCollection?: DropdownGetCollection;
    sort?: Function;
    sortByParam?: string;
    native?: boolean;
    clearable?: boolean;
    isDisabled?: boolean;
    isDropUp?: boolean;
    sameWidth?: boolean;
    isBusy?: boolean;
    displayDropdown?: boolean;
    property?: string;
    testSelector?: string;
    icon?: string;
    dropdownId?: string;
    triggerComponentName?: string;
    contentItemComponentName?: string;
  };
  Blocks: {
    // TODO: Add types
    default: [
      {
        ui: any;
        actions: any;
        state: any;
      }
    ];
  };
}
export default class Dropdown extends Component<DropdownSignature> {
  @service() liquidFireTransitions!: null | {
    waitUntilIdle: () => Promise<void>;
  };
  @service('config') configService!: ConfigService;
  @service() private environment!: EnvironmentService;

  @tracked preselectedValue: OptionValue;

  // component cache of selections if cant be derived from @value and @collection
  // for instance if being used without @collection set
  @tracked selectedItem?: DropdownOption;
  @tracked preselectedItem?: DropdownOption;

  @tracked _items: any[] = [];
  @tracked contentVisible: boolean = false;

  statechart = useMachine(this, () => ({
    machine: Machine<StateContext, StateSchema, StateEvent>({
      initial: 'setup',
      states: {
        setup: {
          on: {
            OPEN: [{ target: 'native', cond: 'isNative' }, 'opened'],
            CLOSE: [{ target: 'native', cond: 'isNative' }, 'closed']
          }
        },
        closed: {
          on: {
            OPEN: 'opened',
            REFRESH: {
              target: 'closed',
              actions: 'resetItems'
            },
            RESET: {
              target: 'closed',
              actions: 'hardReset',
              cond: 'shouldReset'
            },
            CLEAR: {
              target: 'closed',
              actions: 'clear'
            }
          }
        },
        opened: {
          initial: 'loading',
          on: {
            CLOSE: 'closed',
            PRESS_ESCAPE: {
              target: 'closed',
              actions: ['hide', 'focusTrigger']
            },
            CLEAR: {
              target: 'closed',
              actions: 'clear'
            },
            TYPE: {
              target: '.loaded.debounceSearch',
              actions: assign({
                searchTerm: (_c, { searchTerm }) => searchTerm
              })
            }
          },
          states: {
            loading: {
              id: 'loading',
              invoke: {
                src: 'performLoadCollection',
                onDone: 'loaded',
                onError: 'error'
              }
            },
            loaded: {
              initial: 'idle',
              states: {
                idle: {
                  entry: 'setItems',
                  on: {
                    REFRESH: {
                      target: '#loading',
                      actions: 'resetItems'
                    },
                    LOAD_MORE: {
                      target: '#loading',
                      cond: 'hasMoreItems'
                    },
                    SELECT_NEXT: {
                      target: 'idle',
                      actions: 'preselectNext',
                      cond: 'hasMoreThanOneItem'
                    },
                    SELECT_PREVIOUS: {
                      target: 'idle',
                      actions: 'preselectPrevious',
                      cond: 'hasMoreThanOneItem'
                    },
                    PRESS_ENTER: {
                      target: 'idle',
                      cond: 'hasPreselected',
                      actions: 'selectPreselected'
                    }
                  }
                },
                debounceSearch: {
                  after: {
                    [this.environment.isTest ? 0 : 400]: [
                      {
                        target: 'searching',
                        cond: ({ isLocal }) => isLocal
                      },
                      {
                        target: '#loading',
                        actions: 'resetItems'
                      }
                    ]
                  }
                },
                searching: {
                  invoke: {
                    src: 'performSearch',
                    onDone: 'idle',
                    onError: '#error'
                  }
                }
              }
            },
            error: {
              id: 'error',
              on: {
                LOAD_MORE: 'loading'
              }
            }
          }
        },
        native: {
          initial: 'loading',
          states: {
            loading: {
              invoke: {
                src: 'performLoadCollection',
                onDone: 'loadingMore',
                onError: 'error'
              }
            },
            loadingMore: {
              entry: ['setItems', 'loadMore'],
              on: {
                LOAD_MORE: [
                  {
                    target: 'loading',
                    cond: 'hasMoreItems'
                  },
                  'loaded'
                ]
              }
            },
            loaded: {
              on: {
                REFRESH: {
                  target: 'loading',
                  actions: 'resetItems'
                },
                PRESS_ENTER: {
                  target: 'loaded',
                  cond: 'hasPreselected',
                  actions: 'selectPreselected'
                },
                CLEAR: {
                  target: 'loaded',
                  actions: 'clear'
                }
              }
            },
            error: {
              on: {
                LOAD_MORE: 'loading'
              }
            }
          }
        }
      }
    })
      .withContext({
        searchTerm: '',
        isLocal: !this.args.getCollection
      })
      .withConfig({
        actions: {
          hide: () => this.hide(),
          loadMore: () => this.loadMore(),
          focusTrigger: () => {
            (
              document.querySelector(
                `[data-tether-target-id="${this.dropdownId}"]`
              ) as HTMLElement
            ).focus();
          },
          setItems: (_c, { data }: DoneEventObject) => {
            if (data) {
              if (typeof data.toArray === 'function') {
                this._items = [...data.slice()];
              } else {
                this._items = [...data];
              }
            }
          },
          resetItems: () => (this._items = []),
          hardReset: () => this.reset(),
          clear: () => {
            this.reset();
            this.args.onClear?.();
          },
          preselectNext: () => {
            const items = this._items;

            const curIndex = this.dropdownOptions
              .map(item => item.value)
              .indexOf(this.preselected?.value);

            const nextIndex =
              curIndex >= 0 && curIndex + 1 < items.length ? curIndex + 1 : 0;

            this.onPreselect(this.dropdownOptions[nextIndex]);
          },
          preselectPrevious: () => {
            const items = this._items;

            const curIndex = this.dropdownOptions
              .map(item => item.value)
              .indexOf(this.preselected?.value);

            const prevIndex = curIndex >= 1 ? curIndex - 1 : items.length - 1;

            this.onPreselect(this.dropdownOptions[prevIndex]);
          },
          selectPreselected: () => this.onSelectAndHide(this.preselected)
        },
        services: {
          performSearch: async ({ searchTerm }) => {
            const searchRegex = new RegExp(escapeRegExp(searchTerm), 'i');
            return this.args.collection?.filter(item => {
              if (typeof item === 'string') {
                return searchRegex.test(item);
              } else {
                // we expect an object with a label and value
                const { value, label } = item;
                return searchRegex.test(value) || searchRegex.test(label);
              }
            });
          },

          performLoadCollection: async ({ searchTerm, isLocal }) => {
            return isLocal
              ? this.args.collection
              : this.args.getCollection?.(this._items, searchTerm);
          }
        },
        guards: {
          isNative: () => this.args.native ?? false,
          hasMoreItems: () => this.hasMoreItems,
          hasPreselected: () => !!this.preselected,
          hasMoreThanOneItem: () => this.items.length > 1,
          shouldReset: () =>
            !this.args.value || this.args.value !== this.selectedItem?.value
        }
      })
  }));

  @matchesState('opened') isOpen!: boolean;

  @matchesState('opened.loading') isLoading!: boolean;

  @matchesState('native.loading') isNativeLoading!: boolean;

  get hasMoreItems() {
    return this._items.length < (this.args.recordsCount ?? 0);
  }

  get items() {
    const items = this._items;
    if (this.args.sort) {
      return this.args.sort(items);
    } else if (this.args.sortByParam) {
      return sortBy(items, this.args.sortByParam);
    }
    return items;
  }
  get searchTerm() {
    return this.statechart.state?.context.searchTerm;
  }

  get dropdownOptions(): DropdownOption[] {
    if (this.args.optionsTransform) {
      return this.items.map((item: any) => this.args.optionsTransform?.(item));
    }
    return this.items.map(optionsTransform());
  }

  @action
  openOrClosePopup() {
    if (this.displayDropdown) {
      this.statechart.send('OPEN');
    } else {
      this.statechart.send('CLOSE');
    }
  }

  @action
  refresh() {
    this.statechart.send('REFRESH');
  }

  @action
  onSearch(e: InputEvent) {
    const { value } = e.target as HTMLInputElement;
    this.statechart.send('TYPE', { searchTerm: value });
  }

  @action
  loadMore() {
    this.statechart.send('LOAD_MORE');
  }

  @action
  handleKeyUp(event: KeyboardEvent): void {
    // https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key/Key_Values
    switch (event.key) {
      case 'Enter':
        this.statechart.send('PRESS_ENTER');
        event.preventDefault();
        break;
      case 'Escape':
        this.statechart.send('PRESS_ESCAPE', { event });
        break;
      case 'ArrowDown':
      case 'Tab':
        event.stopPropagation();
        event.preventDefault();
        if (event.shiftKey) {
          this.statechart.send('SELECT_PREVIOUS');
        } else {
          this.statechart.send('SELECT_NEXT');
        }
        break;
      case 'ArrowUp':
        this.statechart.send('SELECT_PREVIOUS');
        event.stopPropagation();
        event.preventDefault();
        break;
    }
  }

  get triggerComponent() {
    if (this.args.triggerComponentName) {
      return this.args.triggerComponentName;
    }
    return DropdownTrigger;
  }
  get contentItemComponentName() {
    return (
      this.args.contentItemComponentName ??
      this.configService.getValue(
        'tangram/dropdown/contentItemComponentName'
      ) ??
      DropdownContentItem
    );
  }

  get testSelector() {
    return this.args.testSelector ?? this.args.property ?? this.dropdownId;
  }

  @tracked _displayDropdown = false;

  @tracked _isAnimating = false;

  get displayDropdown(): boolean {
    return this.args.displayDropdown ?? this._displayDropdown;
  }

  get collection(): DropdownOption[] {
    return this.args.collection ?? [];
  }

  get collectionOptions(): DropdownOption[] {
    return this.collection
      .map(item => {
        if (isDropdownOption(item)) {
          return item;
        }
        return this.args.optionsTransform?.(item) ?? null;
      })
      .filter(item => item !== null);
  }

  get sameWidth() {
    return this.args.sameWidth ?? true;
  }

  get value() {
    return this.args.value;
  }

  getDropdownOption(value: Dropdown | any): DropdownOption | undefined {
    if (value && !isDropdownOption(value)) {
      if (this.args.optionsTransform) {
        return this.args.optionsTransform(value);
      }
      if (typeof value === 'string') {
        return optionsTransform()(value);
      }
    }
    return value;
  }

  /**
   * Workaround when HTMLInputElement sends the value as a string, so we
   * need to find the option that matches the stringified value:
   *
   * Example: Match `<model::stage:21>` to the StageModel instance
   *
   * Note: This only works when a static `collection` is provided.
   */
  getDropdownOptionWhenStringified(item: string): DropdownOption | string {
    const { collectionOptions } = this;
    const isSelectingString = typeof item === 'string';
    const hasStringOptions = typeof collectionOptions[0]?.value === 'string';

    if (isSelectingString && !hasStringOptions) {
      return collectionOptions.find(({ value }) => `${value}` === item) ?? item;
    }

    return item;
  }

  get selected(): DropdownOption | undefined {
    const value =
      this.args.selected ||
      this.selectedItem ||
      this.collectionOptions.find(({ value }) => value === this.value) ||
      this.collection.find(value => value === this.value) ||
      this.value;
    return this.getDropdownOption(value);
  }

  get preselected(): DropdownOption | undefined {
    return (
      this.collection.find(({ value }) => value === this.preselectedValue) ||
      this.preselectedItem
    );
  }

  get dropdownId(): string {
    return this.args.dropdownId ?? guidFor(this);
  }

  _preselectItem(index: number): void {
    const item = this.collection[index];
    this.preselectedValue = item.value;
  }

  get allowClearing(): boolean {
    return this.args.value && !!this.args.clearable;
  }

  _checkAnimation(): void {
    this._isAnimating = true;
    this.liquidFireTransitions?.waitUntilIdle().then(() => {
      if (!this.isDestroyed) {
        this._isAnimating = false;
      }
    });
  }

  @action
  onBackgroundClickedAndHide(): void {
    return this.args.onBackgroundClicked?.(this.hide) || this.hide();
  }
  @action
  reset(): void {
    this.preselectedValue = null;
    this.preselectedItem = undefined;
    this.selectedItem = undefined;
    if (this.args.getCollection) {
      this._items = [];
    }
  }
  @action
  shouldReset() {
    this.statechart.send('RESET');
  }
  @action
  handleClear(): void {
    this.statechart.send('CLEAR');
  }

  @action
  onSelectAndHide(item?: DropdownOption | any): void {
    this.hide();

    item = this.getDropdownOptionWhenStringified(item);

    if (isDropdownOption(item)) {
      this.selectedItem = item;
      return this.args.onSelect?.(item.value);
    } else {
      this.selectedItem = undefined;
      const option = this.getDropdownOption(
        this.collectionOptions.find(({ value }) => value === item) || item
      );
      return this.args.onSelect?.(option?.value);
    }
  }

  @action
  onPreselect(item: DropdownOption): void {
    this.preselectedItem = item;
    this.preselectedValue = item.value;
  }
  @action
  hide(): void {
    this._displayDropdown = false;
    this._checkAnimation();
    this.preselectedValue = null;
    this.preselectedItem = undefined;
    this.args.onHide?.();
  }
  @action
  show(): void {
    if (!this.args.isDisabled) {
      this._displayDropdown = true;
      this._checkAnimation();
      this.args.onShow?.();
    }
  }
  @action
  toggleDropdown() {
    if (this.displayDropdown) {
      return this.hide();
    }
    return this.show();
  }

  @action
  onKeyPress(event: KeyboardEvent) {
    switch (event.which || event.keyCode) {
      case ENTER_KEY_CODE:
        event.preventDefault();
        if (isPresent(this.preselectedValue)) {
          this.onSelectAndHide(this.preselected);
        } else {
          this.toggleDropdown();
        }
        break;
      case ESCAPE_KEY_CODE:
        event.preventDefault();
        this.hide();
        break;
      case UP_KEY_CODE:
        event.preventDefault();
        if (this.displayDropdown) {
          // @ts-ignore
          let index = this.collection.indexOf(this.preselected) - 1;
          if (index < 0) {
            index = this.collection.length - 1;
          }
          return this._preselectItem(index);
        }
        break;
      case DOWN_KEY_CODE:
        event.preventDefault();
        if (this.displayDropdown) {
          // @ts-ignore
          let index = this.collection.indexOf(this.preselected) + 1;
          if (index === this.collection.length) {
            index = 0;
          }
          return this._preselectItem(index);
        } else {
          this.show();
        }
        break;
      case TAB_KEY_CODE:
        if (this.displayDropdown) {
          this.hide();
        }
        break;
    }
  }

  @action
  setContentVisible() {
    this.contentVisible = true;
  }
  @action
  setContentHidden() {
    this.contentVisible = false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    Dropdown: typeof Dropdown;
    dropdown: typeof Dropdown;
  }
}
