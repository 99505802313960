import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type { WithBoundArgs } from '@glint/template';
import { assign, Machine } from 'xstate';
import { hash } from '@ember/helper';
import { action } from '@ember/object';
import { useMachine } from 'ember-statecharts';
import type TableRow from '../components/table/row.ts';
import TableCell from '../components/table/td.ts';
import { matchesState } from '../utils/statecharts.ts';
import TableBodyInfiniteComponent from './table/body-infinite.ts';
import TableBodyComponent from './table/body.ts';
import TableFootComponent from './table/foot.ts';
import TableHeadComponent from './table/head.gts';
import TableRowComponent from './table/row.ts';
import TableTableComponent from './table/table.ts';
interface StateContext {
    data: any[];
}
interface StateSchema {
    states: {
        idle: {
        };
        reordering: {
        };
    };
}
type StateEvent = {
    type: 'START';
    data: any[];
} | {
    type: 'ORDER';
    from: number;
    to: number;
} | {
    type: 'END';
    datum: any;
};
interface TableSignature {
    Args: {
        reorderItems?: Function;
        isReordeable?: boolean;
        rowClass?: string;
    };
    Blocks: {
        default: [{
                ui: {
                    table: typeof TableTableComponent;
                    head: WithBoundArgs<typeof TableHeadComponent, 'registerHeaderCell' | 'unregisterHeaderCell'>;
                    body: typeof TableBodyComponent;
                    bodyInfinite: typeof TableBodyInfiniteComponent;
                    foot: typeof TableFootComponent;
                    row: WithBoundArgs<typeof TableRow, 'expandedRows' | 'toggleExpand' | 'collapse'>;
                    cell: typeof TableCell;
                };
            }];
    };
}
export default class Table extends Component<TableSignature> {
    multiRowExpansion = true;
    @tracked
    cells: TableCell[] = [];
    @tracked
    expandedRows: TableRow[] = [];
    statechart = useMachine<StateContext, StateSchema, StateEvent, any, {
    }>(this, ()=>({
            machine: Machine<StateContext, StateSchema, StateEvent>({
                initial: 'idle',
                states: {
                    idle: {
                        on: {
                            START: {
                                target: 'reordering',
                                cond: 'isReordeable',
                                actions: assign({
                                    data: (_c1, { data: data1 })=>data1
                                })
                            }
                        }
                    },
                    reordering: {
                        on: {
                            ORDER: {
                                actions: [
                                    'order'
                                ]
                            },
                            END: {
                                target: 'idle',
                                actions: [
                                    'persistOrder'
                                ]
                            }
                        }
                    }
                }
            }).withContext({
                data: []
            }).withConfig({
                actions: {
                    order: (context1, { from: from1, to: to1 }: Extract<StateEvent, {
                        type: 'ORDER';
                    }>)=>{
                        const newData1 = [
                            ...context1.data.slice(0, from1),
                            ...context1.data.slice(from1 + 1)
                        ];
                        newData1.splice(to1, 0, context1.data[from1]);
                        context1.data = [
                            ...newData1
                        ];
                    },
                    persistOrder: (context1, { datum: datum1 }: Extract<StateEvent, {
                        type: 'END';
                    }>)=>{
                        this.args.reorderItems?.(context1.data, datum1);
                        context1.data = [];
                    }
                },
                guards: {
                    isReordeable: ()=>{
                        return this.args.isReordeable ?? false;
                    }
                }
            })
        }));
    @matchesState('reordering')
    isReordering: boolean;
    get reorderData() {
        return this.statechart.state?.context.data || [];
    }
    @action
    reorderItems(from1: number, to1: number) {
        this.statechart.send('ORDER', {
            from: from1,
            to: to1
        });
    }
    @action
    reorderEnd(datum1: any) {
        this.statechart.send('END', {
            datum: datum1
        });
    }
    @action
    reorderStart(data1: any[]) {
        this.statechart.send('START', {
            data: data1
        });
    }
    @action
    registerHeaderCell(cellComponent1: TableCell) {
        this.cells = [
            ...this.cells,
            cellComponent1
        ];
    }
    @action
    unregisterHeaderCell(cellComponent1: TableCell) {
        const index1 = this.cells.indexOf(cellComponent1);
        if (index1 >= 0) {
            this.cells.splice(index1, 1);
            this.cells = [
                ...this.cells
            ];
        }
    }
    @action
    collapse(row1: TableRow) {
        const index1 = this.expandedRows.indexOf(row1);
        if (index1 >= 0) {
            this.expandedRows.splice(index1, 1);
            this.expandedRows = [
                ...this.expandedRows
            ];
        }
    }
    @action
    toggleExpand(row1: TableRow) {
        const index1 = this.expandedRows.indexOf(row1);
        if (index1 >= 0) {
            this.expandedRows.splice(index1, 1);
            this.expandedRows = [
                ...this.expandedRows
            ];
        } else {
            if (!this.multiRowExpansion) {
                this.expandedRows = [];
            }
            this.expandedRows = [
                ...this.expandedRows,
                row1
            ];
        }
    }
    static{
        template(`
    {{! @glint-nocheck }}
    {{!
      Partial application with component helper causes an issue with the generic
      signature of Table::Body, so that passing the \\\`data\\\` arg does not yield the
      correct type in the default block.

      https://github.com/typed-ember/glint/issues/610
    }}
    {{yield
      (hash
        ui=(hash
          table=TableTableComponent
          head=(component
            TableHeadComponent
            registerHeaderCell=this.registerHeaderCell
            unregisterHeaderCell=this.unregisterHeaderCell
          )
          body=(component
            TableBodyComponent
            rowClass=@rowClass
            cells=this.cells
            expandedRows=this.expandedRows
            toggleExpand=this.toggleExpand
            collapse=this.collapse
            isReordering=this.isReordering
            reorderData=this.reorderData
            reorderItems=this.reorderItems
            reorderStart=this.reorderStart
            reorderEnd=this.reorderEnd
            isReordeable=@isReordeable
          )
          bodyInfinite=(component
            TableBodyInfiniteComponent
            cells=this.cells
            expandedRows=this.expandedRows
            toggleExpand=this.toggleExpand
            collapse=this.collapse
            isReordering=this.isReordering
            reorderData=this.reorderData
            reorderItems=this.reorderItems
            reorderStart=this.reorderStart
            reorderEnd=this.reorderEnd
            isReordeable=@isReordeable
          )
          foot=TableFootComponent
          row=(component
            TableRowComponent
            rowClass=@rowClass
            expandedRows=this.expandedRows
            toggleExpand=this.toggleExpand
            collapse=this.collapse
          )
          cell=TableCell
        )
      )
    }}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
declare module '@glint/environment-ember-loose/registry' {
    export default interface Registry {
        Table: typeof Table;
    }
}
