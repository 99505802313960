import Component from '@glimmer/component';

interface TypeaheadDropdownLoadingSignature {
  Args: {};
}

export default class TypeaheadDropdownLoadingComponent extends Component<TypeaheadDropdownLoadingSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Typeahead::Dropdown::Loading': typeof TypeaheadDropdownLoadingComponent;
    'typeahead/dropdown/loading': typeof TypeaheadDropdownLoadingComponent;
  }
}
