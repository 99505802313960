import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import isEqual from 'lodash-es/isEqual';
import uniq from 'lodash-es/uniq';
import without from 'lodash-es/without';
import { Machine } from 'xstate';
import type { BaseSchema, ObjectSchema, TestContext, TestFunction } from 'yup';
import { ValidationError, addMethod, mixed } from 'yup';
import type { ObjectShape } from 'yup/lib/object';
import type { SchemaObjectDescription } from 'yup/lib/schema';
import type { Message } from 'yup/lib/types';

import { action } from '@ember/object';
import { next } from '@ember/runloop';
import { inject as service } from '@ember/service';

import { task } from 'ember-concurrency';
import { provide } from 'ember-provide-consume-context';
import { useMachine } from 'ember-statecharts';

import type ErrorsService from '../services/-errors-base.ts';
import type ConfigService from '../services/config.ts';
import type { FormObjectBase, Transform } from '../utils/form-object.ts';
import type FormObject from '../utils/form-object.ts';
import { matchesState } from '../utils/statecharts.ts';

const WARN_NAME = 'WARN_ONLY_TEST';
addMethod(
  mixed,
  'warn',
  function (message: Message<{}>, test: TestFunction<any, TestContext>) {
    return this.test({
      name: WARN_NAME,
      exclusive: false,
      test,
      message: params => {
        return `${WARN_NAME}${
          typeof message === 'function' ? message(params) : message
        }`;
      }
    });
  }
);

function isValidationError(error: any): error is ValidationError {
  if (!error || typeof error !== 'object') {
    return false;
  }
  return error instanceof ValidationError || error?.name === 'ValidationError';
}

export interface FormSignature<T extends FormObjectBase> {
  Args: {
    formObject: T;
    schema?: ObjectSchema<ObjectShape>;
    onSubmit?: Function;
    onSuccess?: Function;
    onError?: Function;
    onChanged?: Function;
    onUnchanged?: Function;
    onSubmitInvalid?: (errors: ValidationError[]) => void;
    isSubmittableUnchanged?: boolean;
    displayErrorOnInteraction?: boolean;

    register?: (child: Form<any>) => void;
    unregister?: (child: Form<any>) => void;
    parentDidTrySubmit?: boolean;
    bubbleSubmit?: boolean;
    requiredFields?: string[];
  };
  Blocks: {
    default: [
      {
        state: {
          isValid: Form['allValid'];
          isInvalid: Form['anyInvalid'];
          formObject: T;
          validationErrors: Form['validationErrors'];
          validationWarnings: Form['validationWarnings'];
          serverErrors: Form['serverErrors'];
          isUnchanged: Form['allUnchanged'];
          isChanged: Form['anyChanged'];
          anyChildrenChanged: Form['anyChildrenChanged'];
          isBusy: Form['anyBusy'];
          isSubmittable: Form['isSubmittable'];
          showCtaDisabled: Form['showCtaDisabled'];
          needsTransitionConfirmation: Form['needsTransitionConfirmation'];
          interactedFormFields: Form['interactedFormFields'];
          userDidTrySubmit: Form['anyUserDidTrySubmit'];
          requiredFormFields: Form['requiredFormFields'];
          parentDidTrySubmit: Form['anyUserDidTrySubmit'];
          isSubmittableUnchanged: Form['isSubmittableUnchanged'];
          displayErrorOnInteraction: Form['displayErrorOnInteraction'];
        };
        actions: {
          reset: Form['reset'];
          change: Form['change'];
          changeOnInput: Form['changeOnInput'];
          submit: Form['submit'];
          markChanged: Form['markChanged'];
          markSubmitted: Form['markSubmitted'];
          markSubmittedAndSubmit: Form['markSubmittedAndSubmit'];
          markFormFieldInteractedWith: Form['markFormFieldInteractedWith'];
          register: Form['scheduleRegister'];
          unregister: Form['scheduleUnregister'];
        };
      }
    ];
  };
}
export type FormYieldedArgs<T extends Object = Object> = FormSignature<
  FormObject<any>
>['Blocks']['default'][0];

interface StateSchema {
  states: {
    willInitialize: {};
    initialized: {
      states: {
        behavior: {
          states: {
            unchanged: {};
            changing: {};
            changed: {};
            busy: {};
            success: {};
            error: {};
          };
        };
        validity: {
          states: { validating: {}; valid: {}; invalid: {} };
        };
        submitStatus: {
          states: { unsubmitted: {}; submitted: {} };
        };
      };
    };
  };
}

type StatechartEvent =
  | { type: 'RESET' }
  | { type: 'INIT' }
  | { type: 'SUBMIT' }
  | { type: 'CHANGE'; transform?: Transform }
  | { type: 'CHANGED' }
  | { type: 'RESOLVE'; result: any }
  | {
      type: 'REJECT';
      genericServerErrors: string[];
      serverValidationErrors: ValidationError[];
    }
  | { type: 'VALID'; warnings?: ValidationError[] }
  | { type: 'INVALID'; errors: ValidationError[]; warnings?: ValidationError[] }
  | { type: 'MARK_SUBMISSION' };

function noop() {}

export default class Form<
  T extends FormObject<any> = FormObject<any>
> extends Component<FormSignature<T>> {
  @service errors!: ErrorsService;
  @service config!: ConfigService;

  @tracked formObject!: T;
  @tracked validationErrors: ValidationError[] = [];
  @tracked validationWarnings: ValidationError[] = [];
  @tracked serverErrors: any[] = [];
  @tracked interactedFormFields: string[] = [];
  @tracked private _children: Form[] = [];

  constructor(owner: unknown, args: FormSignature<T>['Args']) {
    super(owner, args);

    this.formObject = this.args.formObject;

    this.statechart.send('INIT');

    this.args.register?.(this);
  }

  willDestroy() {
    super.willDestroy();
    this.args.unregister?.(this);
  }

  get children() {
    return this._children.filter(
      form => !form.isDestroyed && !form.isDestroying
    );
  }

  get allValidationErrors() {
    return [
      ...this.validationErrors,
      ...this.children.map(child => child.validationErrors).flat()
    ];
  }

  get isSubmittableUnchanged() {
    return this.args.isSubmittableUnchanged || false;
  }
  get displayErrorOnInteraction() {
    return this.args.displayErrorOnInteraction ?? true;
  }

  get allValid(): boolean {
    return this.isValid && this.children.every(f => f.allValid);
  }
  get anyInvalid(): boolean {
    return this.isInvalid || this.children.some(f => f.anyInvalid);
  }
  get allChildrenUnchanged(): boolean {
    return this.children.every(f => f.allUnchanged);
  }
  get allUnchanged(): boolean {
    return this.isUnchanged && this.allChildrenUnchanged;
  }
  get anyChanged(): boolean {
    return !this.allUnchanged;
  }
  get anyChildrenChanged(): boolean {
    return !this.allChildrenUnchanged;
  }
  get anyBusy(): boolean {
    return (
      this.isBusy || this.children.some(f => f.anyBusy) || this.isValidating
    );
  }
  get anyUserDidTrySubmit(): boolean {
    return this.userDidTrySubmit || this.args.parentDidTrySubmit!;
  }
  get needsTransitionConfirmation(): boolean {
    return this.anyChanged && !this.isInSubmitSuccess;
  }

  @provide('form')
  get api() {
    const {
      allValid: isValid,
      anyInvalid: isInvalid,
      formObject,
      validationErrors,
      validationWarnings,
      serverErrors,
      allUnchanged: isUnchanged,
      anyChanged: isChanged,
      anyChildrenChanged,
      anyBusy: isBusy,
      isSubmittable,
      showCtaDisabled,
      needsTransitionConfirmation,
      interactedFormFields,
      userDidTrySubmit,
      requiredFormFields,
      anyUserDidTrySubmit: parentDidTrySubmit,
      isSubmittableUnchanged,
      displayErrorOnInteraction,
      reset,
      change,
      changeOnInput,
      submit,
      markChanged,
      markSubmitted,
      markSubmittedAndSubmit,
      markFormFieldInteractedWith,
      scheduleRegister: register,
      scheduleUnregister: unregister
    } = this;

    return {
      state: {
        isValid,
        isInvalid,
        formObject,
        validationErrors,
        validationWarnings,
        serverErrors,
        isUnchanged,
        isChanged,
        anyChildrenChanged,
        isBusy,
        isSubmittable,
        showCtaDisabled,
        needsTransitionConfirmation,
        interactedFormFields,
        userDidTrySubmit,
        requiredFormFields,
        parentDidTrySubmit,
        isSubmittableUnchanged,
        displayErrorOnInteraction
      },
      actions: {
        reset,
        change,
        changeOnInput,
        submit,
        markChanged,
        markSubmitted,
        markSubmittedAndSubmit,
        markFormFieldInteractedWith,
        register,
        unregister
      }
    };
  }

  @matchesState('initialized.validity.validating') isValidating!: boolean;

  @matchesState('initialized.validity.valid') isValid!: boolean;

  @matchesState('initialized.validity.invalid') isInvalid!: boolean;

  @matchesState('initialized.behavior.unchanged') isUnchanged!: boolean;

  @matchesState('initialized.behavior.busy') isBusy!: boolean;

  @matchesState('initialized.submitStatus.submitted')
  userDidTrySubmit!: boolean;

  @matchesState('initialized.behavior.success') isInSubmitSuccess!: boolean;

  statechart = useMachine(this, () => ({
    machine: Machine<Form, StateSchema, StatechartEvent>({
      initial: 'willInitialize',
      on: {
        RESET: 'initialized'
      },
      states: {
        willInitialize: {
          on: {
            INIT: 'initialized'
          }
        },
        initialized: {
          entry: 'resetState',
          type: 'parallel',
          states: {
            behavior: {
              initial: 'unchanged',
              states: {
                unchanged: {
                  entry: 'onUnchanged',
                  on: {
                    CHANGE: {
                      target: 'changing'
                    },
                    SUBMIT: [
                      {
                        target: 'busy',
                        cond: 'isSubmittableUnchanged'
                      },
                      {
                        target: 'unchanged',
                        cond: 'isSubmittedAndInvalid',
                        actions: ['handleSubmitInvalid']
                      }
                    ]
                  }
                },
                changing: {
                  entry: ['updateFormObject', 'onChanged'],

                  on: {
                    CHANGE: {
                      target: 'changing'
                    },
                    CHANGED: [
                      { target: 'unchanged', cond: 'isPristine' },
                      { target: 'changed' }
                    ]
                  }
                },
                changed: {
                  on: {
                    CHANGE: [
                      {
                        target: 'changing'
                      }
                    ],
                    SUBMIT: [
                      {
                        target: 'busy',
                        cond: 'isValid'
                      },
                      {
                        target: 'changed',
                        cond: 'isSubmittedAndInvalid',
                        actions: ['handleSubmitInvalid']
                      }
                    ]
                  }
                },
                busy: {
                  entry: 'submitForm',
                  on: {
                    RESOLVE: {
                      target: 'success'
                    },
                    REJECT: {
                      target: 'error',
                      actions: 'handleSubmitError'
                    }
                  }
                },
                success: {
                  entry: 'handleSubmitSuccess',
                  on: {
                    CHANGE: {
                      target: 'changing'
                    },
                    SUBMIT: [
                      {
                        target: 'busy',
                        cond: 'isValid'
                      },
                      {
                        target: 'success',
                        cond: 'isSubmittedAndInvalid',
                        actions: ['handleSubmitInvalid']
                      }
                    ]
                  }
                },
                error: {
                  entry: ['handleServerError'],
                  on: {
                    CHANGE: {
                      target: 'changing'
                    },
                    SUBMIT: [
                      {
                        target: 'busy',
                        cond: 'isValid',
                        actions: ['resetServerErrors']
                      },
                      {
                        target: 'error',
                        cond: 'isSubmittedAndInvalid',
                        actions: ['handleSubmitInvalid']
                      }
                    ]
                  }
                }
              }
            },
            validity: {
              initial: 'validating',
              states: {
                validating: {
                  entry: ['resetValidationErrors', 'validateFormObject'],
                  on: {
                    CHANGE: 'validating',
                    VALID: 'valid',
                    INVALID: 'invalid'
                  }
                },
                valid: {
                  entry: 'handleValidationWarnings',
                  on: {
                    CHANGE: 'validating'
                  }
                },
                invalid: {
                  entry: 'handleValidationError',
                  on: {
                    CHANGE: 'validating'
                  }
                }
              }
            },
            submitStatus: {
              initial: 'unsubmitted',
              states: {
                unsubmitted: {
                  on: {
                    MARK_SUBMISSION: 'submitted'
                  }
                },
                submitted: {}
              }
            }
          }
        }
      }
    }).withConfig({
      actions: {
        submitForm: () => this.submitFormTask.perform(),
        handleSubmitSuccess: (
          _,
          { result }: Extract<StatechartEvent, { type: 'RESOLVE' }>
        ) => {
          this.args.onSuccess?.(result);
        },
        handleSubmitError: (
          _,
          { genericServerErrors }: Extract<StatechartEvent, { type: 'REJECT' }>
        ) => {
          if (this.args.onError) {
            this.args.onError(genericServerErrors);
          } else if (genericServerErrors.length > 0) {
            if (genericServerErrors.length === 1) {
              this.errors.log(genericServerErrors[0]);
            } else {
              const error = genericServerErrors[0];
              this.errors.log(new Error(`Unhandled server errors: ${error}`), {
                extra: {
                  serverErrors: genericServerErrors
                }
              });
            }
          }
        },
        handleSubmitInvalid: () => {
          this.args.onSubmitInvalid?.(this.allValidationErrors);
        },
        handleValidationError: (
          _,
          { errors, warnings }: Extract<StatechartEvent, { type: 'INVALID' }>
        ) => {
          this.validationErrors = errors;
          this.validationWarnings = warnings ?? [];
        },
        handleValidationWarnings: (
          _,
          { warnings }: Extract<StatechartEvent, { type: 'VALID' }>
        ) => {
          this.validationWarnings = warnings ?? [];
        },
        handleServerError: (
          _,
          {
            genericServerErrors,
            serverValidationErrors
          }: Extract<StatechartEvent, { type: 'REJECT' }>
        ) => {
          this.serverErrors = genericServerErrors;
          this.validationErrors = serverValidationErrors;
        },
        resetValidationErrors: () => {
          if (this.validationErrors.length) {
            this.validationErrors = [];
          }
          this.validationWarnings = [];
        },
        resetServerErrors: () => (this.serverErrors = []),
        validateFormObject: () => this.validateFormTask.perform(),
        updateFormObject: (
          _,
          { transform }: Extract<StatechartEvent, { type: 'CHANGE ' }>
        ) => {
          if (transform) {
            this.formObject.change(transform);
          }
          this.statechart.send('CHANGED');
        },
        resetState: () => {
          this.validationErrors = [];
          this.validationWarnings = [];
          this.serverErrors = [];
          this.interactedFormFields = [];
          this.formObject.reset();
        },
        onChanged: () => this.args.onChanged?.(),
        onUnchanged: () => this.args.onUnchanged?.()
      },
      guards: {
        isPristine: () => this.formObject.isPristine,
        isValid: () => this.allValid,
        isSubmittableUnchanged: () => {
          return (
            (this.anyChanged || this.isSubmittableUnchanged) && this.allValid
          );
        },
        isSubmittedAndInvalid: () => this.anyInvalid && this.userDidTrySubmit
      }
    })
  }));

  get isSubmittable() {
    const { allUnchanged: isUnchanged, isSubmittableUnchanged } = this;

    return !isUnchanged || (isUnchanged && isSubmittableUnchanged);
  }

  get showCtaDisabled() {
    const { isBusy, isInvalid, userDidTrySubmit, isSubmittable } = this;

    return (isInvalid && userDidTrySubmit) || !isSubmittable || isBusy;
  }

  get requiredFormFields() {
    const { schema, requiredFields } = this.args;

    if (schema) {
      const { fields } = schema.describe();
      const keys = Object.keys(fields);

      const requiredFormFields = keys.reduce(
        (prev, curr) => {
          const { tests } = fields[curr] as SchemaObjectDescription;

          if (
            tests.filter(({ name }: { name?: string }) => name === 'required')
              .length > 0
          ) {
            prev = [...prev, curr];
          }

          return prev;
        },
        (requiredFields ?? []) as string[]
      );

      return requiredFormFields;
    } else {
      return requiredFields ?? [];
    }
  }

  validateFormTask = task({ restartable: true }, async () => {
    const { statechart, formObject } = this;
    const { schema } = this.args;
    const errors: ValidationError[] = [];
    const warnings: ValidationError[] = [];
    if (schema) {
      const errorResult = await schema
        .validate(formObject, { abortEarly: false })
        .catch(e => e);

      if (isValidationError(errorResult)) {
        errorResult.inner.forEach(warning => {
          if (warning.message.includes(WARN_NAME)) {
            warning.message = warning.message.replace(WARN_NAME, '');
            warnings.push(warning);
          } else {
            errors.push(warning);
          }
        });
        if (errors.length) {
          return statechart.send('INVALID', { errors, warnings });
        }
      }
    }
    return statechart.send('VALID', { warnings });
  });

  submitFormTask = task({ drop: true }, async () => {
    try {
      const { formObject } = this;
      const { onSubmit = noop } = this.args;
      const childFormObjects = this.children.map(form => form.formObject);
      const result = await onSubmit(formObject, childFormObjects);

      this.statechart.send('RESOLVE', { result });
    } catch (errors) {
      let genericErrors = [];
      let validationErrors = [];
      if (Array.isArray(errors)) {
        genericErrors = errors.filter((e: any) => !isValidationError(e));
        validationErrors = errors.filter((e: any) => isValidationError(e));
      } else if (isValidationError(errors)) {
        validationErrors = [errors];
      } else {
        genericErrors = [errors];
      }
      this.statechart.send('REJECT', {
        genericServerErrors: genericErrors,
        serverValidationErrors: validationErrors
      });
    }
  });

  @action
  submit(event?: Event) {
    event?.preventDefault();

    if (!this.args.bubbleSubmit) {
      event?.stopPropagation();
    }

    this.statechart.send('SUBMIT');
  }

  @action
  change(property: string, value: any) {
    const transform = { [property]: value };

    this.statechart.send('CHANGE', { transform });
  }

  @action
  changeOnInput(
    property: string,
    event: InputEvent & {
      target: HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement;
    }
  ) {
    const value = event.target.value;
    this.markFormFieldInteractedWith(property);
    this.change(property, value);
  }

  @action
  markChanged() {
    this.statechart.send('CHANGE');
  }

  @action
  markSubmitted() {
    this.statechart.send('MARK_SUBMISSION');
  }

  @action
  markSubmittedAndSubmit() {
    this.markSubmitted();
    this.submit();
  }

  @action
  reset() {
    this.formObject = this.args.formObject;
    this.children.forEach(c => c.reset());
    this.statechart.send('RESET');
  }

  @action
  async markFormFieldInteractedWith(propertyName: string) {
    await Promise.resolve();
    this.interactedFormFields = uniq([
      ...this.interactedFormFields,
      propertyName
    ]);
  }

  @action
  handleFormObjectChange() {
    if (
      !isEqual(this.args.formObject.original, this.formObject.original) ||
      this.isInSubmitSuccess
    ) {
      next(() => this.reset());
    }
  }

  @action
  scheduleRegister(child: Form) {
    next(this, function () {
      if (
        child.isDestroying ||
        child.isDestroyed ||
        this.isDestroying ||
        this.isDestroyed
      ) {
        return;
      }

      this.register(child);
    });
  }
  @action
  scheduleUnregister(child: Form) {
    next(this, function () {
      this.unregister(child);
    });
  }

  private register(child: Form) {
    if (this.children.includes(child)) {
      this.errors.log(
        new Error(
          `Child form already registered (formObject = ${JSON.stringify(
            child.formObject
          )})`
        )
      );
    }
    this._children = [...this.children, child];
  }
  private unregister(child: Form) {
    this._children = without(this.children, child);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    Form: typeof Form;
  }
}
