import { helper } from '@ember/component/helper';

export interface NoOpSignature {
  Args: {};
  Return: ReturnType<typeof noOp>;
}

export function noOp() {
  return () => {};
}

export default helper<NoOpSignature>(noOp);
