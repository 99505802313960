import Helper from '@ember/component/helper';
import { inject as service } from '@ember/service';
import { isNone } from '@ember/utils';

import type CurrencyService from '../services/currency.ts';

type FloatNumberOptions = {
  decimals?: number;
  hideZero?: boolean;
  currency?: string;
};

export function floatNumber(number?: number, options: FloatNumberOptions = {}) {
  const { decimals = 2, hideZero = false, currency = '' } = options;
  if (isNone(number) || isNaN(number)) {
    return '';
  }
  if (hideZero && number === 0) {
    return '';
  }
  return `${currency}${number.toLocaleString('en', {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals
  })}`;
}

interface Signature {
  Args: {
    Positional: [number?];
    Named: {
      decimals?: number;
      hideZero?: boolean;
      isCurrency?: boolean;
    };
  };
  Return: string;
}

export default class FloatNumberHelper extends Helper<Signature> {
  @service currency!: CurrencyService;

  compute(
    [number]: [number?],
    options: {
      decimals?: number;
      hideZero?: boolean;
      isCurrency?: boolean;
    } = {}
  ) {
    return floatNumber(number, {
      ...options,
      currency: options.isCurrency ? this.currency.symbol : undefined
    });
  }
}
