import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import {
  zoom as d3Zoom,
  // @ts-ignore - invalid d3 types
  event as d3Event,
  select as d3Select,
  zoomIdentity as d3ZoomIdentity
} from 'd3';
import { Machine } from 'xstate';

import { action } from '@ember/object';
import { next, debounce } from '@ember/runloop';
import { htmlSafe } from '@ember/template';
import { isNone } from '@ember/utils';

import { task, timeout } from 'ember-concurrency';
import { useMachine } from 'ember-statecharts';

import type InteractionRenderer from '../../canvas-renderers/interaction.ts';
import type { DropdownOption } from '../../components/dropdown.ts';
import { mapOptions } from '../../helpers/map-options.ts';
import type { PanPosition } from '../../modifiers/did-pan.ts';
import type {
  Offset,
  Coordinates2D,
  Transform
} from '../../utils/canvas-drawing.ts';
import {
  maxCenteredScaledTransformForDestination,
  transformCoords,
  reverseTransformedCoordsFromMouseEvent,
  createRectangleFromCoordinates,
  CIRCLE_RADIUS,
  reverseTransformRect,
  getOriginalCanvasCoordinatesFromMouseEvent
} from '../../utils/canvas-drawing.ts';
import Raf from '../../utils/raf-engine.ts';
import type { SeatBase } from '../../utils/seat-editor.ts';
import {
  findSeatByCoordinates,
  seatsForSelection
} from '../../utils/seat-editor.ts';
import { matchesState } from '../../utils/statecharts.ts';

export type InteractionType = {
  isDragging: boolean;
  isSelecting: boolean;
  preselectedSeatsLength: number | null;
};

type Context = {};
interface Schema {
  states: {
    idle: {
      states: {
        tooltipIdle: {};
        moving: {};
        hovering: {};
        hover: {};
      };
    };
    selecting: {};
    dragging: {};
    adding: {};
    zooming: {};
  };
}

type Events =
  | { type: 'MOUSE_MOVE'; event: MouseEvent }
  | { type: 'MOUSE_DOWN'; event: MouseEvent }
  | { type: 'MOUSE_UP'; event: MouseEvent }
  | { type: 'MOUSE_STOP'; event: MouseEvent }
  | { type: 'CANCEL' }
  | { type: 'ZOOM_ON'; transform: Transform }
  | { type: 'ZOOM_OFF' }
  | { type: 'TOGGLE_ADD_MODE' }
  | { type: 'HOVER_OBJECT'; seat: SeatBase }
  | { type: 'HOVER_NOTHING' };

type ITransform = Transform & { internal: boolean };

interface SeatManagementSeatMapSignature {
  Args: {
    seats: SeatBase[];
    selectedSeats: SeatBase[];
    onSeatSelected: (_seat: SeatBase, _event: MouseEvent) => void;
    onSeatsSelected: (_seats: SeatBase[], _event: MouseEvent) => void;
    onSeatsDragged: (_offset: Offset) => void;
    sourceWidth: number;
    sourceHeight: number;
    onAddTriggered?: (_coords: Coordinates2D) => {};
    onAddError?: () => {};
    onZoom?: (transform: Transform) => void;
    isDraggable?: boolean;
    disableLassoSelection?: boolean;
    filterSelection?: (_selectedSeats: SeatBase[]) => SeatBase[];
    filterZoomPan?: (event: Event, coordinates: Coordinates2D) => boolean;
    mouseStopTimeout?: number;
    /**
     * Minimum allowed zoom factor
     */
    minZoom?: number;
    /**
     * Maximum allowed zoom factor
     */
    maxZoom?: number;
    /**
     * Initial transform (x/y translate + k scale)
     */
    initTransform?: Transform;
  };
  Blocks: {
    default: [unknown];
  };
}

/**
 * Manages seat map interactions
 *
 *   - Dragging, selecting, adding seats
 *   - Zooming, panning the canvas
 *   - Seat Tooltip
 *
 * 3rd party dependencies:
 *
 *   - d3.js - Used for canvas zooming
 *
 * Note: Except for the zoomIn/zoomOut actions, the zooming and panning is
 * automatically implemented by the d3.zoom behavior that controls the `transform`
 * property.
 */
export default class SeatManagementSeatMapComponent extends Component<SeatManagementSeatMapSignature> {
  ZOOM_INACTIVITY_TIMEOUT_IN_MS = 300;
  MOUSE_MOVE_INACTIVITY_TIMEOUT_IN_MS = this.args.mouseStopTimeout ?? 500;

  @tracked destinationWidth: number | null = null;
  @tracked destinationHeight: number | null = null;

  @tracked boundingClientRect!: DOMRect;

  @tracked mouseDownEvent: MouseEvent | null = null;
  @tracked mouseMoveEvent: MouseEvent | null = null;

  @tracked hoveredSeat: SeatBase | null = null;

  @tracked preselectedSeatsLength: number | null = null;

  @tracked backgroundImage: HTMLImageElement | null = null;

  @tracked private _transform: ITransform | null = null;
  @tracked private canvas!: d3.Selection<
    HTMLCanvasElement,
    any,
    null,
    undefined
  >;
  private scrollContainer?: HTMLElement;
  private scrollContainerResizeObserver?: ResizeObserver;
  private canvasContainer?: HTMLElement;
  private canvasContainerResizeObserver?: ResizeObserver;
  @tracked private zoom!: d3.ZoomBehavior<
    HTMLCanvasElement,
    { byX: number; byY: number; byK: number; toK: number }
  >;
  @tracked private interactionRafLoop: Raf | null = null;
  @tracked private zoomRafLoop: Raf | null = null;
  @tracked private _temporaryMouseMoveEvent: MouseEvent | null = null;
  @tracked private _temporaryTransform: Transform | null = null;
  @tracked private _seats!: SeatBase[];

  private get seats() {
    return this._seats ?? this.args.seats;
  }
  private set seats(value) {
    this._seats = value;
  }

  private get selectedSeats() {
    return this.args.selectedSeats;
  }

  private get isDraggable(): boolean {
    return this.args.isDraggable ?? false;
  }

  private onZoomSetup = (
    canvas: HTMLCanvasElement,
    zoom: d3.ZoomBehavior<
      HTMLCanvasElement,
      { byX: number; byY: number; byK: number; toK: number }
    >
  ) => {
    this.canvas = d3Select(canvas);
    this.zoom = zoom;
  };

  private onZoom = () => {
    // @ts-ignore - Migrate to d3 v7
    const transform = <Transform>d3Event.transform;

    this.statechart.send('ZOOM_ON', { transform });

    this.args.onZoom?.(transform);
  };

  get canRenderSeatPlan(): boolean {
    return !isNone(this.destinationWidth) && !isNone(this.destinationHeight);
  }

  get currentZoomLevel(): number {
    return this.transformZoomLevel || this.zoomLevels[0];
  }

  get isMaxZoomLevel(): boolean {
    return this.currentZoomLevel === this.zoomLevels[0];
  }

  get isMinZoomLevel(): boolean {
    return (
      this.currentZoomLevel === this.zoomLevels[this.zoomLevels.length - 1]
    );
  }

  @matchesState('selecting') isSelecting!: boolean;

  @matchesState('dragging') isDragging!: boolean;

  @matchesState('adding') isAddMode!: boolean;

  @matchesState({ idle: 'hover' }) showTooltipForSeat!: boolean;

  constructor(owner: unknown, args: any) {
    super(owner, args);
    this.onSetup();
  }

  onSetup() {
    // Override in tests
  }

  private statechart = useMachine<Context, Schema, Events, any, {}>(
    this,
    () => {
      const machine = Machine<Context, Schema, Events>({
        initial: 'idle' as const,
        states: {
          idle: {
            entry: 'clearEvents',
            on: {
              MOUSE_DOWN: [
                {
                  target: 'dragging',
                  cond: 'shouldDrag',
                  actions: 'startDrag'
                },
                {
                  target: 'selecting',
                  actions: 'startSelection'
                }
              ],
              ZOOM_ON: 'zooming',
              TOGGLE_ADD_MODE: 'adding'
            },
            initial: 'tooltipIdle',
            states: {
              tooltipIdle: {
                on: {
                  MOUSE_MOVE: 'moving'
                }
              },
              moving: {
                entry: 'waitForStopMoving',
                on: {
                  MOUSE_MOVE: 'moving',
                  MOUSE_STOP: 'hovering'
                }
              },
              hovering: {
                entry: 'checkHoveredPosition',
                on: {
                  MOUSE_MOVE: 'moving',
                  HOVER_OBJECT: 'hover',
                  HOVER_NOTHING: 'tooltipIdle'
                }
              },
              hover: {
                entry: 'renderTooltipForSeat',
                exit: 'hideTooltipForSeat',
                on: {
                  MOUSE_MOVE: {
                    target: 'moving',
                    cond: 'shouldHideTooltip'
                  }
                }
              }
            }
          },
          selecting: {
            on: {
              MOUSE_UP: {
                target: 'idle',
                actions: 'endSelection'
              },
              MOUSE_MOVE: [
                {
                  target: 'selecting',
                  cond: 'supportsLassoSelection',
                  actions: 'moveSelection'
                },
                {
                  target: 'idle',
                  actions: 'endSelection'
                }
              ],
              CANCEL: 'idle'
            }
          },
          dragging: {
            on: {
              MOUSE_UP: [
                {
                  target: 'idle',
                  cond: 'isDragInBoundaries',
                  actions: 'stopDrag'
                },
                {
                  target: 'idle',
                  actions: 'stopDragWithLastValidEvent'
                }
              ],
              MOUSE_MOVE: {
                target: 'dragging',
                cond: 'isDragInBoundaries',
                actions: 'moveDraggingArea'
              },
              CANCEL: 'idle'
            }
          },
          adding: {
            on: {
              MOUSE_DOWN: {
                target: 'adding',
                actions: 'add'
              },
              TOGGLE_ADD_MODE: 'idle'
            }
          },
          zooming: {
            entry: 'startZoomRaf',
            on: {
              ZOOM_ON: 'zooming',
              ZOOM_OFF: {
                target: 'idle',
                actions: 'stopZoomRaf'
              }
            }
          }
        }
      }).withConfig({
        actions: {
          clearEvents: () => this.clearEvents(),
          startSelection: (
            _,
            { event }: Extract<Events, { type: 'MOUSE_DOWN' }>
          ) => (this.mouseDownEvent = event),
          endSelection: (_, { event }: Extract<Events, { type: 'MOUSE_UP' }>) =>
            this.handleEndSelection(event),
          moveSelection: (
            _,
            { event }: Extract<Events, { type: 'MOUSE_MOVE' }>
          ) => this.handleMoveSelection(event),
          startZoomRaf: (
            _,
            { transform }: Extract<Events, { type: 'ZOOM_ON' }>
          ) => this.startZoomRafTask.perform(transform),
          stopZoomRaf: () => this.stopZoomRafLoop(),
          startDrag: (_, { event }: Extract<Events, { type: 'MOUSE_DOWN' }>) =>
            (this.mouseDownEvent = event),
          stopDrag: (_, { event }: Extract<Events, { type: 'MOUSE_UP' }>) =>
            this.handleEndDragging(event),
          moveDraggingArea: (_, e: Extract<Events, { type: 'MOUSE_MOVE' }>) =>
            (this._temporaryMouseMoveEvent = e.event),
          add: (_, { event }: Extract<Events, { type: 'MOUSE_DOWN' }>) => {
            const { boundingClientRect, transform } = this;
            const { sourceWidth, sourceHeight } = this.args;

            const [x, y] = reverseTransformedCoordsFromMouseEvent(
              event,
              boundingClientRect,
              transform
            );

            if (x >= 0 && x <= sourceWidth && y >= 0 && y <= sourceHeight) {
              this.args.onAddTriggered?.([x, y]);
            } else {
              this.args.onAddError?.();
            }
          },
          stopDragWithLastValidEvent: () => {
            if (this._temporaryMouseMoveEvent) {
              this.handleEndDragging(this._temporaryMouseMoveEvent);
            }
          },
          waitForStopMoving: (_, e: Extract<Events, { type: 'MOUSE_MOVE' }>) =>
            this.checkMouseMovingTask.perform(e.event),
          checkHoveredPosition: (
            _,
            e: Extract<Events, { type: 'MOUSE_STOP' }>
          ) => this.checkHoveredPosition(e.event),
          renderTooltipForSeat: (
            _,
            e: Extract<Events, { type: 'HOVER_OBJECT' }>
          ) => (this.hoveredSeat = e.seat),
          hideTooltipForSeat: () => (this.hoveredSeat = null)
        },
        guards: {
          shouldDrag: (
            _,
            { event }: Extract<Events, { type: 'MOUSE_DOWN' }>
          ) => {
            if (!this.isDraggable) return false;
            if (!event.shiftKey) return this.shouldStartDragging(event);
            return false;
          },
          supportsLassoSelection: () => {
            return !this.args.disableLassoSelection;
          },
          isDragInBoundaries: (
            _,
            e: Extract<Events, { type: 'MOUSE_DOWN' }>
          ) => {
            const {
              selectedSeats,
              mouseDownEvent,
              transform,
              boundingClientRect
            } = this;
            const { sourceWidth, sourceHeight } = this.args;

            // transform coords and figure out if we are in bounds when we apply offset
            if (mouseDownEvent) {
              const [mouseDownX, mouseDownY] =
                reverseTransformedCoordsFromMouseEvent(
                  mouseDownEvent,
                  boundingClientRect,
                  transform
                );
              const [eventX, eventY] = reverseTransformedCoordsFromMouseEvent(
                e.event,
                boundingClientRect,
                transform
              );

              const offset = {
                x: mouseDownX - eventX,
                y: mouseDownY - eventY
              };

              const selectedSeatsSortedX = selectedSeats.mapBy('x').sort();
              const selectedSeatsSortedY = selectedSeats.mapBy('y').sort();

              const maxX = selectedSeatsSortedX[0];
              const minX =
                selectedSeatsSortedX[selectedSeatsSortedX.length - 1];
              const maxY = selectedSeatsSortedY[0];
              const minY =
                selectedSeatsSortedY[selectedSeatsSortedY.length - 1];

              // add offset to max/min values create rectangle and check if it fits into ticket-zone
              const rect = createRectangleFromCoordinates(
                [maxX - offset.x, maxY - offset.y],
                [minX - offset.x, minY - offset.y]
              );

              return (
                rect.x + rect.width <= sourceWidth &&
                rect.x >= 0 &&
                rect.y + rect.height <= sourceHeight &&
                rect.y >= 0
              );
            }

            return false;
          },
          shouldHideTooltip: (_, e: Extract<Events, { type: 'MOUSE_MOVE' }>) =>
            !e.event.shiftKey
        }
      });
      return { machine };
    }
  );

  get interaction(): InteractionType {
    const { isSelecting, isDragging, preselectedSeatsLength } = this;
    return { isSelecting, isDragging, preselectedSeatsLength };
  }

  private get transformZoomLevel(): number | null {
    if (this.transform) {
      const { k } = this.transform;

      const percentage = k * 100;
      return Math.round(percentage);
    }

    return null;
  }

  get scaleFactor(): number {
    if (this.transform) {
      const { k } = this.transform;

      if (k > 1.1 || k < 0.9) {
        return 2;
      }
    }

    return 1;
  }

  private get zoomLevels(): number[] {
    const initialZoomLevels = [250, 200, 150, 125, 100, 75, 50];

    const zoomLevels = this.transformZoomLevel
      ? initialZoomLevels.concat(this.transformZoomLevel)
      : initialZoomLevels;

    return zoomLevels.sort((a, b) => b - a).uniq();
  }

  get zoomLevelOptions(): DropdownOption<string>[] {
    return mapOptions([
      this.zoomLevels,
      { mapLabel: (label: string) => `${label}%` }
    ]);
  }

  get transform(): ITransform {
    if (this._transform) return this._transform;
    if (this.args.initTransform) {
      return { ...this.args.initTransform, internal: true };
    }

    const { destinationWidth, destinationHeight } = this;
    const { sourceHeight, sourceWidth } = this.args;
    if (destinationWidth && destinationHeight && sourceHeight && sourceWidth) {
      const scaledTransform = maxCenteredScaledTransformForDestination({
        destinationWidth,
        destinationHeight,
        sourceWidth,
        sourceHeight,
        margin: 20
      });

      return { ...scaledTransform, internal: true };
    }

    return { x: 0, y: 0, k: 1, internal: true };
  }
  set transform(value: ITransform) {
    this._transform = value;
  }

  get tooltipStyle() {
    const { hoveredSeat: seat, transform } = this;
    if (seat) {
      const rect = {
        x: seat.x,
        y: seat.y,
        width: CIRCLE_RADIUS * 2,
        height: CIRCLE_RADIUS * 2
      };
      const { x, y, width, height } = reverseTransformRect(rect, transform);
      return htmlSafe(
        `width: ${width}px; height: ${height}px; left: ${x}px; top: ${y}px;`
      );
    }

    return htmlSafe('');
  }

  private startZoomRafTask = task(
    { restartable: true },
    async (transform: ITransform | Transform) => {
      this.updateTemporaryTransform(transform);

      this.startZoomRafLoopIfNotActive();

      await timeout(this.ZOOM_INACTIVITY_TIMEOUT_IN_MS);

      this.statechart.send('ZOOM_OFF');
    }
  );

  private checkMouseMovingTask = task(
    { restartable: true },
    async (event: MouseEvent) => {
      await timeout(this.MOUSE_MOVE_INACTIVITY_TIMEOUT_IN_MS);

      this.statechart.send('MOUSE_STOP', { event });
    }
  );

  private checkHoveredPosition(event: MouseEvent) {
    const coordinates = getOriginalCanvasCoordinatesFromMouseEvent(
      event,
      this.boundingClientRect,
      this.transform
    );
    const seat = findSeatByCoordinates(coordinates, this.seats);
    if (seat) {
      this.statechart.send('HOVER_OBJECT', { seat });
    } else {
      this.statechart.send('HOVER_NOTHING');
    }
  }

  willDestroy() {
    this.cleanupRafLoop('interactionRafLoop');
    this.unobserveCanvasPosition();
    super.willDestroy();
  }

  private unobserveCanvasPosition() {
    const {
      canvasContainerResizeObserver,
      scrollContainerResizeObserver,
      scrollContainer
    } = this;
    canvasContainerResizeObserver?.disconnect();
    scrollContainerResizeObserver?.disconnect();
    scrollContainer?.removeEventListener(
      'scroll',
      this.updateCanvasPositionDebounced
    );
  }

  private observeCanvasPosition(
    element: HTMLElement,
    scrollContainer?: string
  ) {
    if (scrollContainer) {
      this.observeScrollContainer(scrollContainer);
    }
    // Observe canvas container resize
    const resizeObserver = new ResizeObserver(
      this.updateCanvasPositionDebounced
    );
    resizeObserver.observe(element);
    this.canvasContainerResizeObserver = resizeObserver;
  }

  private observeScrollContainer(id: string) {
    const scrollContainer = document.getElementById(id)!;

    // Observe scroll container resize
    const resizeObserver = new ResizeObserver(
      this.updateCanvasPositionDebounced
    );
    resizeObserver.observe(scrollContainer);
    this.scrollContainerResizeObserver = resizeObserver;

    // Observe scroll container scrolling
    scrollContainer.addEventListener(
      'scroll',
      this.updateCanvasPositionDebounced
    );
    this.scrollContainer = scrollContainer;
  }

  @action
  updateCanvasPositionDebounced() {
    debounce(this, this.updateCanvasPosition, 300);
  }

  @action
  setCanvasContainer(
    element: HTMLElement,
    _positional?: any,
    named?: { scrollContainer: string }
  ) {
    this.canvasContainer = element;
    this.unobserveCanvasPosition();
    this.observeCanvasPosition(element, named?.scrollContainer);
    this.updateCanvasPosition();
  }

  private updateCanvasPosition() {
    const { canvasContainer } = this;
    if (!canvasContainer) {
      return;
    }
    const boundingClientRect = canvasContainer.getBoundingClientRect();
    this.destinationWidth = boundingClientRect.width;
    this.destinationHeight = boundingClientRect.height;
    this.boundingClientRect = boundingClientRect;
  }

  @action
  setupBackground(event: Event) {
    this.backgroundImage = event.target as HTMLImageElement;
    this.zoom.translateExtent([
      [-5, -5],
      [this.backgroundImage!.width + 5, this.backgroundImage!.height + 5]
    ]);
  }
  @action
  resetBackgroundImage() {
    this.backgroundImage = null;
  }

  @action
  setupInteractionRenderer(renderer: InteractionRenderer) {
    this.setupMouseMoveBufferRaf();
    this.setupZoom(renderer.canvas);
  }

  @action
  handleMouseMove(event: MouseEvent) {
    this.statechart.send('MOUSE_MOVE', { event });
  }

  @action
  handleMouseDown(event: MouseEvent) {
    this.statechart.send('MOUSE_DOWN', { event });
  }

  @action
  handleMouseUp(event: MouseEvent) {
    this.statechart.send('MOUSE_UP', { event });
  }

  @action
  handlePanStart(events: PointerEvent[]) {
    this.statechart.send('MOUSE_DOWN', { event: events[0] });
  }

  @action
  handlePanMove(_pos: PanPosition, events: PointerEvent[]) {
    this.statechart.send('MOUSE_MOVE', { event: events[0] });
  }

  @action
  handlePanEnd(_pos: PanPosition, events: PointerEvent[]) {
    this.statechart.send('MOUSE_UP', { event: events[0] });
  }

  @action
  handleKeyDown(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.statechart.send('CANCEL');
    }
  }

  @action
  zoomIn() {
    const { zoomLevels, currentZoomLevel } = this;

    const currentIndex = zoomLevels.indexOf(currentZoomLevel);

    // zoomLevels are sorted descending
    const nextZoomLevel = zoomLevels[currentIndex - 1];

    this.applyZoomLevel(nextZoomLevel);
  }

  @action
  zoomOut() {
    const { zoomLevels, currentZoomLevel } = this;
    const currentIndex = zoomLevels.indexOf(currentZoomLevel);

    // zoomLevels are sorted descending
    const previousZoomLevel = zoomLevels[currentIndex + 1];

    this.applyZoomLevel(previousZoomLevel);
  }

  @action
  setZoomLevel(zoomLevel: number) {
    this.applyZoomLevel(zoomLevel);
  }

  @action
  toggleAddMode() {
    this.statechart.send('TOGGLE_ADD_MODE');
  }

  private startZoomRafLoopIfNotActive() {
    const { zoomRafLoop } = this;

    if (!zoomRafLoop) {
      this.startZoomRafLoop();
    }
  }

  private startZoomRafLoop() {
    function updateTransform(this: SeatManagementSeatMapComponent) {
      this.applyInternalTransformToZoomWhenNecessary();
      this.transform = this._temporaryTransform as ITransform;
    }

    const zoomRafLoop = new Raf(updateTransform.bind(this));

    zoomRafLoop.start();

    this.zoomRafLoop = zoomRafLoop;
  }

  private stopZoomRafLoop() {
    this.cleanupRafLoop('zoomRafLoop');

    this._temporaryTransform = null;
  }

  private updateTemporaryTransform(transform: ITransform | Transform) {
    this._temporaryTransform = transform;
  }

  // if the user has not interacted with the zoom controls already we will apply
  // an automatic transform to scale the seat-map to fit the canvas. When the user
  // starts interacting with the seat-map we will stop this automatic behavior but
  // we need to apply the calculated transform to the zoom-behavior first otherwise
  // we would start out at the default zoom transform which is {k: 1, x: 0, y: 0 }
  private applyInternalTransformToZoomWhenNecessary() {
    const { transform, canvas, zoom } = this;

    if (transform && transform.internal) {
      canvas.call(
        zoom.transform,
        d3ZoomIdentity.translate(transform.x, transform.y).scale(transform.k)
      );
    }
  }

  private handleMoveSelection(mouseMoveEvent: MouseEvent) {
    this._temporaryMouseMoveEvent = mouseMoveEvent;

    const preselectedSeats = this.selectSeatsForSelection(mouseMoveEvent);
    if (preselectedSeats) {
      this.preselectedSeatsLength = preselectedSeats.length;
    }
  }

  private handleEndSelection(mouseUpEvent: MouseEvent) {
    const selectedSeats = this.selectSeatsForSelection(mouseUpEvent);
    if (selectedSeats) {
      if (selectedSeats.length === 1) {
        this.args.onSeatSelected(selectedSeats[0], mouseUpEvent);
      } else {
        this.args.onSeatsSelected(selectedSeats, mouseUpEvent);
      }
    }
  }

  private handleEndDragging(mouseUpEvent: MouseEvent) {
    const { mouseDownEvent, transform } = this;
    if (mouseDownEvent) {
      const [x, y] = transformCoords(
        [
          mouseUpEvent.clientX - mouseDownEvent.clientX,
          mouseUpEvent.clientY - mouseDownEvent.clientY
        ],
        transform
      );
      this.args.onSeatsDragged({ x, y });
    }
  }

  private shouldStartDragging(event: MouseEvent) {
    const {
      seats: _seats,
      boundingClientRect,
      transform,
      selectedSeats
    } = this;
    const seats = _seats.slice();
    const seatsInMouse = seatsForSelection({
      mouseUpEvent: event,
      mouseDownEvent: event,
      boundingClientRect,
      transform,
      seats
    }).slice();

    if (seatsInMouse.length > 0 && selectedSeats.includes(seatsInMouse[0])) {
      return true;
    }
    return false;
  }

  private applyZoomLevel(zoomLevel?: number) {
    if (zoomLevel) {
      const { canvas, zoom } = this;

      this.applyInternalTransformToZoomWhenNecessary();

      // wait for next runloop to make sure internal transform was applied
      next(this, () => {
        zoom.scaleTo(canvas.transition().duration(500), zoomLevel / 100);
      });
    }
  }

  private cleanupRafLoop(name: 'interactionRafLoop' | 'zoomRafLoop') {
    const rafLoop = this[name];

    if (rafLoop) {
      rafLoop.stop();
      this[name] = null;
    }
  }

  private selectSeatsForSelection(event: MouseEvent) {
    const {
      seats: _seats,
      mouseDownEvent,
      boundingClientRect,
      transform
    } = this;

    if (mouseDownEvent) {
      const seats = _seats.slice();
      let selectedSeats = seatsForSelection({
        mouseUpEvent: event,
        mouseDownEvent,
        boundingClientRect,
        transform,
        seats
      });

      // Select only unblocked seats when it's a mix of blocked/unblocked
      if (!selectedSeats.every(seat => seat.blocked)) {
        selectedSeats = selectedSeats.filter(seat => !seat.blocked);
      }

      if (this.args.filterSelection) {
        selectedSeats = this.args.filterSelection?.(selectedSeats);
      }

      return selectedSeats;
    }
    return null;
  }

  private clearEvents() {
    if (this.mouseDownEvent !== null) {
      this.mouseDownEvent = null;
    }
    if (this.mouseMoveEvent !== null) {
      this.mouseMoveEvent = null;
    }
    if (this._temporaryMouseMoveEvent !== null) {
      this._temporaryMouseMoveEvent = null;
    }
    if (this.preselectedSeatsLength !== null) {
      this.preselectedSeatsLength = null;
    }
  }

  private setupMouseMoveBufferRaf() {
    function applyMouseMoveEvent(this: SeatManagementSeatMapComponent) {
      const {
        _temporaryMouseMoveEvent: mouseMoveEvent,
        isSelecting,
        isDragging
      } = this;

      if ((isSelecting || isDragging) && mouseMoveEvent) {
        this.mouseMoveEvent = mouseMoveEvent;
      }
    }

    const interactionRafLoop = new Raf(applyMouseMoveEvent.bind(this));

    this.interactionRafLoop = interactionRafLoop;

    interactionRafLoop.start();
  }
  private setupZoom(canvas: HTMLCanvasElement) {
    const { onZoom, onZoomSetup } = this;

    const zoom = d3Zoom<
      HTMLCanvasElement,
      { byX: number; byY: number; byK: number; toK: number }
    >()
      .filter(() => {
        const event = d3Event as Event;
        if (this.args.filterZoomPan) {
          const coordinates = getOriginalCanvasCoordinatesFromMouseEvent(
            event as MouseEvent,
            this.boundingClientRect,
            this.transform
          );
          return this.args.filterZoomPan(event, coordinates);
        }
        return this.filterZoomPan(event);
      })
      .scaleExtent([this.args.minZoom ?? 0.5, this.args.maxZoom ?? 2.5])
      .on('zoom', onZoom);

    d3Select(canvas).call(zoom);

    onZoomSetup(canvas, zoom);
  }

  private filterZoomPan(event: Event) {
    const eventType = event.type;

    if (eventType.startsWith('touch')) {
      return (event as TouchEvent).touches.length === 2;
    }

    return (event as MouseEvent).altKey;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SeatManagement::SeatMap': typeof SeatManagementSeatMapComponent;
    'seat-management/seat-map': typeof SeatManagementSeatMapComponent;
  }
}
