import type {
  RelationshipWithData,
  ResourceIdentifier
} from 'ticketoffice-api';

import type Model from '@ember-data/model';

function recordIdentifierFor(model: Model): { type: string; id: string } {
  if (!model) {
    throw new Error('recordIdentifierFor - no model');
  }

  // @ts-ignore - ember-data types incomplete
  const type = model.constructor?.modelName;
  const id = model.id;

  if (!type || !id) {
    throw new Error('recordIdentifierFor - no type or id for ' + model);
  }

  return { type, id };
}

import { pluralize } from 'ember-inflector';

function isResourceIdentifier(
  modelLike: Model | ResourceIdentifier
): modelLike is ResourceIdentifier {
  return (
    'id' in modelLike &&
    'type' in modelLike &&
    !!modelLike.id &&
    !!modelLike.type
  );
}

export function identity(
  model: Model | ResourceIdentifier
): ResourceIdentifier {
  let id, type;

  if (isResourceIdentifier(model)) {
    ({ id, type } = model);
  } else {
    ({ id, type } = recordIdentifierFor(model));
  }

  if (type === 'event-ticket-price') {
    // EventTicketPrices have an id in the format:
    // [event_id]-[ticket_price_id]
    // We need the latter half in order to create
    // line items correctly
    return { id: id.split('-')[1], type: 'ticket-prices' };
  }

  return { id, type: pluralize(type) };
}

export function extractIdentity(rel?: RelationshipWithData) {
  if (rel && rel.data && 'id' in rel.data) {
    return rel.data.id;
  }
  return null;
}

export function extractFirstIdentity(rel?: RelationshipWithData) {
  if (rel && rel.data && Array.isArray(rel.data) && 'id' in rel.data[0]) {
    return rel.data[0].id;
  }
  return null;
}
