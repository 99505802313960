import Component from '@glimmer/component';

interface TableCellSignature {
  Args: {
    colspan?: string;
    cellClass?: string;
  };
  Blocks: {
    default: [];
  };
  Element: HTMLTableCellElement;
}

export default class TableCell extends Component<TableCellSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Table::Td': typeof TableCell;
    'table/td': typeof TableCell;
  }
}
