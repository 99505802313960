import Service, { inject as service } from '@ember/service';

import type ConfigService from './config.ts';

export default class EnvironmentService extends Service {
  @service() config!: ConfigService;

  get key(): 'development' | 'test' | 'production' {
    return this.config.getValue('environment');
  }

  get isDevelopment(): boolean {
    return this.key === 'development';
  }

  get isTest(): boolean {
    return this.key === 'test';
  }

  get isProduction(): boolean {
    return this.key === 'production';
  }
}
