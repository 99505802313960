import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

interface TypeaheadDropdownItemSignature {
  Args: {
    selectedItem: any;
    item: any;
    onClick?: Function;
  };
  Blocks: {
    default: [unknown];
  };
}
export default class TypeaheadDropdownItemComponent extends Component<TypeaheadDropdownItemSignature> {
  @tracked isHovered = false;

  get isSelected() {
    return this.args.selectedItem === this.args.item || this.isHovered;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Typeahead::Dropdown::Item': typeof TypeaheadDropdownItemComponent;
    'typeahead/dropdown/item': typeof TypeaheadDropdownItemComponent;
  }
}
