import templateOnlyComponent from '@ember/component/template-only';

interface CanvasLayerSignature {
  Args: {};
  Element: HTMLCanvasElement;
}

const CanvasLayerComponent = templateOnlyComponent<CanvasLayerSignature>();

export default CanvasLayerComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Canvas::Layer': typeof CanvasLayerComponent;
    'canvas/layer': typeof CanvasLayerComponent;
  }
}
