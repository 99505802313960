import templateOnlyComponent from '@ember/component/template-only';

interface FormBaseValidationErrorSignature {
  Args: {
    testSelector?: string;
    guid?: string;
    error: { message: string };
    alignRight?: boolean;
  };
}

const FormBaseValidationErrorComponent =
  templateOnlyComponent<FormBaseValidationErrorSignature>();

export default FormBaseValidationErrorComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Form::Base::ValidationError': typeof FormBaseValidationErrorComponent;
    'form/base/validation-error': typeof FormBaseValidationErrorComponent;
  }
}
