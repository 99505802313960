import { registerDestructor } from '@ember/destroyable';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

import Modifier from 'ember-modifier';

import type EnvironmentService from '../services/environment.ts';
import { transformColor, mix, normalizeColor } from '../utils/color.ts';
import type { LookAndFeel } from '../utils/look-and-feel.ts';

const NOT_SET = '_not_set_';

/**
 * CSS Property Key
 *
 * Example: `backgroundColor`, `color`
 */
type CSSProperty = Exclude<
  {
    [K in keyof CSSStyleDeclaration]: CSSStyleDeclaration[K] extends string
      ? K
      : never;
  }[keyof CSSStyleDeclaration],
  number
>;
export type CSSPropertyValue = CSSStyleDeclaration[CSSProperty];

export type LookAndFeelKey = keyof LookAndFeel;

type OnMouseHover = Pick<
  BrandingOptions['named'],
  | 'alpha'
  | 'luminance'
  | 'saturation'
  | 'contrastedColor'
  | 'invertedColor'
  | 'mix'
>;

export interface BrandingOptions {
  /**
   * [0]: css property to apply
   * [1]: look and feel key
   */
  positional: [CSSProperty, LookAndFeelKey];

  named: {
    /**
     * if true, the change will apply, otherwise it'll be ignored
     */
    enabled?: boolean;
    /**
     * % alpha to add/remove on top of current color setting
     */
    alpha?: number;
    /**
     * % luminance to add/remove on top of current color setting
     */
    luminance?: number;
    /**
     * % saturation to add/remove on top of current color setting
     */
    saturation?: number;
    /**
     * % hue to add/remove on top of current color setting
     */
    hue?: number;
    /**
     * if true, it'll decide between black or white depending on current color setting
     */
    contrastedColor?: boolean;
    contrastedThreshold?: number;
    /**
     * if true, it inverse the color
     */
    invertedColor?: boolean;
    bgColor?: string;

    mix?: {
      color1: LookAndFeelKey | string;
      color2: LookAndFeelKey | string;
      weight: number;
    };

    /**
     * hash of transformation/s to be applied when hovering the element (hash alpha=1 luminance=20 saturation=30 contrastedColor=true)
     */
    onMouseHover?: OnMouseHover;
    /**
     * if true, the transformation will be applied only when hovering the element
     */
    mouseHoverOnly?: boolean;

    /**
     * Used to trigger a refresh (modify) in tandem with other branding
     * modifiers. E.g. one modifier may be "enabled" by some condition, to prevent it
     * overwriting another modifier it must also "watch" for changes to that statement
     * and re-apply if it changes
     */
    watch?: any;
  };
}

/**
 * This modifier is meant to customize an element style based on the look and
 * feel settings in each account / subdomain
 */
export default abstract class BaseBrandingModifier<
  T = BrandingOptions
> extends Modifier<T> {
  _element!: HTMLElement;
  named!: any;
  positional!: any[];
  isInstalled = false;

  private oldValue: CSSPropertyValue = NOT_SET;
  private beforeHover: CSSPropertyValue = NOT_SET;
  @service environment!: EnvironmentService;

  constructor(owner: BaseBrandingModifier<T>, args: any) {
    super(owner, args);
    registerDestructor(this, this.cleanup);
  }

  modify(...args: [Element, any, any]) {
    this.setup(...args);

    if (!this.isInstalled) {
      this.onInstall();
      this.isInstalled = true;
    }

    this.onModify();
  }

  setup(element: Element, positional: any[], named: any) {
    this._element = element as HTMLElement;
    this.positional = positional;
    this.named = named;
  }

  onInstall() {
    this.addHoverListeners();
  }

  cleanup(context: BaseBrandingModifier<T>) {
    if (context.onMouseHover) {
      context._element.removeEventListener(
        'mouseover',
        context.onMouseEnter,
        true
      );
      context._element.removeEventListener('mouseleave', context.onMouseLeave);
    }
  }

  onModify() {
    if (this.oldValue === NOT_SET) {
      this.oldValue = this._element.style[this.property];
    }

    if (this.mouseHoverOnly) {
      // If the element is currently being hovered
      // we need to manually apply the hover style
      // on refresh, otherwise another branding modifier
      // can overwrite it
      if (this.beforeHover !== NOT_SET) this.onMouseEnter();
    } else {
      if (this.isEnabled) {
        this._element.style[this.property] = this.getValue(
          this.alpha,
          this.luminance,
          this.saturation,
          this.contrastedColor,
          this.invertedColor,
          this.mixColors
        );
      } else if (this.oldValue !== NOT_SET) {
        this._element.style[this.property] = this.oldValue;
      }
    }
  }

  addHoverListeners() {
    if (this.onMouseHover) {
      this._element.addEventListener('mouseover', this.onMouseEnter, true);
      this._element.addEventListener('mouseleave', this.onMouseLeave);
    }
  }

  protected getOptions(): BrandingOptions {
    return {
      positional: this.positional as BrandingOptions['positional'],
      named: this.named
    };
  }

  private get options(): BrandingOptions {
    return this.getOptions();
  }

  abstract getLookAndFeel(
    key: LookAndFeelKey | CSSPropertyValue
  ): CSSPropertyValue;

  private get isMouseHoverEnabled(): boolean {
    // If `onMouseHover` is removed after a render
    // we should not trigger onMouseLeave/Enter
    return !!(this.isEnabled && this.onMouseHover);
  }

  // Positional Args
  protected get property(): CSSProperty {
    return this.options.positional[0];
  }
  protected get key(): LookAndFeelKey {
    return this.options.positional[1];
  }

  // Named Args
  private get isEnabled() {
    return this.options.named.enabled ?? true;
  }
  get contrastedColor() {
    return this.options.named.contrastedColor ?? false;
  }
  private get invertedColor() {
    return this.options.named.invertedColor ?? false;
  }
  private get alpha() {
    return (this.options.named.alpha ?? 100) / 100;
  }
  private get luminance() {
    return this.options.named.luminance ?? 0;
  }
  private get saturation() {
    return this.options.named.saturation ?? 0;
  }
  private get hue() {
    return this.options.named.hue ?? 0;
  }
  private get mouseHoverOnly() {
    return this.options.named.mouseHoverOnly;
  }
  private get onMouseHover() {
    return this.options.named.onMouseHover;
  }
  private get mixColors(): BrandingOptions['named']['mix'] | null {
    if (!this.options.named.mix) {
      return null;
    }

    const { mix } = this.options.named;

    return {
      color1: this.getLookAndFeel(mix.color1 as LookAndFeelKey) ?? mix.color1,
      color2: this.getLookAndFeel(mix.color2 as LookAndFeelKey) ?? mix.color2,
      weight: mix.weight
    };
  }
  private get hoverMixColors(): BrandingOptions['named']['mix'] | null {
    if (!this.options.named.onMouseHover?.mix) {
      return null;
    }

    const { mix } = this.options.named.onMouseHover;

    return {
      color1: this.getLookAndFeel(mix.color1 as LookAndFeelKey) ?? mix.color1,
      color2: this.getLookAndFeel(mix.color2 as LookAndFeelKey) ?? mix.color2,
      weight: mix.weight
    };
  }

  get value(): CSSPropertyValue {
    return this.getLookAndFeel(this.key);
  }

  private get isUrl() {
    return this.value.match(/https?:\/\//i);
  }

  private get isColor() {
    return this.property.match(/color/i);
  }

  isColorProp(
    color: LookAndFeelKey | CSSPropertyValue
  ): color is CSSPropertyValue {
    return CSS.supports('color', color);
  }

  private get isFont() {
    return this.property.match(/fontFamily/i);
  }

  getValue(
    alpha = this.alpha,
    luminance = this.luminance,
    saturation = this.saturation,
    contrastedColor = false,
    invertedColor = false,
    mixColors: BrandingOptions['named']['mix'] | null = this.mixColors,
    hue = this.hue
  ) {
    let value = this.value;
    if (!value) {
      return value;
    }
    if (this.isColor) {
      if (mixColors) {
        value = mix(mixColors.color1, mixColors.color2, mixColors.weight);
      } else {
        value = transformColor(normalizeColor<string>(value), {
          alpha,
          luminance,
          saturation,
          contrastedColor,
          invertedColor,
          bgColor: this.options.named.bgColor,
          contrastedThreshold: this.options.named.contrastedThreshold,
          hue
        });
      }
    } else if (this.isUrl) {
      value = `url(${value})`;
    } else if (this.isFont) {
      let font = value;
      if (this.environment.isProduction) {
        font = `"CountryFlagEmoji", ${font}`; // Add font as fallback for country flags
      }
      value = font
        .replace(';', '')
        .split(',')
        .map(fontFamily => {
          fontFamily = fontFamily.trim();
          if (!/\s/g.test(fontFamily)) {
            // Does not contain whitespace
            return fontFamily;
          }
          if (/^".*"$/g.test(fontFamily) || /^'.*'$/g.test(fontFamily)) {
            // Already quoted
            return fontFamily;
          }
          return `"${fontFamily}"`;
        })
        .join(', ');
    }
    return value;
  }

  @action
  private onMouseEnter() {
    if (this.isMouseHoverEnabled) {
      if (this.beforeHover === NOT_SET) {
        this.beforeHover = this._element.style[this.property];
      }

      let { alpha, luminance, saturation, contrastedColor, invertedColor } =
        this.onMouseHover as OnMouseHover;
      alpha = alpha ? alpha / 100 : alpha;
      this._element.style[this.property] = this.getValue(
        alpha,
        luminance,
        saturation,
        contrastedColor,
        invertedColor,
        this.hoverMixColors
      );
    }
  }
  @action
  private onMouseLeave() {
    if (this.isMouseHoverEnabled && this.beforeHover !== NOT_SET) {
      this._element.style[this.property] = this.beforeHover;
    }
    this.beforeHover = NOT_SET;
  }
}
