import { readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Service from '@ember/service';

import type WindowService from './-window-base.ts';

export default class FullStoryService extends Service {
  @service() window!: WindowService;

  @readOnly('window.fs') _fs!: Window['FS'];

  // https://help.fullstory.com/develop-js/137379
  identify(uid?: string, userVars?: any): void {
    return this._fs.identify(uid, userVars);
  }
}
