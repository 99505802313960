type Circle = {
  x: number;
  y: number;
  r: number;
};

export type Transform = {
  x: number;
  y: number;
  k: number;
};

export type Rectangle = {
  x: number;
  y: number;
  width: number;
  height: number;
};

export type Offset = { x: number; y: number };

export type Coordinates2D = [number, number];

// we need to correct coordinates received from the backend when drawing seats
// - coordinates for cx and cy are 5px off
export const CIRCLE_CORRECTION = 5;
export const CIRCLE_RADIUS = 5;
export const CIRCLE_STROKE = 1;

// https://stackoverflow.com/a/21096179
export function rectCircleCollision(circle: Circle, rect: Rectangle): boolean {
  const distX = Math.abs(circle.x - rect.x - rect.width / 2);
  const distY = Math.abs(circle.y - rect.y - rect.height / 2);

  if (distX > rect.width / 2 + circle.r) {
    return false;
  }
  if (distY > rect.height / 2 + circle.r) {
    return false;
  }

  if (distX <= rect.width / 2) {
    return true;
  }
  if (distY <= rect.height / 2) {
    return true;
  }

  const dx = distX - rect.width;
  const dy = distY - rect.height;

  return dx * dx + dy * dy <= circle.r * circle.r;
}

export function createRectangleFromCoordinates(
  [x1, y1]: Coordinates2D,
  [x2, y2]: Coordinates2D
): Rectangle {
  const xLeft = Math.min(x1, x2);
  const yUp = Math.min(y1, y2);
  const xRight = Math.max(x1, x2);
  const yDown = Math.max(y1, y2);

  // if width/height is 0 (when we clicked) we assume at least width/height of 1
  return {
    x: xLeft,
    y: yUp,
    width: (xRight - xLeft) | 1,
    height: (yDown - yUp) | 1
  };
}

export function getCanvasCoordinatesFromMouseEvent(
  event: MouseEvent | TouchEvent,
  rect: DOMRect,
  offsetContainer?: HTMLElement
): Coordinates2D {
  const clientX =
    event instanceof MouseEvent
      ? event.clientX
      : event.changedTouches
        ? event.changedTouches[0].clientX
        : // @ts-ignore - Safari might not supported `changedTouches`
          event.clientX;
  const clientY =
    event instanceof MouseEvent
      ? event.clientY
      : event.changedTouches
        ? event.changedTouches[0].clientY
        : // @ts-ignore - Safari might not supported `changedTouches`
          event.clientY;

  const x = ((clientX - rect.left) / (rect.right - rect.left)) * rect.width;
  const y = ((clientY - rect.top) / (rect.bottom - rect.top)) * rect.height;
  const { scrollLeft = 0, scrollTop = 0 } = offsetContainer ?? {};
  return [Math.round(x + scrollLeft), Math.round(y + scrollTop)];
}

// Without zoom or panning
export function getOriginalCanvasCoordinatesFromMouseEvent(
  event: MouseEvent | TouchEvent,
  rect: DOMRect,
  transform: Transform,
  offsetContainer?: HTMLElement
): Coordinates2D {
  const { x: _x, y: _y, k } = transform;
  const [x, y] = getCanvasCoordinatesFromMouseEvent(
    event,
    rect,
    offsetContainer
  );

  return [(x - _x) / k, (y - _y) / k];
}

export function transformCoords(
  [x, y]: Coordinates2D,
  transform: Transform
): Coordinates2D {
  const { k } = transform;

  return [Math.round(x / k), Math.round(y / k)];
}

export function reverseTransformedCoordsFromMouseEvent(
  event: MouseEvent,
  rect: DOMRect,
  transform: Transform
): Coordinates2D {
  const [x, y] = getCanvasCoordinatesFromMouseEvent(event, rect);

  const { x: _x, y: _y, k } = transform;

  const transformedCoords = {
    x: (x - _x) / k,
    y: (y - _y) / k
  };

  return [
    transformedCoords.x - CIRCLE_CORRECTION,
    transformedCoords.y - CIRCLE_CORRECTION
  ];
}

export function transformRect(
  { x = 0, y = 0, width = 1, height = 1 }: Rectangle,
  transform: Transform
): Rectangle {
  const { x: _x, y: _y, k } = transform;

  return {
    x: (x - _x) / k,
    y: (y - _y) / k,
    width: width / k,
    height: height / k
  };
}

export function reverseTransformRect(
  { x = 0, y = 0, width = 1, height = 1 }: Rectangle,
  transform: Transform
): Rectangle {
  const { x: _x, y: _y, k } = transform;
  return {
    x: x * k + _x,
    y: y * k + _y,
    width: width * k,
    height: height * k
  };
}

export function circleCoordinatesForXY(
  x: number,
  y: number,
  correction = CIRCLE_CORRECTION
): Coordinates2D {
  return [x + correction, y + correction];
}

/**
 * Method to calculate a transform that will make a source object with width/height
 * fit the maximum amount of space of a destination object. This method can be used
 * to calculate a transform that will make a seat-grid fit int a destination
 * canvas and center the plan.
 *
 * E.g. you have a canvas that you know is 500px wide to 500px high. If you want
 * to fit a seat-grid of 300px to 400px into it but display it as big as possible
 * while still maintaining the seat-grids aspect-ration + centering it - you can
 * use this method to get a transform that will fit the grid.
 *
 * @param {Object} [params] Necessary data to calculate transform.
 *   @param destinationWidth The width of the destination element
 *   @param destinationHeight The height of the destination element
 *   @param sourceWidth The height of the source element that you want to display
 *   @param sourceHeight The width of the source element that you want to display
 *   @param margin A margin that should be applied to the edges of the destination element
 */
export function maxCenteredScaledTransformForDestination({
  destinationWidth,
  destinationHeight,
  sourceWidth,
  sourceHeight,
  margin
}: {
  destinationWidth: number;
  destinationHeight: number;
  sourceWidth: number;
  sourceHeight: number;
  margin: number;
}): Transform {
  const maxWidth = destinationWidth - margin * 2;
  const maxHeight = destinationHeight - margin * 2;

  const scaleX = maxWidth / sourceWidth;
  const scaleY = maxHeight / sourceHeight;
  const scaleToFit = Math.min(scaleX, scaleY);

  return {
    x: (destinationWidth - sourceWidth * scaleToFit) / 2,
    y: (destinationHeight - sourceHeight * scaleToFit) / 2,
    k: scaleToFit
  };
}

export function createSelectionRectangleFromMouseEvents(
  startEvent: MouseEvent,
  endEvent: MouseEvent,
  boundingClientRect: DOMRect,
  transform: Transform,
  offsetContainer?: HTMLElement
) {
  const mouseDownCoordinates = getCanvasCoordinatesFromMouseEvent(
    startEvent,
    boundingClientRect,
    offsetContainer
  );
  const mouseUpCoordinates = getCanvasCoordinatesFromMouseEvent(
    endEvent,
    boundingClientRect,
    offsetContainer
  );

  const rect = createRectangleFromCoordinates(
    mouseDownCoordinates,
    mouseUpCoordinates
  );

  return transformRect(rect, transform);
}

export function drawSingleColoredCircle(
  context: CanvasRenderingContext2D,
  cx: number,
  cy: number,
  fill: string,
  stroke?: string | null
) {
  const radius = stroke
    ? Math.floor(CIRCLE_RADIUS - CIRCLE_STROKE / 2)
    : CIRCLE_RADIUS;
  context.beginPath();
  context.fillStyle = fill;
  context.arc(cx, cy, radius, 0, 2 * Math.PI, true);
  context.fill();
  if (stroke) {
    context.lineWidth = 2;
    context.strokeStyle = stroke;
    context.stroke();
  }
}

export function drawDiagonalLineInCircle(
  context: CanvasRenderingContext2D,
  cx: number,
  cy: number
) {
  // find out where to start the strike-through line 45% to the arc from the center
  const length = Math.sqrt(CIRCLE_RADIUS ** 2 / 2);
  const x1 = cx + length;
  const y1 = cy - length;
  const x2 = cx - length;
  const y2 = cy + length;
  context.moveTo(x1, y1);
  context.lineTo(x2, y2);
  context.stroke();
}

export function drawCross(
  context: CanvasRenderingContext2D,
  cx: number,
  cy: number
) {
  // find out where to start the strike-through line 45% to the arc from the center
  const length = Math.sqrt(CIRCLE_RADIUS ** 2 / 2) + 1;
  const x1 = cx + length;
  const y1 = cy - length;
  const x2 = cx - length;
  const y2 = cy + length;
  context.beginPath();

  context.moveTo(x1, y1);
  context.lineTo(x2, y2);

  context.moveTo(x2, y1);
  context.lineTo(x1, y2);

  context.stroke();
}
