import templateOnlyComponent from '@ember/component/template-only';

interface FormBaseValidationWarningSignature {
  Args: {
    testSelector?: string;
    guid?: string;
    warning: { message: string };
  };
}

const FormBaseValidationWarningComponent =
  templateOnlyComponent<FormBaseValidationWarningSignature>();

export default FormBaseValidationWarningComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Form::Base::ValidationWarning': typeof FormBaseValidationWarningComponent;
    'form/base/validation-Warning': typeof FormBaseValidationWarningComponent;
  }
}
