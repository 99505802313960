import { computed } from '@ember/object';
import Service, { inject as service } from '@ember/service';

import MemoryStorage from '../stores/memory.ts';
import type ConfigService from './config.ts';

/**
 * Detect if localStorage is supported AND available
 * e.g. in Safari Private Browsing, it is supported but will explode on use
 */
function detectLocalStorageEnabled(): boolean {
  try {
    window.localStorage.setItem('to_localStorage', String(true));
    return !!window.localStorage;
  } catch (e) {
    console.error(e);
    return false;
  }
}

export default class StorageService extends Service {
  @service private config!: ConfigService;

  localStorageEnabled = detectLocalStorageEnabled();

  memoryStorage: MemoryStorage = new MemoryStorage();

  /**
   * Return the localStorage object or an empty passthrough handler
   */
  /* eslint-disable ember/require-computed-property-dependencies */
  @computed('localStorageEnabled')
  get localStorage(): Window['localStorage'] | MemoryStorage {
    if (this.localStorageEnabled) {
      return window.localStorage;
    } else {
      return this.memoryStorage;
    }
  }

  getLocalStorageKey(key: string): string {
    const prefix = this.config.getValue('localStoragePrefix', '') as string;
    return prefix ? `${prefix}_${key}` : key;
  }

  clear(): any {
    return this.localStorage.clear();
  }

  fetch(key: string): any {
    return this.localStorage.getItem(key);
  }

  add(key: string, value: string): any {
    return this.localStorage.setItem(key, value);
  }

  remove(key: string): any {
    return this.localStorage.removeItem(key);
  }

  getArray(key: string): any {
    const json = this.fetch(key);
    return json ? JSON.parse(json) : [];
  }

  setArray(key: string, arr: any[]) {
    this.add(key, JSON.stringify(arr));
  }

  pushToArray(key: string, hash: any) {
    const arr = this.getArray(key);
    arr.push(hash);
    this.add(key, JSON.stringify(arr));
  }
}
