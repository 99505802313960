import { isBlank } from '@ember/utils';

import { isKeyValueObject } from '../utils/utils.ts';

/**
 * @module Regex
 * @public
 *
 * This module is used to create or match regex expressions. It providers
 * a set of options & defaults for common use cases such as when
 * searching a list.
 */

type DelimiterName = 'search' | 'escapeRegExp';

type ToRegexpOpts = {
  flags?: string;
};

type RegexpListFromTermOpts = {
  delimiters?: string;
  flags?: string;
  toRegexFn?: (term: string, opts?: ToRegexpOpts) => RegExp;
};
type ReplaceKeyNamesOpts = {
  toRegexpFn?: Function;
};

type AttemptMatchOpts = {
  ignoreValues: string[];
};

/**
 * This function provides sets of delimiters that are commonly
 * used when creating or otherwise working with RegExp.

 * @method getDelimiters
 * @param {String} The name of a set of delimiters
 * @return RegExp
 */
function getDelimiters(type?: DelimiterName): RegExp {
  switch (type) {
    case 'search':
      return /[\s|,|+]+/;
    case 'escapeRegExp':
      return /[.*+?^${}()|[\]\\]/;
    default:
      return /[\s|,|+]+/;
  }
}

/**
 * This function will escape any special characters that may be used in a
 * regexp expression by default. You may also pass a regexp  of your own.
 *
 * @method escapeString
 * @param str {String} The string which will be escaped
 * @param opts {{regexp?: Regexp}} An optional RegExp that can be used instead
 * @returns String
 */
function escapeString(str: string, opts?: { regexp: RegExp | string }): string {
  const regexp = opts?.regexp ?? getDelimiters('escapeRegExp');
  return str.replace(regexp, '\\$&');
}

/**
 * Used to turn some term of type string into a RegExp
 * expression.
 *
 * @method toRegexp
 * @param term {String} The term to be converted into a regex
 * @param opts {ToRegexpOpts} A set of options to be applied
 * to the conversion
 * @return RegExp
 */
function toRegexp(term: string = '', opts?: ToRegexpOpts): RegExp {
  return new RegExp(`(${escapeString(term)})`, opts?.flags);
}

/**
 * Given a string this function returns a list of Regexps.
 * The string is split by a set of delimiters, if none are
 * provided then the default set of dilimters from
 * `getDelimiters` will be used.
 *
 * @method listFromTerm
 * @param term {String} The term to be converted into list
 * @param opts {RegexpListFromTermOpts} A set of options
 * @return RegExp[]
 */
function listFromTerm(term: string, opts?: RegexpListFromTermOpts): RegExp[] {
  if (isBlank(term)) return [];

  const delimiters = opts?.delimiters ?? getDelimiters();
  const termToRegexFn = opts?.toRegexFn ?? toRegexp;
  const flags = opts?.flags ?? '';

  return term
    .split(delimiters)
    .filter(term => !isBlank(term))
    .map(t => termToRegexFn(t, { flags }));
}

/**
 * Given some RegExp expression attempt to match the
 * supplied value according tot the options passed. If
 * no options are passed then defaults will apply.
 *
 * @method attemptMatch
 * @param regex {RegExp} The regex to use in the attempted match
 * @param value {String | Number} The value to attempt the match on
 * @param opts  {AttemptMatchOpts} An options hash
 * @return RegExpMatchArray | null
 */
function attemptMatch(
  regex: RegExp,
  value: string | number,
  opts?: AttemptMatchOpts
): RegExpMatchArray | null {
  const ignoreValues: any[] = opts?.ignoreValues ?? [null, undefined];

  if (ignoreValues.includes(value)) return null;

  if (typeof value === 'number') return `${value}`.match(regex);

  return value.match(regex);
}

/**
 * This function is used to replace the key names of an object
 * with the string passed is (replaceWith).
 *
 * @method replaceKeyNames
 * @param object {KeyValueObject} The object whose keys are to be replaced
 * @param toReplace {String} A string containing the characters to replace
 * @param replaceWith {KeyValueObject} The value to replace the characters with
 * @param opts {ReplaceKeyNamesOpts} Options, such as custom regexp to match against
 * @returns String
 */
function replaceKeyNames(
  obj: KeyValueObject,
  toReplace: string,
  replaceWith: string,
  opts?: ReplaceKeyNamesOpts
): KeyValueObject {
  const toRegexpFn = opts?.toRegexpFn ?? toRegexp;

  return Object.keys(obj).reduce((acc: KeyValueObject, key: string) => {
    const value = obj[key];

    const newKey = key.replace(toRegexpFn(toReplace), replaceWith);

    acc[newKey] = isKeyValueObject(value)
      ? replaceKeyNames(value, toReplace, replaceWith, opts)
      : value;

    return acc;
  }, {});
}

export default {
  toRegexp,
  escapeString,
  attemptMatch,
  listFromTerm,
  getDelimiters,
  replaceKeyNames
};
