import { tracked } from '@glimmer/tracking';
import isEqual from 'lodash-es/isEqual';

import { assert } from '@ember/debug';
import { getProperties } from '@ember/object';

export type Transform = {
  [key: string]: any;
};

type Hooks<Shape> = {
  onChange?: (formObject: FormObject<Shape>) => void;
};

export class FormObjectBase {}

class FormObject<Shape> extends FormObjectBase {
  @tracked
  transforms: Transform[] = [];

  original: Shape;
  _props: Array<keyof Shape>;
  hooks?: Hooks<Shape>;

  constructor(
    properties: Shape extends FormObject<any>
      ? Omit<{ [k in keyof Shape]: Shape[k] }, keyof FormObject<{}>>
      : Shape,
    hooks?: Hooks<Shape>
  ) {
    super();

    this.initialize(properties as Shape);
    this.hooks = hooks;
  }

  protected initialize(properties: Shape) {
    this.original = Object.assign({}, properties);
    // @ts-ignore
    this._props = Object.keys(properties);

    // copy all properties to this context
    Object.assign(this, properties);
  }

  // to be overriden in subclasses
  cleanup() {}

  get hasChanges() {
    return !this.isPristine;
  }

  get isPristine() {
    return isEqual(this.original, this.propertiesToSync);
  }

  get propertiesToSync() {
    // @ts-ignore
    return getProperties(this, this._props);
  }

  change(transform: Transform) {
    this.transforms = [...this.transforms, transform];

    assert(
      `Transform contains unknown keys please add them to the form object constructor ('${Object.keys(
        transform
      )}' not in '${this._props}')`,
      Object.keys(transform).every(key =>
        this._props.includes(key as keyof Shape)
      )
    );

    Object.assign(this, transform);

    this.hooks?.onChange?.(this);
  }

  reset() {
    if (!this.isPristine) {
      this.transforms = [];
      Object.assign(this, this.original);
    }
  }

  /**
   * Work in progress
   *
   * @deprecated
   */
  resetOriginal(original: any) {
    this.transforms = [];
    // @ts-ignore
    this.original = original;
  }
}

export class DynamicFormObject extends FormObject<{}> {
  constructor(properties: Record<string, any>) {
    // Initialize Empty
    super({});

    this.initializeDynamicProperties(properties);
  }

  initializeDynamicProperties(properties: Record<string, any>) {
    // Define tracked properties
    this.defineTrackedProperties(properties);

    // Reinitialize
    super.initialize(properties);
  }

  private defineTrackedProperties(properties: Record<string, any>) {
    Object.keys(properties).forEach(key => {
      const value = properties[key];
      this.defineTrackedProperty(key, value);
    });
  }

  private defineTrackedProperty(key: string, initValue: any) {
    const descriptor: PropertyDescriptor & { initializer: Function } = {
      configurable: true,
      enumerable: true,
      writable: true,
      initializer: () => initValue
    };

    // @ts-ignore - tracked not typed properly
    Object.defineProperty(this, key, tracked(this, key, descriptor));
  }
}

export default FormObject;
