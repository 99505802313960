import { helper } from '@ember/component/helper';

export function preventDefault(event: Event) {
  event.preventDefault();
}

export function preventDefaultFunc([action]: [Function]) {
  return function (event: Event) {
    preventDefault(event);
    return typeof action === 'function' ? action(event) : event;
  };
}
export default helper(preventDefaultFunc);
