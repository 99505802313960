import { tracked } from '@glimmer/tracking';
import isObject from 'lodash-es/isObject';
import without from 'lodash-es/without';

import Service, { inject as service } from '@ember/service';
import Ember from 'ember';

import { task, timeout } from 'ember-concurrency';
import type { TaskInstance } from 'ember-concurrency';

import type {
  Error,
  ServerValidationErrorCollection
} from '../utils/errors.ts';
import { getAllUserMessagesOrDefault } from '../utils/errors.ts';
import type ConfigService from './config.ts';

export type ErrorArg = string | Error | Error[] | NotificationOptions;

export type NotificationType =
  | 'success'
  | 'warning'
  | 'error'
  | 'neutral'
  | 'intermediate';

type NotificationArguments =
  | [message: string]
  | [message: string, options: NotificationOptions]
  | [options: NotificationOptions];

export type NotificationOptions = {
  header?: string;
  list?: string[];
  message?: string;
  defaultMsg?: string;
  historyDisabled?: boolean;
  testSelector?: string;
  manual?: boolean;
  displayAsList?: boolean;
  closeable?: boolean;
  action?: Function;
  actionLabel?: string;

  id?: string;
};

export type Notification = NotificationOptions & {
  type: NotificationType;
};

type NotificationHistory = {
  type: NotificationType;
  message: string;
  options: NotificationOptions;
  time: Date;
};

const DISPLAY_OPTS_KEYS = [
  'header',
  'list',
  'defaultMsg',
  'historyDisabled'
] as const;

function mapArgumentsToOptions(
  args: NotificationArguments
): NotificationOptions {
  if (typeof args[0] === 'string') {
    return { message: args[0], ...args[1] };
  }
  return args[0];
}

/**
 * A service for displaying notifications to the user.
 *
 * Provides detailed options via the `NotificationOptions` interface.
 * Creates a ember-concurrency TaskInstance for each notification, which contains
 * the notification options and the type of notification in the first argument.
 */
export default class NotificationsService extends Service {
  @service private config!: ConfigService;

  @tracked queue: TaskInstance<void>[] = [];

  history: NotificationHistory[] = [];

  private get DISPLAY_MS(): number {
    return this.config.getValue('tangram.notifications.displayMs', 5000);
  }
  private get TRANSITION_MS(): number {
    return this.config.getValue('tangram.notifications.transitionMs', 500);
  }
  private get envTimeout(): Function {
    // Tests do not wait for flash notifications
    return Ember.testing
      ? (ms: number) => new Promise(resolve => window.setTimeout(resolve, ms))
      : timeout;
  }

  private displayTask = task(
    async (type: NotificationType, options: NotificationOptions) => {
      if (options.id) {
        this.removeTaskById(options.id);
      }

      let showTask: TaskInstance<void>;
      try {
        showTask = this.showTask.perform({ type, ...options });
        this.queue = [...this.queue, showTask];
        await showTask;
      } finally {
        await this.envTimeout(this.TRANSITION_MS);
        this.queue = without(this.queue, showTask);
      }
    }
  );

  private showTask = task(async ({ manual }: Notification) => {
    return manual ? new Promise(() => {}) : this.envTimeout(this.DISPLAY_MS);
  });

  private display(
    type: NotificationType,
    options: NotificationOptions
  ): TaskInstance<void> {
    const { header, message, list, historyDisabled } = options;

    if (header && !message && !list) {
      throw 'Notifications with a `header` need to define either a `message` or a `list`';
    }

    const taskInstance = this.displayTask.perform(type, options);

    if (!historyDisabled) {
      this.history.push({ type, message, options, time: new Date() });
    }

    return taskInstance;
  }

  // Convenience helpers
  //
  // errors: [{
  //   title: "Internal Server Error",
  //   detail: "Internal Server Error",
  //   code: "500",
  //   status: "500",
  // }]
  //
  // or
  //
  // errors: [{
  //   title: "Name Of Error",
  //   detail: "Localized info about error",
  //   status: "422",
  //   code: "422",
  // }]
  //
  serverException(ex: ServerValidationErrorCollection): void {
    const { errors } = ex;
    if (!errors) {
      // not a server exception
      throw ex;
    }
    const list = errors.map(error => error.detail);
    if (errors.length > 1) {
      this.error(ex, { list });
    } else {
      this.error(ex, { message: list[0] });
    }
  }

  protected isNotificationOptions(
    param: ErrorArg
  ): param is NotificationOptions {
    if (!isObject(param)) return false;
    // @ts-ignore
    return !!DISPLAY_OPTS_KEYS.find(key => key in param);
  }

  success(...args: NotificationArguments): void {
    this.display('success', mapArgumentsToOptions(args));
  }

  warning(...args: NotificationArguments): void {
    this.display('warning', mapArgumentsToOptions(args));
  }

  neutral(...args: NotificationArguments): void {
    this.display('neutral', mapArgumentsToOptions(args));
  }

  intermediate(...args: NotificationArguments): TaskInstance<void> {
    return this.display('intermediate', mapArgumentsToOptions(args));
  }

  error(error: ErrorArg, opts: NotificationOptions = {}): void {
    if (this.isNotificationOptions(error)) {
      // If the first option is a display opts skip to display
      this.display('error', error);
    } else if (typeof error === 'string') {
      // If just a string is given display the error
      this.display('error', { message: error, ...opts });
    } else if (opts?.message || opts?.list) {
      // If an explicit error message was given use that
      this.display('error', opts);
    } else {
      // Else attempt to get the error messages from the error itself
      // If more than one error is found a list will be shown
      // If nothing is found the defaultMsg will be shown or if not
      // given the default message from the errors lib.
      const messages = getAllUserMessagesOrDefault(error, opts);

      messages.length > 1 || opts.displayAsList
        ? this.display('error', { ...opts, list: messages })
        : this.display('error', { ...opts, message: messages[0] });
    }
  }

  removeTaskById(id: string): void {
    const instance = this.queue.find(
      (task: TaskInstance<void> & { args: any[] }) => task.args[0].id,
      id
    );
    if (instance) {
      this.queue = without(this.queue, instance);
    }
  }
}
