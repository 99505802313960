import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import { htmlSafeSanitized } from '../helpers/html-safe-sanitized.ts';
import Regex from '../lib/regex.ts';
interface HighlightTextSignature {
    Args: {
        term: string | null;
        text: string;
    };
}
export default class HighlightText extends Component<HighlightTextSignature> {
    get highlightedText() {
        const text1 = `${this.args.text}` ?? '';
        const searchRegexes1 = Regex.listFromTerm(this.args.term, {
            flags: 'gi'
        });
        const highlightedText1 = searchRegexes1.reduce((ht1, regex1)=>ht1.replace(regex1, '<~~$1~~>'), text1).replace(/<~~/g, '<strong>').replace(/~~>/g, '</strong>');
        return htmlSafeSanitized([
            highlightedText1
        ]);
    }
    // Note: Don't add whitespace around the `highlightedText`
    // It might be wrapped in a container with a preserving white-space css style
    static{
        template(`{{this.highlightedText}}`, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
