import { Renderer } from '../components/canvas.ts';
import type { Transform } from '../utils/canvas-drawing.ts';
import { CIRCLE_RADIUS } from '../utils/canvas-drawing.ts';
import type { SeatBase } from '../utils/seat-editor.ts';
import { circleCoordinatesForSeat } from '../utils/seat-editor.ts';

export default class SeatsSelection extends Renderer {
  render(
    transform: Transform,
    width: number,
    height: number,
    selectedSeats: SeatBase[]
  ) {
    const { context } = this;

    if (context) {
      context.save();
      context.clearRect(0, 0, width, height);
      context.translate(transform.x, transform.y);
      context.scale(transform.k, transform.k);

      selectedSeats.forEach(this._drawSelectedCircle.bind(this));

      context.restore();
    }
  }

  _drawSelectedCircle(seat: SeatBase) {
    const { context } = this;
    const [cx, cy] = circleCoordinatesForSeat(seat);

    context.beginPath();

    context.arc(cx, cy, CIRCLE_RADIUS, 0, 2 * Math.PI, true);
    context.lineWidth = 2;
    context.stroke();
  }
}
