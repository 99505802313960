import { schedule } from '@ember/runloop';

import { modifier } from 'ember-modifier';

export default modifier((element: HTMLElement, _, named = { cond: true }) => {
  if (named.cond !== undefined && !named.cond) return;
  schedule('afterRender', null, function () {
    element.focus();
  });
});
