import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isBlank } from '@ember/utils';

import type CurrencyService from '../../../services/currency.ts';

interface FormCurrencyBaseSignature {
  Args: {
    value: any;
    onChange: (value: number | string | null) => void;
    markInteractedWith: () => void;
    displayErrors: boolean;
  };
  Element: HTMLInputElement;
}

export default class FormCurrencyBaseComponent extends Component<FormCurrencyBaseSignature> {
  @service currency!: CurrencyService;

  @tracked isFocus = false;

  get formattedValue() {
    const { value } = this.args;
    if (isBlank(value) || isNaN(value)) {
      return '';
    }
    return parseFloat(value).toFixed(2);
  }

  @action
  handleInput(event: KeyboardEvent & { target: HTMLInputElement }) {
    this.args.onChange(event.target.value);
    this.isFocus = true;
  }

  @action
  handleBlur() {
    this.args.markInteractedWith();

    const { value } = this.args;
    const serialized =
      isBlank(value) || isNaN(value)
        ? null
        : parseFloat(parseFloat(value).toFixed(2));
    if (value !== serialized) {
      this.args.onChange(serialized);
    }
    this.isFocus = false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Form::Currency::Base': typeof FormCurrencyBaseComponent;
    'form/currency/base': typeof FormCurrencyBaseComponent;
  }
}
