import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import { action } from '@ember/object';

import type { DropdownOption } from '../../dropdown.ts';

interface DropdownTriggerNativeSignature {
  Args: {
    dropdownOptions: DropdownOption[];
    id: string;
    filterByLabel?: string;
    guid: string;
    isInvalid?: boolean;
    hasSearch?: boolean;
  };
  Element: HTMLSelectElement;
}

export default class DropdownTriggerNativeComponent extends Component<DropdownTriggerNativeSignature> {
  @tracked filterText = '';

  get filterByLabel() {
    return this.args.filterByLabel || 'Filter results by';
  }

  get hasFilteredResults() {
    return this.filterText !== '';
  }

  get ariaDescribedBy() {
    if (this.args.isInvalid) return `error:${this.args.guid}`;
    return this.args.hasSearch ? `filterSelect:${this.args.guid}` : '';
  }

  @action
  promptForFilter() {
    this.filterText = window.prompt(this.filterByLabel, this.filterText) || '';
    (document.querySelector(`#${this.args.id}`) as HTMLSelectElement).focus();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Dropdown::Trigger::Native': typeof DropdownTriggerNativeComponent;
    'dropdown/trigger/native': typeof DropdownTriggerNativeComponent;
  }
}
