import { template } from "@ember/template-compiler";
import Component from '@glimmer/component';
import type { WithBoundArgs } from '@glint/template';
import { fn } from '@ember/helper';
import { action } from '@ember/object';
import didInsert from '@ember/render-modifiers/modifiers/did-insert.js';
import willDestroy from '@ember/render-modifiers/modifiers/will-destroy.js';
import type RouterService from '@ember/routing/router';
import { inject as service } from '@ember/service';
import type { TaskInstance } from 'ember-concurrency';
import first from '../helpers/first.ts';
import type ConfigService from '../services/config.ts';
import type NotificationsService from '../services/notifications.ts';
import type NotificationBase from './flash-messages/notification-base.gts';
import NotificationLarge from './flash-messages/notification-large.gts';
import NotificationMinimal from './flash-messages/notification-minimal.gts';
import NotificationModern from './flash-messages/notification-modern.gts';
import Notification from './flash-messages/notification.gts';
interface FlashMessagesSignature {
    Args: {
        notificationStyle?: 'default' | 'large' | 'minimal' | 'modern';
        onCollision?: (_isColliding: boolean) => void;
    };
    Blocks: {
        notification: [WithBoundArgs<typeof NotificationBase, 'notification' | 'isVisible' | 'dismissNotification' | 'openLink'>];
    };
    Element: HTMLDivElement;
}
function notificationComponent(style1: 'default' | 'large' | 'minimal' | 'modern') {
    if (style1 === 'large') {
        return NotificationLarge;
    }
    if (style1 === 'minimal') {
        return NotificationMinimal;
    }
    if (style1 === 'modern') {
        return NotificationModern;
    }
    return Notification;
}
export default class FlashMessagesComponent extends Component<FlashMessagesSignature> {
    @service
    private notifications: NotificationsService;
    @service
    private router: RouterService;
    @service
    private config: ConfigService;
    notificationComponent = notificationComponent(this.args.notificationStyle);
    notificationsContainer: HTMLElement | null = null;
    @action
    dismiss(task1: TaskInstance<void>) {
        task1.cancel();
    }
    @action
    openLink(link1: HTMLAnchorElement, e1: Event) {
        e1.preventDefault();
        e1.stopPropagation();
        const url1 = `/${link1.pathname.substring(this.config.getValue('rootURL').length)}`;
        this.router.transitionTo(url1);
        return false;
    }
    @action
    setupCollisionObserver(el1: HTMLElement) {
        this.notificationsContainer = el1;
        this.observeActiveElement();
        // Listen for focus and blur events
        document.addEventListener('focusin', this.observeActiveElement);
        document.addEventListener('focusout', this.observeActiveElement);
    }
    @action
    observeActiveElement() {
        if (this.notificationsContainer && document.activeElement instanceof HTMLElement) {
            const rect11 = this.notificationsContainer.getBoundingClientRect();
            const rect21 = document.activeElement.getBoundingClientRect();
            const colliding1 = !(rect11.right < rect21.left || rect11.left > rect21.right || rect11.bottom < rect21.top || rect11.top > rect21.bottom);
            if (colliding1) {
                this.args.onCollision?.(true);
            } else {
                this.args.onCollision?.(false);
            }
        }
    }
    @action
    destroyCollisionObserver(el1: HTMLElement) {
        if (this.notificationsContainer) {
            this.notificationsContainer = null;
            this.args.onCollision?.(false);
        }
        document.removeEventListener('focusin', this.observeActiveElement);
        document.removeEventListener('focusout', this.observeActiveElement);
    }
    static{
        template(`
    {{#if this.notifications.queue}}
      <div data-test-flash-messages ...attributes {{didInsert this.setupCollisionObserver}} {{willDestroy this.destroyCollisionObserver}}>
        {{#if (has-block "notification")}}
          {{#each this.notifications.queue as |task|}}
            {{! @glint-ignore - ec types missing 'args' }}
            {{yield (component this.notificationComponent notification=(first task.args)
              isVisible=task.isRunning
              dismissNotification=(fn this.dismiss task)
              openLink=this.openLink
            )
              to="notification"}}
          {{/each}}
        {{else}}
          {{#each this.notifications.queue as |task|}}
            {{! @glint-ignore - ec types missing 'args' }}
            <this.notificationComponent @notification={{first task.args}}
              @isVisible={{task.isRunning}}
              @dismissNotification={{fn this.dismiss task}}
              @openLink={{this.openLink}}
            />
          {{/each}}
        {{/if}}
      </div>
    {{/if}}
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
