import Component from '@glimmer/component';

interface Signature {
  Args: {
    c: any;
    collectionName?: string;
  };
}

export default class extends Component<Signature> {
  get collectionName() {
    return this.args.collectionName ?? 'records';
  }
}
