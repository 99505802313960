import { tracked } from '@glimmer/tracking';

import { action } from '@ember/object';

import type {
  ExtractFormProps,
  ExtractFormValue,
  FormContext
} from './-control-base.ts';
import FormInput from './input.ts';

type PasswordProperty = string;

const PASSWORD_LENGTH = 12;

// Include the "Password strength" text here so it is
// announced correctly by screen readers
const PASSWORD_TEXT = {
  very_weak: 'Password strength: Very Weak',
  weak: 'Password strength: Weak',
  fair: 'Password strength: Fair',
  good: 'Password strength: Good',
  strong: 'Password strength: Strong'
};

function checkPasswordStrength(password?: string) {
  if (!password) {
    return 0;
  }

  // Initialize variables
  let strength = 1;

  // Check password length
  if (password.length >= PASSWORD_LENGTH) {
    strength += 1;
  }

  // Check for mixed case
  if (password.match(/[a-z]/) && password.match(/[A-Z]/)) {
    strength += 1;
  }

  // Check for numbers
  if (password.match(/\d/)) {
    strength += 1;
  }

  // Check for special characters
  if (password.match(/[^a-zA-Z\d]/)) {
    strength += 1;
  }

  return strength;
}

export default class PasswordInput<
  Context extends FormContext,
  Property extends ExtractFormProps<Context, PasswordProperty>,
  Value extends ExtractFormValue<Context, Property>
  /** @ts-expect-error **/
> extends FormInput<Context, Property, Value> {
  @tracked passwordIsVisible: boolean = false;

  get passwordStrength() {
    return checkPasswordStrength(this.value as string);
  }

  get passwordStrengthKey(): keyof typeof PASSWORD_TEXT {
    if (this.passwordStrength <= 1) return 'very_weak';
    if (this.passwordStrength === 2) return 'weak';
    if (this.passwordStrength === 3) return 'fair';
    if (this.passwordStrength === 4) return 'good';
    return 'strong';
  }

  get passwordStrengthText(): string {
    return PASSWORD_TEXT[this.passwordStrengthKey];
  }

  @action
  togglePasswordVisibility() {
    this.passwordIsVisible = !this.passwordIsVisible;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Form::Password': typeof PasswordInput;
  }
}
