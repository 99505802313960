/**
 * Extracted from ember-api-actions https://github.com/mike-north/ember-api-actions/blob/master/addon/utils/serialize-and-push.ts
 *
 * Our response doesn't include json-api version, so we need to skip the jsonapi doc validation part
 */

import type Model from '@ember-data/model';
import { getOwner } from '@ember/application';
import { isArray } from '@ember/array';

/**
 * Given a record, obtain the ember-data model class
 * @param record
 */
function _getModelClass<M extends typeof Model>(record: InstanceType<M>): M {
  return record.constructor as M;
}

/**
 * Given an ember-data model class, obtain its name
 * @param clazz
 */
function _getModelName(clazz: typeof Model): string {
  return clazz.modelName as string;
}

/**
 * Given an ember-data-record, obtain the related Store
 * @param record
 */
function _getStoreFromRecord(record: Model) {
  const owner = getOwner(record);
  return owner.lookup('service:store');
}

function triggerRecordCache(records: Model[]) {
  // Accessing `id` will go trigger the RecordCache
  records.forEach(record => record.id);
}

export function serializeAndPush(this: Model, response: any): any {
  const responseHasData =
    response && ['object', 'array'].includes(typeof response.data);

  if (responseHasData) {
    const recordClass = _getModelClass(this);
    const modelName = _getModelName(recordClass);
    const store = _getStoreFromRecord(this);
    const model = store.modelFor(modelName);
    const serializer = store.serializerFor(modelName);
    let normalized = {};
    if (isArray(response.data)) {
      normalized = serializer.normalizeArrayResponse(
        store,
        model,
        response,
        null as any,
        'findAll'
      );
    } else {
      normalized = serializer.normalizeSingleResponse(
        store,
        model,
        response,
        response.data.id,
        'findRecord'
      );
    }
    const recordOrRecords = store.push(normalized) as Model[] | Model;
    triggerRecordCache(
      isArray(recordOrRecords) ? recordOrRecords : [recordOrRecords]
    );
    return recordOrRecords;
  }
  return null;
}
