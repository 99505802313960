import { captureException, configureScope } from '@sentry/ember';
import type { ErrorObject } from 'jsonapi-typescript';
import type { ErrorDocument } from 'ticketoffice-api';

import Service, { inject as service } from '@ember/service';
import { isNone, isBlank } from '@ember/utils';

export class FormServerValidationError extends Error {}

import type EnvironmentService from './environment.ts';

export function shouldLogJsonApiError(error: ErrorObject): boolean {
  // 401 || 403 || 404 || 422 || 0 || 504 || 499
  return ![401, 403, 404, 422, 0, 504, 499].includes(Number(error.status));
}

/**
 * Don't log the same error twice
 */
const handledErrors = new WeakSet();

export default class ErrorsBaseService extends Service {
  @service() environment!: EnvironmentService;

  private prefix = '[handled]: ';

  setTag(key: string, value: string): void {
    configureScope(scope => {
      scope.setTag(key, value);
    });
  }

  setUser(data: any): void {
    configureScope(scope => {
      scope.setUser(data);
    });
  }

  setExtra(key: string, value: any) {
    configureScope(scope => {
      scope.setExtra(key, value);
    });
  }

  setPrefix(prefix: string) {
    this.prefix = prefix;
  }

  log(error: Error | string | null | any, scope?: { extra: any }): void {
    if (handledErrors.has(error)) {
      return;
    }

    if (Array.isArray(error)) {
      error.forEach(e => this.log(e, scope));
      return;
    }

    // { errors: [...] }
    if (error && this.isJsonApiErrorDocument(error)) {
      error.errors.forEach(e => this.log(e, scope));
      return;
    }

    // { data: { errors: [...] } }
    const errorDocument =
      error && typeof error === 'object' && 'data' in error && error.data;
    if (errorDocument && this.isJsonApiErrorDocument(errorDocument)) {
      errorDocument.errors.forEach(e => this.log(e, scope));
      return;
    }

    /*eslint no-console: ["error", { allow: ["error"] }] */
    const isProduction = this.environment.isProduction;
    if (isProduction && this.shouldLogError(error)) {
      captureException(this.prettifyError(error), scope);
    } else {
      this.printErrorDetails(this.prettifyError(error), scope);
    }

    if (typeof error === 'object' && error !== null) {
      handledErrors.add(error);
    }
  }

  /**
   * Ignore error reporting in production for certain type of errors, like 401
   *
   * Overridable hook
   */
  shouldLogError(error: any): boolean {
    // null, undefined, [], ''
    if (isBlank(error)) {
      return false;
    }
    if (error instanceof FormServerValidationError) {
      return false;
    }
    if (this.isJsonApiError(error)) {
      return shouldLogJsonApiError(error);
    }
    return true;
  }

  isJsonApiErrorDocument(doc: any): doc is ErrorDocument {
    return (
      typeof doc === 'object' && 'errors' in doc && Array.isArray(doc.errors)
    );
  }

  isJsonApiError(error: any): error is ErrorObject {
    return typeof error === 'object' && 'status' in error && 'detail' in error;
  }

  isJsonApiUnauthorizedError(doc: any) {
    return (
      this.isJsonApiErrorDocument(doc) &&
      doc.errors.some(error => error.code === '401')
    );
  }

  private prettifyError(error: any): any {
    if (isNone(error)) {
      return error;
    }

    // Convert json-api error
    if (this.isJsonApiError(error)) {
      this.setExtra('error', error);
      error = new Error(`${error.status} ${error.detail ?? ''}`);
    }

    // Add logger prefix
    if (error.message) {
      try {
        error.message = `${this.prefix}${error.message}`;
      } catch (prependMessageError) {
        console.error(prependMessageError);
      }
    } else if (typeof error === 'string') {
      error = `${this.prefix}${error}`;
    }

    return error;
  }

  private printErrorDetails(error: any, scope: any) {
    if (isNone(error)) {
      console.error('Error: null or undefined');
      console.error('Stack trace:', new Error().stack);
    } else if (error instanceof Error) {
      console.error('Error:', error.message);
      console.error('Stack trace:', error.stack);
    } else if (typeof error === 'object') {
      try {
        console.error('Error:', JSON.stringify(error, null, 2));
        console.error('Stack trace:', new Error().stack);
      } catch (e) {
        console.error('Error: (could not be stringified)');
        console.error('Stack trace:', new Error().stack);
        for (const key in error) {
          // eslint-disable-next-line no-prototype-builtins
          if (error.hasOwnProperty(key)) {
            console.error(`${key}: ${error[key]}`);
          }
        }
      }
    } else {
      console.error('Error:', error);
      console.error('Stack trace:', new Error().stack);
    }
    if (scope) {
      console.error('Scope:', scope);
    }
  }
}
